import React, {useState} from 'react';
import './Panel.sass';
import  { News } from "./News/News"
import {Articles} from "./Articles/Articles";
import {Doctors} from "./Doctors/Doctors";
import {Price} from "./Price/Price";
import {Services} from "./Services/Services";
import {AdminGallery} from "./AdminGallery/AdminGallery";
import {MainVideo} from "./MainVideo/MainVideo";
import {FeedbackModerate} from "./FeedbackModerate/FeedbackModerate";


function Section(sec){
    if (sec === "news"){
        return < News/>
    } else if(sec === "atricles"){
        return < Articles/>
    } else if(sec === "price"){
        return < Price/>
    }else if(sec === "doctors"){
        return < Doctors/>
    }else if(sec === "services"){
        return < Services/>
    }else if (sec === "gallery"){
        return <AdminGallery/>
    }else if (sec === "mainvideo"){
        return <MainVideo/>
    }else if (sec === "feedback"){
        return <FeedbackModerate/>
    }

}

export function Panel()  {
    const[section, SetSection] = useState(null);

    return (
        <div className={"control-panel container"}>
            <div className={"admin-menu"}>
                <ul className={"menu-items"}>
                    <li onClick={() => SetSection('atricles')}>Статьи</li>
                    <li onClick={() => SetSection('news')}>Новости</li>
                    <li onClick={() => SetSection('doctors')}>Врачи</li>
                    <li onClick={() => SetSection('price')}>Прайс</li>
                    <li onClick={() => SetSection('services')}>Услуги</li>
                    <li onClick={() => SetSection('gallery')}>Галерея</li>
                    <li onClick={() => SetSection('mainvideo')}>Видео</li>
                    <li onClick={() => SetSection('feedback')}>Отзывы</li>
                </ul>
            </div>
            <div className={"admin-content"}>
                <h1>Административная панель</h1>
                {Section(section)}
            </div>
        </div>
    )
}