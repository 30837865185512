import React from 'react';
import './Admin.sass';
import {LoginForm} from "./components/LoginForm";

export function Admin() {


    return (
        <div className={"adminka"}>
            <div className={"black-block"}></div>
            <LoginForm/>
        </div>
    );
}