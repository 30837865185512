import "./SpecArticles.sass"
import {useSelector} from "react-redux";
import {doctorsList, servicesList, specArticlesList} from "../../reducers/dataReducer";
import {Link, useParams} from "react-router-dom";
import {Sechead} from "../../components/Sechead/Sechead";
import React from "react";
import parse from "html-react-parser";
import {MainContacts} from "../../components/MainContacts/MainContacts";
import {Footer} from "../../components/Footer/Footer";
import {Helmet} from "react-helmet";

export function SpecArticles(){
    let spec_articles = useSelector(specArticlesList);
    let doctors = useSelector(doctorsList);
    let services = useSelector(servicesList);
    const {spec_name} = useParams();
    let fix_name = spec_name.replaceAll("_", ' ');

    let currentSpecArticle
    let currentService

    for (let item of spec_articles){
        if (item.name === fix_name){
            currentSpecArticle = item;
            for (let serv of services){
                if (serv.id === currentSpecArticle.owner_id){
                    currentService = serv;
                }
            }
        }
    }
    let specs = [];

    for (let spec of currentSpecArticle.doctors){
        for (let item of doctors){
            if ( item.id === parseInt(spec)){
                specs.push(item);
            }
        }
    }

    return(
        <div className={"spec-articles"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Возрождение жизни - {currentService.name} - {currentSpecArticle.name}</title>
            </Helmet>
            <Sechead/>
            <div className={"spec-article-page"}>
                <div className={"bread"}>
                    <ul>
                        <li><Link to="/">Главная</Link></li>
                        <li>Услуги</li>
                        <li>{currentService.name}</li>
                        <li>{currentSpecArticle.name}</li>
                    </ul>
                </div>
                <div className={"spec-article-text"}>
                    <h1>{currentSpecArticle.name}</h1>
                    <div className={"spec-article-text-image"}>
                        <img src={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/" + currentSpecArticle.image}/>
                    </div>
                    {parse(currentSpecArticle.text)}
                </div>
                <div className={"doctors"}>
                    <h2>Наши специалисты</h2>
                    {specs.map((spec) => {
                        return (
                            <div className={"doctor"} key={spec.id}>
                                <Link to={"/doctor/" + spec.surname + "_" + spec.name + "_" + spec.patronymics}>{spec.surname} {spec.name} {spec.patronymics}</Link>
                            </div>
                        )
                    })}
                </div>
            </div>
            <MainContacts/>
            <Footer/>
        </div>
    );
}