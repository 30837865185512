import Modal from "react-modal";
import validator from "validator";
import React, {useEffect, useState} from "react";
import {addDoctor, profList} from "../../../../reducers/dataReducer";
import {useDispatch, useSelector} from "react-redux";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build';
import {IoTrashOutline} from "react-icons/io5";
import "./AddDoctor.sass";


Modal.setAppElement("#root");

export function AddDoctor() {
    const dispatch = useDispatch();
    let proflist = useSelector(profList);

    const [showModal, setModal] = useState(false);

    const [doctorName, setDocname] = useState("");
    const [doctorPatronym, setPatronym] = useState("");
    const [doctorSurname, setSurname] = useState("");
    const [doctorSpec, setSpec] = useState("");
    const [doctorFullSpec, setFullSpec] = useState([]);
    const [doctorAbout, setAbout] = useState("");
    const [doctorHowWork, setHowWork] = useState("");
    const [doctorPhoto, setPhoto] = useState("");
    const [doctorStag, setStag] = useState("");

    const [PhotoFile, setFile] = useState();

    const [doctorEdu, setEdu] = useState([]);
    const [eduYear, setYear] = useState("");
    const [eduText, setEduText] = useState("");
    const [eduImage, setEduImage] = useState("");
    const [eduFile, setEduFile] = useState();

    const [doctorWorks, setWorks] = useState([]);
    const [workYear, setWorkYear] = useState("");
    const [workText, setWorkText] = useState("");
    const [workImage, setWorkImage] = useState("");
    const [workFile, setWorkFile] = useState();

    const [doctorMetodi, setMetodi] = useState([]);
    const [metodInput, setMetod] = useState("");

    const [doctorDocuments, setDocuments] = useState([]);
    const [documentInput , setDocument] = useState("");
    const [documentFile, setDocumentFile] = useState();

    const [selectedProf, setProf] = useState(null);
    const [deletedProf, setDelProf] = useState(null);
    const [deletedStage, setDelStage] = useState(null);
    const [deletedWork, setDelWork] = useState(null);
    const [deletedMetod, setDelMetod] = useState(null);
    const [deletedDocument, setDelDocument] = useState(null);


    const [token, setToken] = useState("");
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            setToken(token);
        }
    }, []);

    let AddDoctor = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: "POST",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                "id": 0,
                "name": doctorName,
                "patronymics": doctorPatronym,
                "surname": doctorSurname,
                "specialty": doctorSpec,
                "fullspeciality": JSON.stringify(doctorFullSpec),
                "about": doctorAbout,
                "howiwork": doctorHowWork,
                "photo": doctorPhoto,
                "stag": doctorStag,
                "education": JSON.stringify(doctorEdu),
                "working": JSON.stringify(doctorWorks),
                "metodi": JSON.stringify(doctorMetodi),
                "documents": JSON.stringify(doctorDocuments)
            }),
        };
        const response = await fetch("https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/doctors/add", requestOptions);
        const data = await response.json();
        if (!response.ok) {
            console.log("Хуйня");
        } else {
            dispatch(addDoctor(data));
        }
        setModal(false);
    };

    const [inputsValid, setValid] = useState({
        name: validator.isLength(doctorName, {min: 5, max: 40}),
        patronym: validator.isLength(doctorPatronym, {min: 5, max: 40}),
        surname: validator.isLength(doctorSurname, {min: 5, max: 40}),
        specialty: validator.isLength(doctorSpec, {min: 5, max: 40}),
        fullspeciality: doctorFullSpec.length > 0,
        about: validator.isLength(doctorAbout, {min: 50, max: 1000}),
        howiwork: validator.isLength(doctorHowWork, {min: 50, max: 1000}),
        photo: validator.isLength(doctorPhoto, {min: 5, max: 40}),
        stag: validator.isLength(doctorStag, {min: 5, max: 40}),
        education: doctorEdu.length > 0,
        working: doctorWorks.length > 0,
        metodi: doctorMetodi.length > 0,
        documents: doctorDocuments.length > 0
    });
    const formValid = (values) => {
        return !(values.name === true &&
            values.patronym === true &&
            values.surname === true &&
            values.about === true &&
            values.howiwork === true &&
            values.education === true &&
            values.working === true &&
            values.metodi === true &&
            values.documents === true &&
            values.fullspeciality === true &&
            values.photo === true &&
            values.stag === true &&
            values.specialty === true);
    };
    const AddProf = async (e) => {
        e.preventDefault();
        let add = selectedProf;
        if (!doctorFullSpec.includes(add)) {
            setFullSpec(arr => [...arr, add]);
            setValid({
                ...inputsValid,
                fullspeciality: doctorFullSpec.length > 0
            })
        }
    }
    useEffect(() => {
        if (deletedProf) {
            let profs = doctorFullSpec;
            for (let i = 0; i < profs.length; i++) {
                if (deletedProf === profs[i]) {
                    profs.splice(i, 1);
                    break
                }
            }
            setFullSpec(profs);
            setDelProf(null);
        }
    }, [deletedProf]);

    let UploadImage = async (e) => {
        e.preventDefault()
        const image = new FormData();
        image.append('uploaded_file', PhotoFile);

        const requestOptions = {
            method: "POST",
            headers: {
                "accept": "application/json",
                Authorization: "Bearer " + token,
            },
            "Content-Type": "multipart/form-data",
            body: image,
        };
        const response = await fetch("https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload-file/", requestOptions);


        const data = await response.json();
        if (!response.ok) {
            console.log(response);
        } else {
            setPhoto(PhotoFile.name);
            setValid({
                ...inputsValid,
                image: validator.isLength(PhotoFile.name, {
                    min: 5,
                    max: 40,
                }),
            });
            console.log(data);
        }
    };

    let UploadImageEdu = async (e) => {
        e.preventDefault()
        const image = new FormData();
        image.append('uploaded_file', eduFile);

        const requestOptions = {
            method: "POST",
            headers: {
                "accept": "application/json",
                Authorization: "Bearer " + token,
            },
            "Content-Type": "multipart/form-data",
            body: image,
        };
        const response = await fetch("https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload-file/", requestOptions);


        const data = await response.json();
        if (!response.ok) {
            console.log(response);
        } else {
            setEduImage(eduFile.name);
            console.log(data);
        }
    };
    const AddEduStage = async (e) => {
        e.preventDefault();
        let add = {
            year: eduYear,
            text: eduText,
            img: eduImage,
        }
        if (!doctorEdu.includes(add)) {
            setEdu(arr => [...arr, add]);
            setValid({
                ...inputsValid,
                education: doctorEdu.length > 0
            })
        }
    }
    useEffect(() => {
        if (deletedStage) {
            let stages = doctorEdu;
            for (let i = 0; i < stages.length; i++) {
                if (deletedStage === stages[i]) {
                    stages.splice(i, 1);
                    break
                }
            }
            setEdu(stages);
            setDelStage(null);
        }
    }, [deletedStage]);

    let UploadImageWork = async (e) => {
        e.preventDefault()
        const image = new FormData();
        image.append('uploaded_file', workFile);

        const requestOptions = {
            method: "POST",
            headers: {
                "accept": "application/json",
                Authorization: "Bearer " + token,
            },
            "Content-Type": "multipart/form-data",
            body: image,
        };
        const response = await fetch("https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload-file/", requestOptions);


        const data = await response.json();
        if (!response.ok) {
            console.log(response);
        } else {
            setWorkImage(workFile.name);
            console.log(data);
        }
    };
    const AddWork = async (e) => {
        e.preventDefault();
        let add = {
            year: workYear,
            text: workText,
            img: workImage,
        }
        if (!doctorWorks.includes(add)) {
            setWorks(arr => [...arr, add]);
            setValid({
                ...inputsValid,
                working: doctorWorks.length > 0
            })
        }
    };
    useEffect(() => {
        if (deletedWork) {
            let works = doctorWorks;
            for (let i = 0; i < works.length; i++) {
                if (deletedWork === works[i]) {
                    works.splice(i, 1);
                    break
                }
            }
            setWorks(works);
            setDelWork(null);
        }
    }, [deletedWork]);

    const AddMetod = async (e) => {
        e.preventDefault();
        let add = metodInput;
        if (!doctorMetodi.includes(add)) {
            setMetodi(arr => [...arr, add]);
            setValid({
                ...inputsValid,
                metodi: doctorMetodi.length > 0
            })
            setDelMetod("");
        }
    }
    useEffect(() => {
        if (deletedMetod) {
            let metodi = doctorMetodi;
            for (let i = 0; i < metodi.length; i++) {
                if (deletedMetod === metodi[i]) {
                    metodi.splice(i, 1);
                    break
                }
            }
            setMetodi(metodi);
            setValid({
                ...inputsValid,
                metodi: doctorMetodi.length > 0
            })
            setDelMetod("");
        }
    }, [deletedMetod]);

    const AddDocument = async (e) => {
        e.preventDefault();
        let add = documentInput;
        if (!doctorDocuments.includes(add)) {
            setDocuments(arr => [...arr, add]);
            setValid({
                ...inputsValid,
                documents: doctorDocuments.length > 0
            });
            setDelDocument("");
        }
    }
    useEffect(() => {
        if (deletedDocument) {
            let documents = doctorDocuments;
            for (let i = 0; i < documents.length; i++) {
                if (deletedDocument === documents[i]) {
                    documents.splice(i, 1);
                    break
                }
            }
            setDocuments(documents);
            setValid({
                ...inputsValid,
                documents: doctorDocuments.length > 0
            })
            setDelDocument("");
        }
    }, [deletedDocument]);
    let UploadDocumentImage = async (e) => {
        e.preventDefault()
        const image = new FormData();
        image.append('uploaded_file', documentFile);

        const requestOptions = {
            method: "POST",
            headers: {
                "accept": "application/json",
                Authorization: "Bearer " + token,
            },
            "Content-Type": "multipart/form-data",
            body: image,
        };
        const response = await fetch("https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload-file/", requestOptions);


        const data = await response.json();
        if (!response.ok) {
            console.log(response);
        } else {
            setDocument(documentFile.name);
            console.log(data);
        }
    };

    return (
        <div className={"modal-form"}>
            <div className={"add-doctor"}>
                <button onClick={() => setModal(true)} className={"add-doctor-button"}>Добавить</button>
            </div>
            <Modal isOpen={showModal} contentLabel="Форма: Добавить врача">
                <h2>Добавить врача</h2>
                <form>
                    <label>
                        <span>Имя</span>
                        <input
                            name="name"
                            type="text"
                            value={doctorName}
                            onChange={(event) => setDocname(event.target.value)}
                            onInput={(event) =>
                                setValid({
                                    ...inputsValid,
                                    name: validator.isLength(event.target.value, {
                                        min: 5,
                                        max: 40,
                                    }),
                                })
                            }
                        />
                        <span className="warning">
                              {inputsValid.name === true
                                  ? ""
                                  : "Введите Имя!"}
                            </span>
                    </label>
                    <br/>
                    <label>
                        <span>Отчество</span>
                        <input
                            name="patronymics"
                            type="text"
                            value={doctorPatronym}
                            onChange={(event) => setPatronym(event.target.value)}
                            onInput={(event) =>
                                setValid({
                                    ...inputsValid,
                                    patronym: validator.isLength(event.target.value, {
                                        min: 5,
                                        max: 40,
                                    }),
                                })
                            }
                        />
                        <span className="warning">
                              {inputsValid.patronym === true
                                  ? ""
                                  : "Введите Отчество!"}
                            </span>
                    </label>
                    <br/>
                    <label>
                        <span>Фамилия</span>
                        <input
                            name="surname"
                            type="text"
                            value={doctorSurname}
                            onChange={(event) => setSurname(event.target.value)}
                            onInput={(event) =>
                                setValid({
                                    ...inputsValid,
                                    surname: validator.isLength(event.target.value, {
                                        min: 5,
                                        max: 40,
                                    }),
                                })
                            }
                        />
                        <span className="warning">
                              {inputsValid.surname === true
                                  ? ""
                                  : "Введите Фамилию!"}
                            </span>
                    </label>
                    <br/>
                    <label>
                        <span>Специальность</span>
                        <input
                            name="specialty"
                            type="text"
                            value={doctorSpec}
                            onChange={(event) => setSpec(event.target.value)}
                            onInput={(event) =>
                                setValid({
                                    ...inputsValid,
                                    specialty: validator.isLength(event.target.value, {
                                        min: 5,
                                        max: 40,
                                    }),
                                })
                            }
                        />
                        <span className="warning">
                              {inputsValid.specialty === true
                                  ? ""
                                  : "Введите Специальность!"}
                            </span>
                    </label>
                    <br/>
                    <label>
                        <select onChange={(event) => setProf(event.target.value)}>
                            <option value={""}>Выберите специальность</option>
                            {proflist.map((prof, index) => {
                                return (
                                    <option key={index} value={prof}>{prof}</option>
                                )
                            })}
                        </select>
                        <button onClick={AddProf}>добавить специальность</button>
                        <div className={"chosed-profs"}>
                            {doctorFullSpec.map((prof, index) => {
                                return (
                                    <div className={"prof"} key={index}>
                                        {prof}
                                        <span onClick={() => setDelProf(prof)}><IoTrashOutline/></span>
                                    </div>
                                )
                            })}
                        </div>
                    </label>
                    <br/>
                    <label>
                        <span>О враче</span>
                        <CKEditor
                            editor={Editor}
                            data={doctorAbout}
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setAbout(data);
                                setValid({
                                    ...inputsValid,
                                    about: validator.isLength(data, {
                                        min: 50,
                                        max: 1000,
                                    }),
                                });
                            }}

                        />
                        <span className="warning">
                              {inputsValid.about === true
                                  ? ""
                                  : "Введите Информацию о враче!"}
                            </span>
                    </label>
                    <br/>
                    <label>
                        <span>Как я работаю</span>
                        <CKEditor
                            editor={Editor}
                            data={doctorHowWork}
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setHowWork(data);
                                setValid({
                                    ...inputsValid,
                                    howiwork: validator.isLength(data, {
                                        min: 50,
                                        max: 1000,
                                    }),
                                });
                            }}


                        />
                        <span className="warning">
                              {inputsValid.howiwork === true
                                  ? ""
                                  : "Введите Информацию о работе врача"}
                            </span>
                    </label>
                    <br/>
                    <label>
                        <span>Фото</span>
                        <input
                            name="photo"
                            type="text"
                            value={doctorPhoto}
                            onChange={(event) => setPhoto(event.target.value)}
                            onInput={(event) =>
                                setValid({
                                    ...inputsValid,
                                    photo: validator.isLength(event.target.value, {
                                        min: 5,
                                        max: 40,
                                    }),
                                })
                            }
                        />
                        <span className="warning">
                              {inputsValid.photo === true
                                  ? ""
                                  : "Введите название файла, либо введите значение: no-photo"}
                            </span>
                    </label>
                    <label>
                        <input
                            name="article-image-file"
                            type="file"
                            onChange={(event) => setFile(event.target.files[0])}
                        />
                        <button onClick={UploadImage}> загрузить</button>
                    </label>
                    <br/>
                    <label>
                        <span>Стаж</span>
                        <input
                            name="stag"
                            type="text"
                            value={doctorStag}
                            onChange={(event) => setStag(event.target.value)}
                            onInput={(event) =>
                                setValid({
                                    ...inputsValid,
                                    stag: validator.isLength(event.target.value, {
                                        min: 4,
                                        max: 40
                                    }),
                                })
                            }
                        />
                        <span className="warning">
                              {inputsValid.stag === true
                                  ? ""
                                  : "Введите стаж"}
                            </span>
                    </label>
                    <br/>
                    <label>Oбразование</label>
                    <br/>
                    <label>
                        <span>год</span>
                        <input
                            name="year"
                            type="text"
                            value={eduYear}
                            onChange={(event) => setYear(event.target.value)}
                        />
                        <span>текст</span>
                        <input
                            name="edutext"
                            type="text"
                            value={eduText}
                            onChange={(event) => setEduText(event.target.value)}
                        />
                        <span>фото</span>
                        <input
                            name="eduimage"
                            type="text"
                            value={eduImage}
                            onChange={(event) => setEduImage(event.target.value)}
                        />
                        <label>
                            <input
                                name="disease-image-file"
                                type="file"
                                onChange={(event) => setEduFile(event.target.files[0])}
                            />
                            <button onClick={UploadImageEdu}> загрузить</button>
                        </label>
                        <button onClick={AddEduStage}>добавить специальность</button>
                        <div className={"edu-list"}>
                            {doctorEdu.map((stage, index) => {
                                return (
                                    <div key={index}>
                                        {stage.year + " " + stage.text + " " + stage.img}<span
                                        onClick={() => setDelStage(stage)}><IoTrashOutline/></span>
                                    </div>
                                )
                            })}
                        </div>
                    </label>
                    <br/>
                    <label>Работа</label>
                    <br/>
                    <label>
                        <span>год</span>
                        <input
                            name="work-year"
                            type="text"
                            value={workYear}
                            onChange={(event) => setWorkYear(event.target.value)}
                        />
                        <span>текст</span>
                        <input
                            name="work-text"
                            type="text"
                            value={workText}
                            onChange={(event) => setWorkText(event.target.value)}
                        />
                        <span>фото</span>
                        <input
                            name="work-image"
                            type="text"
                            value={workImage}
                            onChange={(event) => setWorkImage(event.target.value)}
                        />
                        <label>
                            <input
                                name="work-image-file"
                                type="file"
                                onChange={(event) => setWorkFile(event.target.files[0])}
                            />
                            <button onClick={UploadImageWork}> загрузить</button>
                        </label>
                        <button onClick={AddWork}>добавить работу</button>
                        <div className={"edu-list"}>
                            {doctorWorks.map((work, index) => {
                                return (
                                    <div key={index}>
                                        {work.year + " " + work.text + " " + work.img}<span
                                        onClick={() => setDelWork(work)}><IoTrashOutline/></span>
                                    </div>
                                )
                            })}
                        </div>
                    </label>
                    <br/>
                    <label>
                        <span>Методики</span>
                        <input
                            name="metodi"
                            type="text"
                            value={metodInput}
                            onChange={(event) => setMetod(event.target.value)}
                        />
                        <button onClick={AddMetod}>добавить специальность</button>
                        <div className={"metodi-list"}>
                            {doctorMetodi.map((metod, index) => {
                                return (
                                    <div key={index}>
                                        {metod}<span onClick={() => setDelMetod(metod)}><IoTrashOutline/></span>
                                    </div>
                                )
                            })}
                        </div>
                    </label>
                    <br/>
                    <label>
                        <span>Документы</span>
                        <input
                            name="documents"
                            type="text"
                            value={documentInput}
                            onChange={(event) => setDocument(event.target.value)}
                        />
                        <label>
                            <input
                                name="document-image-file"
                                type="file"
                                onChange={(event) => setDocumentFile(event.target.files[0])}
                            />
                            <button onClick={UploadDocumentImage}> загрузить</button>
                        </label>
                        <button onClick={AddDocument}>добавить документ</button>
                        <div className={"documents-list"}>
                            {doctorDocuments.map((document, index) => {
                                return (
                                    <div key={index}>
                                        {document}<span onClick={() => setDelDocument(document)}><IoTrashOutline/></span>
                                    </div>
                                )
                            })}
                        </div>
                    </label>
                </form>
                <button
                    onClick={AddDoctor}
                    className="AddButton"
                    disabled={formValid(inputsValid)}
                >
                    Добавить
                </button>
                <button onClick={() => setModal(false)} className="closeButton">
                    Закрыть
                </button>
            </Modal>
        </div>
    );
}