import {useSelector} from "react-redux";
import {newsList} from "../../reducers/dataReducer";
import "./News.sass"
import moment from "moment";
import React from "react";
import "animate.css/animate.compat.css";
import ScrollAnimation from 'sgk-react-animation-scroll';
import {Link} from "react-router-dom";

export function News() {
    let news_full = useSelector(newsList);
    let news_3 = []
    for (let i = 0; i < 3; i++) {
        news_3.push(news_full[i]);
    }

    return (
        <div className={"main-page-news"} id={"news"}>
            <div className={"news-block"}>
                <div className={"news-header"}>
                    <h2>Новости</h2>
                </div>
                {news_3.map((news) => {
                    let photo = "https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/" + news.news_img

                    return (
                        <div className={"new-big-block"} key={news.news_id}>
                            <div className={"news-item"}>
                                <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true} offset={100}>
                                <div className={"news-image"}>
                                    <img src={photo} alt={news.header}/>
                                    <div className={"opacity-layer"}></div>
                                </div>
                                </ScrollAnimation>
                                <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true} offset={100}>
                                <div className={"new-header"}>
                                    <h3>
                                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="4" cy="4" r="4" fill="#D84313"/>
                                        </svg>
                                        {news.header}</h3>
                                </div>
                                </ScrollAnimation>
                                <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true} offset={100}>
                                <div className={"news-short"}>
                                    <p>{news.short_descr}</p>
                                </div>
                                <div className={"news-date-see-more"}>
                                    <div className={"news-date"}>
                                        {moment(news.date).format("DD.MM.YY")} г.
                                    </div>
                                    <div className={"see-more"}>
                                        <Link to={"/news/" + news.header.replaceAll(" ", "_")}>читать далее</Link>
                                    </div>
                                </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    );
                })
                }
                <div className={"more-news"}>
                    <div className={"more-news-text"}><Link to={"/news"}>Ещё новости</Link></div>
                    <div className={"arrow"}>
                        <svg width="24" height="8" viewBox="0 0 24 8" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5V3.5ZM23.3536 4.35355C23.5488 4.15829 23.5488 3.84171 23.3536 3.64645L20.1716 0.464466C19.9763 0.269204 19.6597 0.269204 19.4645 0.464466C19.2692 0.659728 19.2692 0.976311 19.4645 1.17157L22.2929 4L19.4645 6.82843C19.2692 7.02369 19.2692 7.34027 19.4645 7.53553C19.6597 7.7308 19.9763 7.7308 20.1716 7.53553L23.3536 4.35355ZM1 4.5L23 4.5V3.5L1 3.5V4.5Z"
                                fill="#D84313"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
}