import {articlesList} from "../../reducers/dataReducer";
import {useSelector} from "react-redux";
import "./ArticlesMain.sass"
import {Link} from "react-router-dom";
import React from "react";

export function ArticlesMain() {
    let articles = useSelector(articlesList);
    let last_articles = [];

    last_articles.push(articles[articles.length-2]);
    last_articles.push(articles[articles.length-1]);

    return (
        <div className={"blog-block-articles"}>
            <div className={"article-block"}>
                <div className={"article-image"}>
                    <img src={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/" + last_articles[0].image} height={110}
                         alt={last_articles[0].header} width={110}/>
                </div>
                <div className={"article-text"}>
                    <h4>{last_articles[0].header}</h4>
                    <p>{last_articles[0].description}</p>
                    <div className={"article-link"}><Link to={"/article/"+ last_articles[0].header.replaceAll(" ", '_').replaceAll("?", "q")} >читать</Link></div>
                </div>
            </div>
            <div className={"article-block"}>
                <div className={"article-image"}>
                    <img src={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/" + last_articles[1].image} height={110}
                         alt={last_articles[1].header} width={110}/>
                </div>
                <div className={"article-text"}>
                    <h4>{last_articles[1].header}</h4>
                    <p>{last_articles[1].description}</p>
                    <div className={"article-link"}><Link to={"/article/:"+ last_articles[1].article_id} >читать</Link></div>
                </div>
            </div>
            <div className={"articles-link"}><Link to="/articles">Читать ещё</Link>
                <div className={"arrow"}>
                    <svg width="24" height="8" viewBox="0 0 24 8" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5V3.5ZM23.3536 4.35355C23.5488 4.15829 23.5488 3.84171 23.3536 3.64645L20.1716 0.464466C19.9763 0.269204 19.6597 0.269204 19.4645 0.464466C19.2692 0.659728 19.2692 0.976311 19.4645 1.17157L22.2929 4L19.4645 6.82843C19.2692 7.02369 19.2692 7.34027 19.4645 7.53553C19.6597 7.7308 19.9763 7.7308 20.1716 7.53553L23.3536 4.35355ZM1 4.5L23 4.5V3.5L1 3.5V4.5Z"
                            fill="#D84313"/>
                    </svg>
                </div>
            </div>

        </div>
    );
}