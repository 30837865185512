import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import {editReview} from "../../../../reducers/dataReducer";

import {CKEditor} from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build';
import {FiEdit} from "react-icons/fi";

export function EditReview(props) {
    let edited_review = props.review;
    const dispatch = useDispatch();

    const [showModal, setModal] = useState(false);

    const [reviewId, setId] = useState(edited_review.id);
    const [reviewUser, setUser] = useState(edited_review.username);
    const [reviewText, setText] = useState(edited_review.feedback_text);
    const [reviewRating, setRating] = useState(edited_review.rating);
    const [reviewDate, setDate] = useState(edited_review.date);
    const [reviewPub, setPub] = useState(edited_review.is_published ? "1" : "0");
    const [reviewAns, setAns] = useState(edited_review.answer);
    const [pubBool, setBool] = useState(edited_review.is_published);
    console.log(edited_review);

    useEffect(() => {
        if (reviewPub === "1"){
            setBool(true)
        }else {
            setBool(false);
        }
    }, [reviewPub]);

    console.log(pubBool);
    //токен
    const [token, setToken] = useState("");
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            setToken(token);
        }
    }, []);
    //__/токен
    let EditFeed = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: "PATCH",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                "username": reviewUser,
                "feedback_text": reviewText,
                "rating": reviewRating,
                "date": reviewDate,
                "is_published": pubBool,
                "answer": reviewAns
            }),
        };
        const response = await fetch(`https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/reviews/edit?review_id=${reviewId}`, requestOptions);
        const data = await response.json();
        if (!response.ok) {
            console.log("Хуйня");
        } else {
            dispatch(editReview(data));
        }
        setModal(false);
    };
    return (
        <div className={"button-edit-review"}>
            <button onClick={() => setModal(true)}><FiEdit /></button>
            <Modal isOpen={showModal} contentLabel="Форма: Изменить отзыв">

                <h2>Редактировать отзыв</h2>

                <form>
                    <label>
                        <span>Пользователь</span>
                        <input
                            name="review-user"
                            type="text"
                            value={reviewUser}
                            onChange={(event) => setUser(event.target.value)}
                        />
                    </label>
                    <br/>
                    <label>
                        <span>Текст отзыва</span>
                        <CKEditor
                            editor={Editor}
                            data={reviewText}
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setText(data);
                            }}

                        />
                    </label>
                    <br/>
                    <label>
                        <span>Рейтинг</span>
                        <input
                            name="review-rating"
                            type="number"
                            value={reviewRating}
                            onChange={(event) => setRating(event.target.value)}

                        />
                    </label>
                    <br/>
                    <label>
                        <span>Дата</span>
                        <input
                            name="review-date"
                            type="text"
                            value={reviewDate}
                            onChange={(event) => setDate(event.target.value)}

                        />
                    </label>
                    <br/>
                    <label>
                        <span>Опубликован?</span>

                        <select value={reviewPub} onChange={(event) => setPub(event.target.value)}>
                            <option value={1}>да</option>
                            <option value={0}>нет</option>
                        </select>
                    </label>
                    <br/>
                    <label>
                        <span>Ответ</span>
                        <input
                            name="review-answer"
                            type="text"
                            value={reviewAns}
                            onChange={(event) => setAns(event.target.value)}

                        />
                    </label>
                    <br/>
                    <button
                        onClick={EditFeed}
                        className="editButton"
                    >
                        Добавить
                    </button>
                    <button onClick={() => setModal(false)} className="closeButton">
                        Закрыть
                    </button>
                </form>
            </Modal>
        </div>
    );
}