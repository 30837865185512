import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build';
import Modal from "react-modal";
import {editNews} from "../../../../reducers/dataReducer";
import validator from "validator";
import moment from "moment";

export function EditNewsForm(props){
    let edited_news = props.news;



    const dispatch = useDispatch();
    const [showModal, setModal] = useState(false);

    const [newsId, setId] = useState(edited_news.news_id);
    const [newsHeader, setHeader] = useState(edited_news.header);
    const [newsDate, setDate] = useState(edited_news.date);
    const [newsShortDesc, setShortDesc] = useState(edited_news.short_descr);
    const [newsImage, setImage] = useState(edited_news.news_img);
    const [newsDesc, setDesc] = useState(edited_news.description);
    const [imageFile, setFile] = useState();



    //токен
    const [token, setToken] = useState("");
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            setToken(token);
        }
    }, []);
    //__/токен

    let EditNews = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: "PATCH",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                "header": newsHeader,
                "short_descr": newsShortDesc,
                "description": newsDesc,
                "date": newsDate,
                "news_img": newsImage,
            }),
        };
        const response = await fetch(`https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/news/edit?news_id=${newsId}`, requestOptions);
        const data = await response.json();
        if (!response.ok) {
            console.log("Хуйня");
        } else {
            dispatch(editNews(data));
        }
        setModal(false);
    };
    let UploadImage = async (e) => {
        e.preventDefault()
        const image = new FormData();
        image.append('uploaded_file', imageFile);

        const requestOptions = {
            method: "POST",
            headers: {
                "accept": "application/json",
                Authorization: "Bearer " + token,
            },
            "Content-Type": "multipart/form-data",
            body: image,
        };
        const response = await fetch("https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload-file/", requestOptions);


        const data = await response.json();
        if (!response.ok) {
            console.log(response);
        } else {
            setImage(imageFile.name);

            console.log(data);
        }
    };

    return(
        <div className={"button-edit-news"}>
            <button onClick={() => setModal(true)}>Изменить</button>
            <Modal isOpen={showModal} contentLabel="Форма: Добавить статью">

                <h2>Редактировать новость</h2>

                <form>
                    <label>
                        <span>Заголовок</span>
                        <input
                            name="news-header"
                            type="text"
                            value={newsHeader}
                            onChange={(event) => setHeader(event.target.value)}
                        />
                    </label>
                    <br/>
                    <label>
                        <span>Краткое содержание</span>
                        <input
                            name="news-description"
                            type="text"
                            value={newsShortDesc}
                            onChange={(event) => setShortDesc(event.target.value)}
                        />
                    </label>
                    <br/>
                    <label>
                        <span>Текст Новости</span>
                        <CKEditor
                            editor={Editor}
                            data={newsDesc}
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setDesc(data);
                            }}

                        />
                    </label>
                    <br/>
                    <label>
                        <span>Главное фото</span>
                        <input
                            name="news-image"
                            type="text"
                            value={newsImage}
                            onChange={(event) => setImage(event.target.value)}

                        />

                        <label>
                            <input
                                name="article-image-file"
                                type="file"
                                onChange={(event) => setFile(event.target.files[0])}
                            />
                            <button onClick={UploadImage}> загрузить</button>
                        </label>
                    </label>
                    <br/>
                    <button
                        onClick={EditNews}
                        className="editButton"
                    >
                        Добавить
                    </button>
                    <button onClick={() => setModal(false)} className="closeButton">
                        Закрыть
                    </button>
                </form>
            </Modal>
        </div>
    );
}