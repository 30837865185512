import React, {useState, useRef, useEffect, useCallback} from 'react';
import doctorimg from "../../images/forparallax/doctor.png";
import familyimg from "../../images/forparallax/family.png";
import fathersonimg from "../../images/forparallax/father and son.png";
import handsimg from "../../images/forparallax/hands.png";
import childimg from "../../images/forparallax/little child.png";
import officeimg from "../../images/forparallax/office.png";
import pensimg from "../../images/forparallax/pensioner.png";
import sportimg from "../../images/forparallax/spotrsmen.png";
import weddimg from "../../images/forparallax/wedding.png";
import {useSpring, animated, easings} from '@react-spring/web';
import "./Parallax.sass";
import {FirstHeader} from "../FirstHeader/FirstHeader";
import {HashLink} from "react-router-hash-link";


export function Parallax() {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const ref = useRef(null);
    const [imageDoc, setImageDoc] = useState({x: 0, y: 0});
    const [imageFam, setImageFam] = useState({x: 0, y: 0});
    const [imageFathson, setImageFathson] = useState({x: 0, y: 0});
    const [imageHands, setImageHands] = useState({x: 0, y: 0});
    const [imageBaby, setImageBaby] = useState({x: 0, y: 0});
    const [imageOffice, setImageOffice] = useState({x: 0, y: 0});
    const [imagePens, setImagePens] = useState({x: 0, y: 0});
    const [imageSport, setImageSport] = useState({x: 0, y: 0});
    const [imageWedd, setImageWedd] = useState({x: 0, y: 0});

    const handlerMoveMouse = useCallback((e) => {
        let coord1;
        let coord2;
        let coord3;
        let coord4;
        let coord5;
        if (e.x > screenWidth / 2 && e.y < screenHeight / 2) {
            coord1 = {x: imageHands.x - (e.x - screenWidth / 2) / 40, y: imageHands.y - (e.y - screenHeight / 2) / 40}
            coord2 = {x: imageDoc.x - (e.x - screenWidth / 2) / 27, y: imageDoc.y - (e.y - screenHeight / 2) / 27}
            coord3 = {x: imageSport.x - (e.x - screenWidth / 2) / 17, y: imageSport.y - (e.y - screenHeight / 2) / 17}
            coord4 = {x: imageWedd.x - (e.x - screenWidth / 2) / 12, y: imageWedd.y - (e.y - screenHeight / 2) / 12}
            coord5 = {x: imageBaby.x - (e.x - screenWidth / 2) / 13, y: imageBaby.y - (e.y - screenHeight / 2) / 13}
        } else {
            coord1 = {x: imageOffice.x + (screenWidth / 2 - e.x) / 40, y: imageOffice.y + (screenHeight / 2 - e.y) / 40}
            coord2 = {x: imageDoc.x + (screenWidth / 2 - e.x) / 27, y: imageDoc.y + (screenHeight / 2 - e.y) / 27}
            coord3 = {x: imageSport.x + (screenWidth / 2 - e.x) / 17, y: imageSport.y + (screenHeight / 2 - e.y) / 17}
            coord4 = {x: imageWedd.x + (screenWidth / 2 - e.x) / 13, y: imageWedd.y + (screenHeight / 2 - e.y) / 13}
            coord5 = {x: imageBaby.x + (screenWidth / 2 - e.x) / 13, y: imageBaby.y + (screenHeight / 2 - e.y) / 13}
        }
        setImageOffice(coord1);
        setImageHands(coord1);
        setImageDoc(coord2);
        setImagePens(coord2);
        setImageSport(coord3);
        setImageFam(coord3);
        setImageWedd(coord5);
        setImageFathson(coord4);
        setImageBaby(coord4);
    }, []);

    const [props1, api] = useSpring(
        () => (
            {
                delay: 6500,
                from: {x: 4046, y: -131, opacity: 0, scale3d: [3, 3, 3], rotate:[20] },
                to: {x: 46, y: 276, opacity: 1, scale3d: [1, 1, 1], rotate:[0]},
                config: {duration: 2500, easings: easings.easeInBounce},
            }),
        []
    );
    const [props2, api2] = useSpring(
        () => (
            {
                from: {x: 4750, y: 125, opacity: 0.5, scale3d: [3, 3, 3], rotate:[20]},
                to: {x: 987, y: 525, opacity: 1, scale3d: [1, 1, 1], rotate:[0]},
                config: {duration: 2500},
                delay: 6500,
            }),
        []
    );
    const [props3, api3] = useSpring(
        () => (
            {
                from: {x: 4388, y: 248, opacity: 0.5, scale3d: [3, 3, 3], rotate:[20]},
                to: {x: 388, y: 648, opacity: 1, scale3d: [1, 1, 1], rotate:[0]},
                config: {duration: 2500},
                delay: 6500,
            }),
        []
    );
    const [props4, api4] = useSpring(
        () => (
            {
                from: {x: 4918, y: -400, opacity: 0.5, scale3d: [3, 3, 3], rotate:[20]},
                to: {x: 918, y: 0, opacity: 1, scale3d: [1, 1, 1], rotate:[0]},
                config: {duration: 2500, easings: easings.easeInBounce},
                delay: 6500,
            }),
        []
    );
    const [props5, api5] = useSpring(
        () => (
            {
                from: {x: 4000, y: -400, opacity: 0.5, scale3d: [3, 3, 3], rotate:[20]},
                to: {x: 0, y: 0, opacity: 1, scale3d: [1, 1, 1], rotate:[0]},
                config: {duration: 2500, easings: easings.easeInBounce},
                delay: 6500,
            }),
        []
    );
    const [props6, api6] = useSpring(
        () => (
            {
                from: {x: 4000, y: 126, opacity: 0.5, scale3d: [3, 3, 3], rotate:[20]},
                to: {x: 0, y: 526, opacity: 1, scale3d: [1, 1, 1], rotate:[0]},
                config: {duration: 2500, easings: easings.easeInBounce},
                delay: 6500,
            }),
        []
    );
    const [props7, api7] = useSpring(
        () => (
            {
                from: {x: 4750, y: 304, opacity: 0.5, scale3d: [3, 3, 3], rotate:[20]},
                to: {x: 761, y: 704, opacity: 1, scale3d: [1, 1, 1], rotate:[0]},
                config: {duration: 2500, easings: easings.easeInBounce},
                delay: 6500,
            }),
        []
    );
    const [props8, api8] = useSpring(
        () => (
            {
                from: {x: 4750, y: -121, opacity: 0.5, scale3d: [3, 3, 3], rotate:[20]},
                to: {x: 897, y: 271, opacity: 1, scale3d: [1, 1, 1], rotate:[0]},
                config: {duration: 2500, easings: easings.easeInBounce},
                delay: 6500,
            }),
        []
    );
    const [props9, api9] = useSpring(
        () => (
            {
                from: {x: 4514, y: -336, opacity: 0.5, scale3d: [3, 3, 3], rotate:[20]},
                to: {x: 514, y: 64, opacity: 1, scale3d: [1, 1, 1], rotate:[0]},
                config: {duration: 2500, easings: easings.easeInBounce,},
                delay: 6500,
            }),
        []
    );

    useEffect(() => {
        const _ref = ref.current;
        _ref.addEventListener('mousemove', handlerMoveMouse);
        return () => {
            _ref.removeEventListener('mousemove', handlerMoveMouse);
        }
    }, [handlerMoveMouse]);

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -150;
        window.scrollTo({top: yCoordinate + yOffset, behavior: 'smooth'});
    }
    return (
        <div className={"for-parrallax"} ref={ref}>
            <FirstHeader/>
            <div className={"parallax container"}>
                <animated.div style={props4}><img src={handsimg} className={"prlx-img four-img"} alt={"руки"}
                                                  style={{left: imageHands.x, top: imageHands.y}}/>
                </animated.div>
                <animated.div style={props5}><img src={officeimg} className={"prlx-img six-img"} alt={"офис"}
                                                  style={{
                                                      left: imageOffice.x,
                                                      top: imageOffice.y
                                                  }}/></animated.div>
                <animated.div style={props1}><img src={doctorimg} className={"prlx-img first-img"} alt={"доктор"}
                                                  style={{left: imageDoc.x, top: imageDoc.y}}/>
                </animated.div>
                <animated.div style={props6}><img src={sportimg} className={"prlx-img eight-img"} alt={"спортик"}
                                                  style={{left: imageSport.x, top: imageSport.y}}/>
                </animated.div>
                <animated.div style={props3}><img src={fathersonimg} className={"prlx-img three-img"} alt={"папа-сын"}
                                                  style={{
                                                      left: imageFathson.x,
                                                      top: imageFathson.y
                                                  }}/></animated.div>
                <animated.div style={props8}><img src={pensimg} className={"prlx-img seven-img"} alt={"пенсионер"}
                                                  style={{left: imagePens.x, top: imagePens.y}}/>
                </animated.div>
                <animated.div style={props2}><img src={familyimg} className={"prlx-img sec-img"} alt={"семья"}
                                                  style={{left: imageFam.x, top: imageFam.y}}/>
                </animated.div>
                <animated.div style={props7}><img src={childimg} className={"prlx-img five-img"} alt={"дитё"}
                                                  style={{left: imageBaby.x, top: imageBaby.y}}/>
                </animated.div>
                <animated.div style={props9}><img src={weddimg} className={"prlx-img nine-img"} alt={"свадьба"}
                                                  style={{left: imageWedd.x, top: imageWedd.y}}/>
                </animated.div>
            </div>
            <div className={"big-inscription"}>
                <h1>Возрождение жизни</h1>
                <p>“Здоровье есть высочайшее богатство человека”</p>
                <p className={"hippo"}>Гиппократ</p>
            </div>
            <HashLink to="/#secondheader" smooth className={"to-down-link"}>
                <div className={"to-down"}>
                    <svg width="24" height="84" viewBox="0 0 24 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M13.5 2C13.5 1.17157 12.8284 0.5 12 0.5C11.1716 0.5 10.5 1.17157 10.5 2H13.5ZM10.9393 83.0607C11.5251 83.6464 12.4749 83.6464 13.0607 83.0607L22.6066 73.5147C23.1924 72.9289 23.1924 71.9792 22.6066 71.3934C22.0208 70.8076 21.0711 70.8076 20.4853 71.3934L12 79.8787L3.51472 71.3934C2.92893 70.8076 1.97919 70.8076 1.3934 71.3934C0.807611 71.9792 0.807611 72.9289 1.3934 73.5147L10.9393 83.0607ZM10.5 2L10.5 82H13.5L13.5 2H10.5Z"
                            fill="#D84313"/>
                    </svg>
                </div>
            </HashLink>
        </div>
    );
}