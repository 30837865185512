import "./VrBlock.sass"
import clock from "../../images/clock.png"
import {Element} from "react-scroll";
import React from "react";
import "animate.css/animate.compat.css";
import ScrollAnimation from 'sgk-react-animation-scroll';

export function VrBlock(){
    return(
        <div className={"main-page-vr"}>
            <Element name="vr-tech" className="element"></Element>
            <div className={"vr-block"}>
                <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true} offset={100}>
                <div className={"vr-header"}>
                    <h2>VR Технологии</h2>
                </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true} offset={100}>
                <div className={"vr-in-develop"}>
                    Раздел находится в разработке
                </div>
                <div className={"vr-clock"}>
                    <img src={clock} alt={"часы"}/>
                </div>
                </ScrollAnimation>
            </div>
        </div>
    );
}