import StarRating from "reactjs-star-rating";
import {useEffect} from "react";

export function Rating(props) {
    const review_rating = props.review_rating;

    useEffect(() => {

    }, [review_rating]);
    return (
        <StarRating
            maxRating={"5"} // Maximum rating value
            color="#D84313" // Color of stars
            size={32} // Size of stars in pixels
            defaultRating={review_rating} // Default rating value
            readOnly={true} // Make rating read only
            showLabel={false} // Show label
            labels={["Плохо", "так себе", "норм", "хорошо", "Отлично"]} // Labels for rating
            className={"review-rating"}
            onSetRating={(rating) => console.log(rating)}
        />
    );
}