import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {editDoctor, profList} from "../../../../reducers/dataReducer";
import Modal from "react-modal";
import {IoTrashOutline} from "react-icons/io5";

import {CKEditor} from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build';
import "./EditDoctor.sass"
import validator from "validator";

export function EditDoctor(props) {
    let edited_doctor = props.doc;
    let proflist = useSelector(profList);

    const dispatch = useDispatch();
    const [showModal, setModal] = useState(false);

    let docId = edited_doctor.id
    const [docName, setName] = useState(edited_doctor.name);
    const [docPatr, setPatr] = useState(edited_doctor.patronymics);
    const [docSurname, setSurname] = useState(edited_doctor.surname);
    const [docSpec, setSpec] = useState(edited_doctor.specialty);
    const [docFullSpec, setFullSpec] = useState(JSON.parse(edited_doctor.fullspeciality));
    const [docAbout, setAbout] = useState(edited_doctor.about);
    const [docHowWork, setHowWork] = useState(edited_doctor.howiwork);
    const [docPhoto, setPhoto] = useState(edited_doctor.photo);
    const [doctorStag, setStag] = useState(edited_doctor.stag);
    const [PhotoFile, setFile] = useState();

    const [docEdu, setEdu] = useState(JSON.parse(edited_doctor.education));
    const [eduYear, setYear] = useState("");
    const [eduText, setEduText] = useState("");
    const [eduImage, setEduImage] = useState("");
    const [eduFile, setEduFile] = useState();

    const [docWorks, setWorks] = useState(JSON.parse(edited_doctor.working));
    const [workYear, setWorkYear] = useState("");
    const [workText, setWorkText] = useState("");
    const [workImage, setWorkImage] = useState("");
    const [workFile, setWorkFile] = useState();

    const [docMetodi, setMetodi] = useState(JSON.parse(edited_doctor.metodi));
    const [metodInput, setMetod] = useState("");

    const [docDocuments, setDocuments] = useState(JSON.parse(edited_doctor.documents));
    const [documentInput , setDocument] = useState("");
    const [documentFile, setDocumentFile] = useState();


    const [selectedProf, setProf] = useState(null);
    const [deletedProf, setDelProf] = useState(null);
    const [deletedStage, setDelStage] = useState(null);
    const [deletedWork, setDelWork] = useState(null);
    const [deletedMetod, setDelMetod] = useState(null);
    const [deletedDocument, setDelDocument] = useState(null);



    //токен
    const [token, setToken] = useState("");
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            setToken(token);
        }
    }, []);
    //__/токен


    let EditDoc = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: "PATCH",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                "name": docName,
                "patronymics": docPatr,
                "surname": docSurname,
                "specialty": docSpec,
                "fullspeciality": JSON.stringify(docFullSpec),
                "about": docAbout,
                "howiwork": docHowWork,
                "photo": docPhoto,
                "stag": doctorStag,
                "education": JSON.stringify(docEdu),
                "working": JSON.stringify(docWorks),
                "metodi": JSON.stringify(docMetodi),
                "documents": JSON.stringify(docDocuments)
            }),
        };
        const response = await fetch(`https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/doctors/edit?doctor_id=${docId}`, requestOptions);
        const data = await response.json();
        if (!response.ok) {
            console.log("Хуйня");
        } else {
            dispatch(editDoctor(data));
        }
        setModal(false);
    };

    let UploadImage = async (e) => {
        e.preventDefault()
        const image = new FormData();
        image.append('uploaded_file', PhotoFile);

        const requestOptions = {
            method: "POST",
            headers: {
                "accept": "application/json",
                Authorization: "Bearer " + token,
            },
            "Content-Type": "multipart/form-data",
            body: image,
        };
        const response = await fetch("https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload-file/", requestOptions);


        const data = await response.json();
        if (!response.ok) {
            console.log(response);
        } else {
            setPhoto(PhotoFile.name);
            console.log(data);
        }
    };

    const AddProf = async (e) => {
        e.preventDefault();
        let add = selectedProf;
        if (!docFullSpec.includes(add)){
            setFullSpec(arr => [...arr, add])
        }
    }

    useEffect(() => {
        if (deletedProf){
            let profs = docFullSpec;
            for (let i = 0; i < profs.length; i++){
                if (deletedProf === profs[i]){
                    profs.splice(i, 1);
                    break
                }
            }
            setFullSpec(profs);
            setDelProf(null);
        }
    },[deletedProf]);

    const AddEduStage = async (e) => {
        e.preventDefault();
        let add = {
            year: eduYear,
            text: eduText,
            img: eduImage,
        }
        if (!docEdu.includes(add)){
            setEdu(arr => [...arr, add])
        }
    }

    useEffect(() => {
        if (deletedStage){
            let stages = docEdu;
            for (let i = 0; i < stages.length; i++){
                if (deletedStage === stages[i]){
                    stages.splice(i, 1);
                    break
                }
            }
            setEdu(stages);
            setDelStage(null);
        }
    },[deletedStage]);

    let UploadImageEdu = async (e) => {
        e.preventDefault()
        const image = new FormData();
        image.append('uploaded_file', eduFile);

        const requestOptions = {
            method: "POST",
            headers: {
                "accept": "application/json",
                Authorization: "Bearer " + token,
            },
            "Content-Type": "multipart/form-data",
            body: image,
        };
        const response = await fetch("https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload-file/", requestOptions);


        const data = await response.json();
        if (!response.ok) {
            console.log(response);
        } else {
            setEduImage(eduFile.name);
            console.log(data);
        }
    };

    const AddWork = async (e) => {
        e.preventDefault();
        let add = {
            year: workYear,
            text: workText,
            img: workImage,
        }
        if (!docWorks.includes(add)){
            setWorks(arr => [...arr, add])
        }
    };

    useEffect(() => {
        if (deletedWork){
            let works = docWorks;
            for (let i = 0; i < works.length; i++){
                if (deletedWork === works[i]){
                    works.splice(i, 1);
                    break
                }
            }
            setWorks(works);
            setDelWork(null);
        }
    },[deletedWork]);


    let UploadImageWork = async (e) => {
        e.preventDefault()
        const image = new FormData();
        image.append('uploaded_file', workFile);

        const requestOptions = {
            method: "POST",
            headers: {
                "accept": "application/json",
                Authorization: "Bearer " + token,
            },
            "Content-Type": "multipart/form-data",
            body: image,
        };
        const response = await fetch("https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload-file/", requestOptions);


        const data = await response.json();
        if (!response.ok) {
            console.log(response);
        } else {
            setWorkImage(workFile.name);
            console.log(data);
        }
    };

    const AddMetod = async (e) => {
        e.preventDefault();
        let add = metodInput;
        if(!docMetodi.includes(add)){
            setMetodi(arr => [...arr, add]);
            setDelMetod("");
        }
    }

    useEffect(() => {
        if (deletedMetod){
            let metodi = docMetodi;
            for (let i = 0; i < metodi.length; i++){
                if (deletedMetod === metodi[i]){
                    metodi.splice(i, 1);
                    break
                }
            }
            setMetodi(metodi);
            setDelMetod("");
        }
    },[deletedMetod]);

    const AddDocument = async (e) => {
        e.preventDefault();
        let add = documentInput;
        if (!docDocuments.includes(add)) {
            setDocuments(arr => [...arr, add]);

            setDelDocument("");
        }
    }
    useEffect(() => {
        if (deletedDocument) {
            let documents = docDocuments;
            for (let i = 0; i < documents.length; i++) {
                if (deletedDocument === documents[i]) {
                    documents.splice(i, 1);
                    break
                }
            }
            setDocuments(documents);
            setDelDocument("");
        }
    }, [deletedDocument]);
    let UploadDocumentImage = async (e) => {
        e.preventDefault()
        const image = new FormData();
        image.append('uploaded_file', documentFile);

        const requestOptions = {
            method: "POST",
            headers: {
                "accept": "application/json",
                Authorization: "Bearer " + token,
            },
            "Content-Type": "multipart/form-data",
            body: image,
        };
        const response = await fetch("https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload-file/", requestOptions);


        const data = await response.json();
        if (!response.ok) {
            console.log(response);
        } else {
            setDocument(documentFile.name);
            console.log(data);
        }
    };

    return(
        <div className={"edit-doctor-button"}>
            <button onClick={() => setModal(true)}>Изменить</button>
            <Modal isOpen={showModal} contentLabel="Форма: Добавить врача">
                <h2>Редактировать</h2>
                <form>
                    <label>
                        <span>Имя</span>
                        <input
                            name="name"
                            type="text"
                            value={docName}
                            onChange={(event) => setName(event.target.value)}
                        />
                    </label>
                    <br/>
                    <label>
                        <span>Отчество</span>
                        <input
                            name="patronymics"
                            type="text"
                            value={docPatr}
                            onChange={(event) => setPatr(event.target.value)}
                        />
                    </label>
                    <br/>
                    <label>
                        <span>Фамилия</span>
                        <input
                            name="surname"
                            type="text"
                            value={docSurname}
                            onChange={(event) => setSurname(event.target.value)}
                        />
                    </label>
                    <br/>
                    <label>
                        <span>Специальность</span>
                        <input
                            name="specialty"
                            type="text"
                            value={docSpec}
                            onChange={(event) => setSpec(event.target.value)}
                        />
                    </label>
                    <br/>
                    <label>
                        <select onChange={(event) => setProf(event.target.value)}>
                            <option value={""}>Выберите специальность</option>
                            {proflist.map((prof, index) => {
                                return (
                                    <option key={index} value={prof}>{prof}</option>
                                )
                            })}
                        </select>
                        <button onClick={AddProf}>добавить специальность</button>
                        <div className={"chosed-profs"}>
                            {docFullSpec.map((prof, index) => {
                                return (
                                    <div className={"prof"} key={index}>
                                        {prof}
                                        <span onClick={() => setDelProf(prof)}><IoTrashOutline/></span>
                                    </div>
                                )
                            })}
                        </div>
                    </label>
                    <br/>
                    <label>
                        <span>О враче</span>
                        <CKEditor
                            editor={Editor}
                            data={docAbout}
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setAbout(data);
                            }}

                        />
                    </label>
                    <br/>
                    <label>
                        <span>Как я работаю</span>
                        <CKEditor
                            editor={Editor}
                            data={docHowWork}
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setHowWork(data);
                            }}

                        />
                    </label>
                    <br/>
                    <label>
                        <span>Фото</span>
                        <input
                            name="about"
                            type="text"
                            value={docPhoto}
                            onChange={(event) => setPhoto(event.target.value)}
                        />
                    </label>
                    <label>
                        <input
                            name="article-image-file"
                            type="file"
                            onChange={(event) => setFile(event.target.files[0])}
                        />
                        <button onClick={UploadImage}> загрузить</button>
                    </label>
                    <br/>
                    <label>
                        <span>Стаж</span>
                        <input
                            name="about"
                            type="text"
                            value={doctorStag}
                            onChange={(event) => setStag(event.target.value)}
                        />
                    </label>
                    <br/>
                    <label>Oбразование</label>
                    <br/>
                    <label>
                        <span>год</span>
                        <input
                            name="year"
                            type="text"
                            value={eduYear}
                            onChange={(event) => setYear(event.target.value)}
                        />
                        <span>текст</span>
                        <input
                            name="edutext"
                            type="text"
                            value={eduText}
                            onChange={(event) => setEduText(event.target.value)}
                        />
                        <span>фото</span>
                        <input
                            name="eduimage"
                            type="text"
                            value={eduImage}
                            onChange={(event) => setEduImage(event.target.value)}
                        />
                        <label>
                            <input
                                name="disease-image-file"
                                type="file"
                                onChange={(event) => setEduFile(event.target.files[0])}
                            />
                            <button onClick={UploadImageEdu}> загрузить</button>
                        </label>
                        <button onClick={AddEduStage}>добавить специальность</button>
                        <div className={"edu-list"}>
                            {docEdu.map((stage, index) => {
                                return (
                                    <div key={index}>
                                        {stage.year + " " + stage.text + " " + stage.img}<span
                                        onClick={() => setDelStage(stage)}><IoTrashOutline/></span>
                                    </div>
                                )
                            })}
                        </div>
                    </label>
                    <br/>
                    <label>Работа</label>
                    <br/>
                    <label>
                        <span>год</span>
                        <input
                            name="work-year"
                            type="text"
                            value={workYear}
                            onChange={(event) => setWorkYear(event.target.value)}
                        />
                        <span>текст</span>
                        <input
                            name="work-text"
                            type="text"
                            value={workText}
                            onChange={(event) => setWorkText(event.target.value)}
                        />
                        <span>фото</span>
                        <input
                            name="work-image"
                            type="text"
                            value={workImage}
                            onChange={(event) => setWorkImage(event.target.value)}
                        />
                        <label>
                            <input
                                name="work-image-file"
                                type="file"
                                onChange={(event) => setWorkFile(event.target.files[0])}
                            />
                            <button onClick={UploadImageWork}> загрузить</button>
                        </label>
                        <button onClick={AddWork}>добавить работу</button>
                        <div className={"edu-list"}>
                            {docWorks.map((work, index) => {
                                return (
                                    <div key={index}>
                                        {work.year + " " + work.text + " " + work.img}<span
                                        onClick={() => setDelWork(work)}><IoTrashOutline/></span>
                                    </div>
                                )
                            })}
                        </div>
                    </label>
                    <br/>
                    <label>
                        <span>Методики</span>
                        <input
                            name="metodi"
                            type="text"
                            value={metodInput}
                            onChange={(event) => setMetod(event.target.value)}
                        />
                        <button onClick={AddMetod}>добавить специальность</button>
                        <div className={"metodi-list"}>
                            {docMetodi.map((metod, index) => {
                                return (
                                    <div key={index}>
                                        {metod}<span onClick={() => setDelMetod(metod)}><IoTrashOutline/></span>
                                    </div>
                                )
                            })}
                        </div>
                    </label>
                    <br/>
                    <label>
                        <span>Документы</span>
                        <input
                            name="documents"
                            type="text"
                            value={documentInput}
                            onChange={(event) => setDocument(event.target.value)}
                        />
                        <label>
                            <input
                                name="document-image-file"
                                type="file"
                                onChange={(event) => setDocumentFile(event.target.files[0])}
                            />
                            <button onClick={UploadDocumentImage}> загрузить</button>
                        </label>
                        <button onClick={AddDocument}>добавить документ</button>
                        <div className={"documents-list"}>
                            {docDocuments.map((document, index) => {
                                return (
                                    <div key={index}>
                                        {document}<span
                                        onClick={() => setDelDocument(document)}><IoTrashOutline/></span>
                                    </div>
                                )
                            })}
                        </div>
                    </label>
                </form>
                <button
                    onClick={EditDoc}
                    className="AddButton"
                >
                    Изменить
                </button>
                <button onClick={() => setModal(false)} className="closeButton">
                    Закрыть
                </button>
            </Modal>
        </div>
    );


}