import Modal from "react-modal";
import validator from "validator";
import React, {useEffect, useState} from "react";
import {addService} from "../../../../reducers/dataReducer";
import {useDispatch} from "react-redux";

import {CKEditor} from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build';

export function AddService(){
    const [showModal, setModal] = useState(false);

    const [serviceName, setName] = useState("");
    const [serviceImage, setImage] = useState("");
    const [serviceText, setText] = useState("");
    const [serviceFile, setFile] = useState();

    const dispatch = useDispatch();

    //токен
    const [token, setToken] = useState("");
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            setToken(token);
        }
    }, []);
    //__/токен

    const [inputsValid, setValid] = useState({
        name: validator.isLength(serviceName, {min: 5, max: 40}),
        image: validator.isLength(serviceImage, {min: 5, max: 40}),
        text: validator.isLength(serviceText, {min: 5, max: 20000}),
    });

    const formValid = (values) => {
        return !(values.image === true &&
            values.name === true &&
            values.text === true);
    };

    let UploadImage = async (e) => {
        e.preventDefault()
        const image = new FormData();
        image.append('uploaded_file', serviceFile);

        const requestOptions = {
            method: "POST",
            headers: {
                "accept": "application/json",
                Authorization: "Bearer " + token,
            },
            "Content-Type": "multipart/form-data",
            body: image,
        };
        const response = await fetch("https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload-file/", requestOptions);


        const data = await response.json();
        if (!response.ok) {
            console.log(response);
        } else {
            setImage(serviceFile.name);
            setValid({
                ...inputsValid,
                image: validator.isLength(serviceFile.name, {
                    min: 5,
                    max: 40,
                }),
            });
            console.log(data);
        }
    }

    let AddService = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: "POST",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                "id": 0,
                "name": serviceName,
                "image": serviceImage,
                "text": serviceText,
                "diseases": [],
                "spec_articles": []
            }),
        };
        const response = await fetch("https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/services/add", requestOptions);
        const data = await response.json();
        if (!response.ok) {
            console.log("Хуйня");
        } else {
            dispatch(addService(data));
        }
        setModal(false);
    };

    return(
        <div className={"add-service-form"}>
            <div className={"add-service"}>
                <button onClick={() => setModal(true)} className={"add-article-button"}>Добавить</button>
            </div>
            <Modal isOpen={showModal} contentLabel="Форма: Добавить сервис">
                <h2>Добавить раздел</h2>
                <form>
                    <label>
                        <span>Название</span>
                        <input
                            name="service-name"
                            type="text"
                            value={serviceName}
                            onChange={(event) => setName(event.target.value)}
                            onInput={(event) =>
                                setValid({
                                    ...inputsValid,
                                    name: validator.isLength(event.target.value, {
                                        min: 5,
                                        max: 40,
                                    }),
                                })
                            }
                        />
                        <span className="warning">
                          {inputsValid.name === true
                              ? ""
                              : "Введите название!"}
                        </span>
                    </label>
                    <br/>
                    <label>
                        <span>Фото</span>
                        <input
                            name="service-image"
                            type="text"
                            value={serviceImage}
                            onChange={(event) => setImage(event.target.value)}
                            onInput={(event) =>
                                setValid({
                                    ...inputsValid,
                                    image: validator.isLength(event.target.value, {
                                        min: 5,
                                        max: 40,
                                    }),
                                })
                            }
                        />
                        <span className="warning">
                          {inputsValid.image === true
                              ? ""
                              : "Введите название!"}
                        </span>
                    </label>
                    <label>
                        <input
                            name="article-image-file"
                            type="file"
                            onChange={(event) => setFile(event.target.files[0])}
                        />
                        <button onClick={UploadImage}> загрузить</button>
                    </label>
                    <br/>
                    <label>
                        <span>Текст Статьи</span>
                        <CKEditor
                            editor={Editor}
                            data={serviceText}
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setText(data);
                                setValid({
                                    ...inputsValid,
                                    text: validator.isLength(data, {
                                        min: 5,
                                        max: 20000,
                                    }),
                                });
                            }}

                        />
                        <span className="warning">
                              {inputsValid.text === true
                                  ? ""
                                  : "Введите Текст!"}
                            </span>
                    </label>
                </form>
                <button
                    onClick={AddService}
                    className="AddButton"
                    disabled={formValid(inputsValid)}
                >
                    Добавить
                </button>
                <button onClick={() => setModal(false)} className="closeButton">
                    Закрыть
                </button>
            </Modal>
        </div>
    );
}