import React, {useState, useEffect} from 'react';
import {Panel} from "./Panel";

export function LoginForm() {
    const [token, setToken] = useState();
    const [username, setName] = useState("");
    const [password, setPass] = useState("");
    const [message, setMessage] = useState("");
    useEffect(() => {
      sessionStorage.setItem('token', JSON.stringify(token));
    }, [token]);

    let handleSubmit = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            body: JSON.stringify(
                `grant_type=&username=${username}&password=${password}&scope=&client_id=&client_secret=`
            ),
        };
        const response = await fetch("https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/token", requestOptions);
        const data = await response.json();
        if (!response.ok) {
            console.log("Хуйня")
        } else {
            setToken(data);
        }
    };


    return (
        <div>
            {!token ?
                <form onSubmit={handleSubmit}>
                    <label>
                        <p>Username</p>
                        <input type="text" value={username} placeholder={"Введите логин"}
                               onChange={(event) => setName(event.target.value)}/>
                    </label>
                    <label>
                        <p>Password</p>
                        <input type="password" value={password} placeholder={"Введите пароль"}
                               onChange={(event) => setPass(event.target.value)}/>
                    </label>
                    <div>
                        <button type="submit">Submit</button>
                    </div>
                </form>
                : <Panel/>}
        </div>
    )
}