import {Sechead} from "../../components/Sechead/Sechead";
import {Document, Page} from 'react-pdf';
import React, {useState} from "react";
import {pdfjs} from 'react-pdf';
import "./Licenses.sass";
import Modal from "react-modal";
import { IoCloseOutline } from "react-icons/io5";
import {Link} from "react-router-dom";
import {MainContacts} from "../../components/MainContacts/MainContacts";
import {Footer} from "../../components/Footer/Footer";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

export function Licenses() {
    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);

    const [showModalLicense, setModalLicense] = useState(false);
    const [showModalOgrn, setModalOgrn] = useState(false);
    const [showModalInn, setModalInn] = useState(false);
    const [showModaEgrul, setModalEgrul] = useState(false);

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    return (
        <div className={"licenses-all"}>
            <Sechead/>
            <div className={"licenses-page"}>
                <h1>Лицензии</h1>
                <ul className={"docs-list"}>
                    <li className={"docs-list-item"}>
                        <div className={"docs-list-item-button"} onClick={() => setModalLicense(true)}>Выписка из
                            реестра
                            лицензий
                        </div>
                        <Modal isOpen={showModalLicense} contentLabel="Форма: Добавить статью" className="Modal"
                               overlayClassName="Overlay">
                            <div className={"docs-list-modal-close-button"} onClick={() => setModalLicense(false)}>
                                <IoCloseOutline size={30}/></div>
                            <Document
                                file={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/test.pdf"}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page pageNumber={pageNumber} renderAnnotationLayer={false} renderTextLayer={false}
                                      width={450}/>
                            </Document>
                            <div className={"pdf-nav"}>
                                <p>
                                    Страница {pageNumber || (numPages ? 1 : '--')} из {numPages || '--'}
                                </p>
                                <button
                                    type="button"
                                    disabled={pageNumber <= 1}
                                    onClick={previousPage}
                                >
                                    Предыдущая
                                </button>
                                <button
                                    type="button"
                                    disabled={pageNumber >= numPages}
                                    onClick={nextPage}
                                >
                                    Следующая
                                </button>
                                <div className={"link-open"}>
                                    <Link to={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/test.pdf"} target={"_blank"}>открыть в новой вкладке</Link>
                                </div>
                            </div>
                        </Modal>
                    </li>
                    <li className={"docs-list-item"}>
                        <div className={"docs-list-item-button"} onClick={() => setModalOgrn(true)}>ОГРН</div>
                        <Modal isOpen={showModalOgrn} contentLabel="Форма: Добавить статью" className="Modal"
                               overlayClassName="Overlay">
                            <div className={"docs-list-modal-close-button"} onClick={() => setModalOgrn(false)}>
                                <IoCloseOutline size={30}/></div>
                            <Document
                                file={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/ogrn_inn.pdf"}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page pageNumber={pageNumber} renderAnnotationLayer={false} renderTextLayer={false}
                                      width={450}/>
                            </Document>
                            <div className={"pdf-nav"}>
                                <p>
                                    Страница {pageNumber || (numPages ? 1 : '--')} из {numPages || '--'}
                                </p>
                                <button
                                    type="button"
                                    disabled={pageNumber <= 1}
                                    onClick={previousPage}
                                >
                                    Предыдущая
                                </button>
                                <button
                                    type="button"
                                    disabled={pageNumber >= numPages}
                                    onClick={nextPage}
                                >
                                    Следующая
                                </button>
                                <div className={"link-open"}>
                                    <Link to={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/ogrn_inn.pdf"} target={"_blank"}>открыть в новой вкладке</Link>
                                </div>
                            </div>
                        </Modal>
                    </li>
                    <li className={"docs-list-item"}>
                        <div className={"docs-list-item-button"} onClick={() => setModalInn(true)}>ИНН</div>
                        <Modal isOpen={showModalInn} contentLabel="Форма: Добавить статью" className="Modal"
                               overlayClassName="Overlay">
                            <div className={"docs-list-modal-close-button"} onClick={() => setModalInn(false)}>
                                <IoCloseOutline size={30}/></div>
                            <Document
                                file={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/ogrn_inn.pdf"}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page pageNumber={pageNumber} renderAnnotationLayer={false} renderTextLayer={false}
                                      width={450}/>
                            </Document>
                            <div className={"pdf-nav"}>
                                <p>
                                    Страница {pageNumber || (numPages ? 1 : '--')} из {numPages || '--'}
                                </p>
                                <button
                                    type="button"
                                    disabled={pageNumber <= 1}
                                    onClick={previousPage}
                                >
                                    Предыдущая
                                </button>
                                <button
                                    type="button"
                                    disabled={pageNumber >= numPages}
                                    onClick={nextPage}
                                >
                                    Следующая
                                </button>
                                <div className={"link-open"}>
                                    <Link to={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/ogrn_inn.pdf"} target={"_blank"}>открыть в новой вкладке</Link>
                                </div>
                            </div>
                        </Modal>
                    </li>
                    <li className={"docs-list-item"}>
                        <div className={"docs-list-item-button"} onClick={() => setModalEgrul(true)}>Выписка ЕГРЮЛ</div>
                        <Modal isOpen={showModaEgrul} contentLabel="Форма: Добавить статью" className="Modal"
                               overlayClassName="Overlay">
                            <div className={"docs-list-modal-close-button"} onClick={() => setModalEgrul(false)}>
                                <IoCloseOutline size={30}/></div>
                            <Document
                                file={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/egrul.pdf"}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page pageNumber={pageNumber} renderAnnotationLayer={false} renderTextLayer={false}
                                      width={450}/>
                            </Document>
                            <div className={"pdf-nav"}>
                                <p>
                                    Страница {pageNumber || (numPages ? 1 : '--')} из {numPages || '--'}
                                </p>
                                <button
                                    type="button"
                                    disabled={pageNumber <= 1}
                                    onClick={previousPage}
                                >
                                    Предыдущая
                                </button>
                                <button
                                    type="button"
                                    disabled={pageNumber >= numPages}
                                    onClick={nextPage}
                                >
                                    Следующая
                                </button>
                                <div className={"link-open"}>
                                    <Link to={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/egrul.pdf"} target={"_blank"}>открыть в новой вкладке</Link>
                                </div>
                            </div>
                        </Modal>
                    </li>


                </ul>
            </div>
            <MainContacts/>
            <Footer/>
        </div>
    );
}