import {useDispatch, useSelector} from "react-redux";
import {imagesGalleryList, AddImage, DelImage} from "../../../../reducers/dataReducer";
import {useEffect, useState} from "react";
import {EditNewsForm} from "../News/EditNewsForm";
import "./Gallery.sass"
import {AddNewImage} from "./AddImage";

export function AdminGallery(){
    const gallery = useSelector(imagesGalleryList);
    const dispatch = useDispatch();

    const[deletedImage, setDel] = useState(0);

    let DeleteImage = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: "DELETE",
            headers: {
                "accept": "application/json",
                Authorization: "Bearer " + token,
            },

        };
        const response = await fetch(`https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/images-gallery/delete?image_id=${deletedImage}`, requestOptions);
        const data = await response.json();
        if (!response.ok) {
            console.log("Хуйня");
        } else {
            dispatch(DelImage(deletedImage));
            alert(data);
        }
    };


    //токен
    const [token, setToken] = useState("");
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            setToken(token);
        }
    }, []);
    //__/токен


    return(
        <div className={"images-all"}>
            <h2>Галерея</h2>
            <AddNewImage/>
            <div className={"image-list"}>
                {gallery.map((image) =>{
                    return(
                        <div className={"image-item"} key={image.id}>
                            <img src={image.url} alt={"image" + image.id}/>
                            <div className={"news-actions"} onMouseEnter={() => setDel(image.id)}>
                                <button className={"button-del"} onClick={DeleteImage}>Удалить</button>
                            </div>
                        </div>
                    )
                })}
            </div>

        </div>
    );
}