import { deletePriceItem, priceList } from "../../../../reducers/dataReducer";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import "./Price.sass"
import {AddPriceItem} from "./AddPriceItem";
import {AddPriceSection} from "./AddPriceSection";
import {Accordion, AccordionItem} from "@szhsin/react-accordion";

export function Price(){
    const price = useSelector(priceList);
    const [activeSection, setSection] = useState(price[0].childrens[0]);
    const dispatch = useDispatch();

    console.log(activeSection);

    const [deletedPriceItem, setItem] = useState();

    //токен
    const [token, setToken] = useState("");
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            setToken(token);
        }
    }, []);
    //__/токен

    useEffect(() => {
        for (let section of price){
            for (let item of section.childrens){
                if(item.id === activeSection.id){
                    setSection(item);
                    break;
                }
            }
        }



    }, [activeSection, price]);

    let DeletePriceItem = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: "DELETE",
            headers: {
                "accept": "application/json",
                Authorization: "Bearer " + token,
            },

        };
        const response = await fetch(`https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/price/delete_item?item_id=${deletedPriceItem}`, requestOptions);
        const data = await response.json();
        if (!response.ok) {
            console.log("Хуйня");
        } else {
            dispatch(deletePriceItem(deletedPriceItem));

            alert(data);
        }
    };



    return(
        <div className={"admin-price"}>
            <div className={"left-column"}>
                <Accordion transition transitionTimeout={250} allowMultiple>
                                {price.map((razdel) => {
                                    return (
                                        <AccordionItem className={"razdel"} key={razdel.id}
                                                       header={
                                                           <>
                                                               {razdel.parent_name}
                                                               <svg width="8" height="17" viewBox="0 0 8 17" fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                   <path
                                                                       d="M4.5 1C4.5 0.723858 4.27614 0.5 4 0.5C3.72386 0.5 3.5 0.723858 3.5 1L4.5 1ZM3.64645 16.3536C3.84171 16.5488 4.15829 16.5488 4.35355 16.3536L7.53553 13.1716C7.7308 12.9763 7.7308 12.6597 7.53553 12.4645C7.34027 12.2692 7.02369 12.2692 6.82843 12.4645L4 15.2929L1.17157 12.4645C0.976311 12.2692 0.659729 12.2692 0.464467 12.4645C0.269204 12.6597 0.269204 12.9763 0.464467 13.1716L3.64645 16.3536ZM3.5 1L3.5 16L4.5 16L4.5 1L3.5 1Z"
                                                                       fill="#4E4E4E"/>
                                                               </svg>
                                                           </>
                                                       }>
                                            {razdel.childrens.map((child) => {
                                                return (
                                                    <div key={child.id} onClick={() => setSection(child)}>
                                                        {child.name}
                                                    </div>
                                                );
                                            })}
                                        </AccordionItem>
                                    );
                                })}
                            </Accordion>
                <AddPriceSection/>
            </div>
            <div className={"right-column"}>
                <h2>{activeSection.name}</h2>
                {activeSection.services.map((price_item) => {
                    return(
                        <div className={"price-item"} key={price_item.id}>
                            <div className={"price-item-name"}>
                                {price_item.name}
                            </div>
                            <div className={"price-item-price"}>
                                {price_item.price}
                            </div>
                            <div className={"price-item-actions"} onMouseEnter={() => setItem(price_item.id)}>
                                <button className={"doc-button"} onClick={DeletePriceItem}>удалить</button>
                            </div>
                        </div>
                    )
                })}
                <AddPriceItem/>
            </div>
        </div>
    );
}