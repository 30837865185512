import './Spec.sass';
import {doctorsList, profList} from "../../reducers/dataReducer";
import {useSelector} from "react-redux";
import React, {useState} from "react";
import {Sechead} from "../../components/Sechead/Sechead";
import {Link} from "react-router-dom";
import {Footer} from "../../components/Footer/Footer";
import {MainContacts} from "../../components/MainContacts/MainContacts";



export function Specs() {
    let doctors = useSelector(doctorsList);
    let allSpeciality = useSelector(profList);


    const [filter, setFilter] = useState("Все");
    console.log(filter);

    let filterMassive = [];

    for (let doctor of doctors){
        if(filter === "Все"){
            filterMassive = doctors;
            break;
        }
        let specs = JSON.parse(doctor.fullspeciality);
        for(let spec of specs){
            if(spec === filter){
                filterMassive.push(doctor);
            }
        }
    }

    return (
        <div className={"specs-main"}>
            <Sechead/>
            <div className="specs-page">
                <div className={"bread"}>
                    <ul>
                        <li><Link to="/">Главная</Link></li>
                        <li>Специалисты</li>
                    </ul>
                </div>
                <div className={"doctors-filter"}>
                    <div className={"doctors-filter"}>
                        {allSpeciality.map((item, index) => {
                            return (
                                <div className={filter === item ? "prof-button-active" : "prof-button"} key={index}
                                     onClick={() => setFilter(item)}>
                                    <div className={"prof-button-text"}>{item}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className={"search-results"}>
                    {filterMassive.map((doctor) => {
                        let photo
                        if (doctor.photo === "no-photo") {
                            photo = "https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/placeholder.png"
                        } else {
                            photo = "https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/" + doctor.photo
                        }
                        return (
                            <div className={"spec-block"} key={doctor.id}>
                                <Link to={"/doctor/" + doctor.surname + "_" + doctor.name + "_" + doctor.patronymics}>
                                <div className={"spec-img"}>
                                    <img src={photo} alt={doctor.specialty}/>
                                </div>
                                <div className={"spec-fio"}>
                                        <h4>{doctor.surname} <br/>{doctor.name} {doctor.patronymics}</h4>
                                </div>
                                <div className={"spec-stag"}>
                                    стаж <span>{doctor.stag}</span>
                                </div>
                                <div className={"spec-spec"}>
                                    {doctor.specialty}
                                </div>
                                </Link>
                            </div>
                        );
                    })}
                </div>
            </div>
            <MainContacts/>
            <Footer/>
        </div>
    );
}