import "./Blog.sass";
import {useSelector} from "react-redux";
import {categoriesList} from "../../reducers/dataReducer";
import React, {useState} from 'react';
import {Sechead} from "../../components/Sechead/Sechead";
import parse from 'html-react-parser';

export function Blog() {
    let categories = useSelector(categoriesList);
    const [activeCategory, setCategory] = useState(categories[0]);
    return (
        <div>
             <Sechead/>
            <div className={"blog-main container"}>
                <div className={"left-column"}>
                    <h1>Оглавление</h1>
                    <ul className={"categories-table"}>
                        {categories.map((category) => {
                            return (
                                <li className={"cats-item"} key={"cat-" + category.id}
                                    onClick={() => setCategory(category)}>
                                    <h2>{category.name}</h2>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className={"right-column"}>
                    <div className={"category-item"}>
                        <h2>{activeCategory.name}</h2>
                        <p>{activeCategory.description}</p>
                        {activeCategory.articles.map((article) => {
                            return (
                                <div className={"article-item"} key={activeCategory.id + article.article_id}>
                                    <h2>{article.header}</h2>
                                    <p>{article.description}</p>
                                    <div className={"article-text"}>
                                        {parse(article.text)}
                                    </div>
                                    <div className={"article-date"}>
                                        {article.date}
                                    </div>
                                    <div className={"article-author"}>
                                        {article.author}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}