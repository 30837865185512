import "./MainContacts.sass";
import watsap from "../../images/whatsapp.png";
import telega from "../../images/telega.png";
import mail from "../../images/mail.png";
import phone from "../../images/phone.png";
import youtube from "../../images/youtube.png";
import vk from "../../images/vk.png";
import {Element} from "react-scroll";
import mapIcon from "../../images/marker.png";
import {YMaps, Map, Placemark} from '@pbe/react-yandex-maps';
import ScrollAnimation from 'sgk-react-animation-scroll';
import {Link} from "react-router-dom";
import React from "react";


export function MainContacts() {
    const availableScreenWidth = window.screen.availWidth;
    let map_width;
    let map_height;
    if (availableScreenWidth > 1439){
        map_width = 660;
        map_height = 493;
    }else {
        map_width = Math.trunc(availableScreenWidth/100 * 50);
        map_height = Math.trunc(map_width / 1.3387);
    }


    return (
        <div className={"main-page-contacts"}>
            <Element name="contacts" className="element"></Element>
            <div className={"contacts-block"}>
                <div className={"map-side"} id={"map-here"}>
                    <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true} offset={100}>
                        <div className={"map-main"}>
                            <YMaps>
                                <Map
                                    defaultState={{
                                        center: [56.005878, 92.849005],
                                        zoom: 17,
                                        controls: ["zoomControl", "fullscreenControl"],
                                        behaviors: ["drag"],
                                    }}
                                    modules={["control.ZoomControl", "control.FullscreenControl", "geoObject.addon.balloon", "geoObject.addon.hint"]}
                                    width={map_width}
                                    height={map_height}
                                >
                                    <Placemark defaultGeometry={[56.005878, 92.849005]}
                                               properties={{
                                                   hintContent: "г. Красноярск, ул. Горького, 3",
                                                   balloonContent: 'Мы находимся здесь'
                                               }}
                                               options={{
                                                   iconLayout: 'default#image',
                                                   iconImageHref: mapIcon,
                                                   iconImageSize: [33, 45],
                                                   hideIconOnBalloonOpen: false,
                                                   openEmptyHint: true,
                                               }}
                                    />
                                </Map>
                            </YMaps>
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true} offset={100}>
                        <h3>С нами также можно связаться в соц.сетях:</h3>
                        <div className={"social"}>
                            <div className={"social-item"}>
                                <Link to={"tel:+73912303030"}><img src={phone} alt={"телефон"}/></Link>
                        </div>
                            <div className={"social-item"}>
                                <Link to={"mailto:revivallive@mail.ru"}><img src={mail} alt={"почта"}/></Link>
                            </div>
                            <div className={"social-item"}>
                                <Link to={"https://t.me/+79029752255"}><img src={telega} alt={"телеграм"}/></Link>
                            </div>
                            <div className={"social-item"}>
                                <Link to={"https://www.youtube.com/@centr.revival"}><img src={youtube} alt={"Youtube"}/></Link>
                            </div>
                            <div className={"social-item"}>
                                <Link to={"https://wa.me/79029752255"}><img src={watsap} alt={"вотсап"}/></Link>
                            </div>
                            <div className={"social-item"}>
                                <a><img src={vk} alt={"вконтакте"}/></a>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
                <div className={"contact-side"}>
                    <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true} offset={100}>
                        <h2>Наши контакты:</h2>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true} offset={100}>
                        <div className={"tel"}>
                            <h3>Телефон:</h3>
                            <a href={"tel:+73912000707"}>+7 (391) 200 07 07</a>
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true} offset={100}>
                        <div className={"adr"}>
                            <h3>Адрес:</h3>
                            <p>г. Красноярск, ул. Горького, 3</p>
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true} offset={100}>
                        <div className={"chas"}>
                            <h3>Часы работы:</h3>
                            <p>Пн-Сб: 9:00 - 21:00&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Вс: 10:00
                                - 20:00</p>
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true} offset={100}>
                        <div className={"mail"}>
                            <h3>E-mail:</h3>
                            <a href={"mailto:revivallive@mail.ru"}>revivallive@mail.ru</a>
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true} offset={100}>
                        <div className={"button-priem"}>
                            <button>Записаться на приём</button>
                        </div>
                    </ScrollAnimation>
                </div>
            </div>
        </div>
    );
}