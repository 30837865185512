import {imagesGalleryList} from "../../reducers/dataReducer";
import {useSelector} from "react-redux";

import React, {useEffect, useState} from "react";

import PhotoAlbum from "react-photo-album";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import {Sechead} from "../../components/Sechead/Sechead";

import "./Gallery.sass"
import {MainContacts} from "../../components/MainContacts/MainContacts";
import {Footer} from "../../components/Footer/Footer";
import {Link} from "react-router-dom";

export function Gallery() {
    const [index, setIndex] = useState(-1);
    let images = useSelector(imagesGalleryList);

    const getMeta = (url, cb) => {
        const img = new Image();
        img.onload = async () => await cb(null, img);
        img.onerror = (err) => cb(err);
        img.src = url;
        return img
    };



    let photos = [];


    for (let item of images) {
        let x
        x = getMeta(item.url, async (err, img) => {});
        console.log(x);
        photos.push({src: x.src, width: x.naturalWidth, height: x.naturalHeight});
    }
    console.log(photos);
    const [photosList, setList] = useState(photos);

    useEffect(() => {
        setList(photos);
    }, [photos]);


    return (
        <div className={"gallery-main"}>
            <Sechead/>
            <div className={"gallery-main-page"}>
                <div className={"bread"}>
                    <ul>
                        <li><Link to="/">Главная</Link></li>
                        <li>Галерея</li>
                    </ul>
                </div>
                <h1>Галерея</h1>
                {photosList.length === images.length ?
                    <PhotoAlbum photos={photosList} layout="rows" targetRowHeight={300} onClick={({index}) => setIndex(index)} srcSet={photos}/>:
                    <div> загрузка</div>
                }

                <Lightbox
                    slides={photosList}
                    open={index >= 0}
                    index={index}
                    close={() => setIndex(-1)}
                    // enable optional lightbox plugins
                    plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                />
            </div>
            <MainContacts/>
            <Footer/>
        </div>
    );
}