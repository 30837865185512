import './MainPage.sass';
import React, {useEffect} from "react";
import {Sechead} from "../../components/Sechead/Sechead";
import {Parallax} from "../../components/Parallax/Parallax";
import {Directions} from "../../components/Directions/Directions";
import {Specialists} from "../../components/Specialists/Specialists";
import {MainPrice} from "../../components/MainPrice/MainPrice";
import {BlogBlock} from "../../components/BlogBlock/BlogBlock";
import {VrBlock} from "../../components/VrBlock/VrBlock";
import {News} from "../../components/News/News";
import {Appointment} from "../../components/Appointment/Appointment";
import {WhichSpecialist} from "../../components/WhichSpecialist/WhichSpecialist";
import {MainContacts} from "../../components/MainContacts/MainContacts";
import {Footer} from "../../components/Footer/Footer";
import {changeVisParallax, visParallax} from "../../reducers/dataReducer";


import "animate.css/animate.compat.css"
import {useDispatch, useSelector} from "react-redux";
import {Intro} from "../../components/Intro/Intro";


export function MainPage() {
    let par_vis = useSelector(visParallax);
    const dispatch = useDispatch();

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    let view

    function Parralax(){
        if (par_vis){
            view = true
            return <Parallax/>;

        }else {
            view = false;
            return null;
        }
    }

    const handleScroll = () => {
        if (window.scrollY > 1000) {
            dispatch(changeVisParallax());
        }
    };




    return (
        <div className="main-page">
            <Intro/>
            <Parralax/>
            <Sechead player={view}/>
            <div className={"container-fluid"}>
                <WhichSpecialist/>
                <Directions/>
                <Specialists/>
                <MainPrice/>
                <BlogBlock/>
                <VrBlock/>
                <News/>
                <Appointment/>
                <MainContacts/>
                <Footer/>
            </div>
        </div>
    );
}