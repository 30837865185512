import "./BlogBlock.sass";
import ReactPlayer from 'react-player/youtube';
import {ArticlesMain} from "./ArticlesMain";
import {Link} from "react-router-dom";
import React from "react";
import {Element} from "react-scroll";
import "animate.css/animate.compat.css";
import ScrollAnimation from 'sgk-react-animation-scroll';

export function BlogBlock() {
    return (
        <div className={"main-page-blog"} id={"blog"}>
            <div className={"blog-block"}>
                <div className={"left-side"}>
                    <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true} offset={100}>
                    <h2>Наши видео</h2>
                    <ReactPlayer url='https://www.youtube.com/watch?v=7NOSDKb0HlU' controlst="0" width={641}
                                 height={362} light={true} className={"player"}/>
                    <div className={"video-link"}><Link to="video">Смотреть ещё</Link>
                        <div className={"arrow"}>
                            <svg width="24" height="8" viewBox="0 0 24 8" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5V3.5ZM23.3536 4.35355C23.5488 4.15829 23.5488 3.84171 23.3536 3.64645L20.1716 0.464466C19.9763 0.269204 19.6597 0.269204 19.4645 0.464466C19.2692 0.659728 19.2692 0.976311 19.4645 1.17157L22.2929 4L19.4645 6.82843C19.2692 7.02369 19.2692 7.34027 19.4645 7.53553C19.6597 7.7308 19.9763 7.7308 20.1716 7.53553L23.3536 4.35355ZM1 4.5L23 4.5V3.5L1 3.5V4.5Z"
                                    fill="#D84313"/>
                            </svg>
                        </div>
                    </div>
                    </ScrollAnimation>
                </div>
                <div className={"right-side"}>
                    <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true} offset={100}>
                    <h2>Статьи</h2>
                    <ArticlesMain/>
                    </ScrollAnimation>
                </div>
            </div>
        </div>
    );
}