import './Contacts.sass';

export function Contacts() {
    return(
      <div className="contacts-page">
          <div className="intro">
              <h1>Контакты</h1>
              <p>Наша клиника эт прям всем клиникам клиника</p>
          </div>
          <div className="address">
              <h2>Мы находимся:</h2>
              <p>Проспект Медицинский, Дом 14</p>
          </div>
      </div>
    );
}