import {doctorsList} from "../../reducers/dataReducer";
import {Link, useParams} from 'react-router-dom';
import {useSelector} from "react-redux";
import {Sechead} from "../../components/Sechead/Sechead";
import React, {useState} from "react";
import "./DoctorPage.sass";
import parse from 'html-react-parser';
import {TimeLine} from "./TimeLine";
import {MainContacts} from "../../components/MainContacts/MainContacts";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import {Footer} from "../../components/Footer/Footer";
import {Helmet} from "react-helmet";


export function DoctorPage() {
    let doctors = useSelector(doctorsList);
    const {fio} = useParams();
    let fix_fio = fio.split("_");

    let currentDoctor;
    let metodi;
    let documents;
    let specs;

    const [openDocuments, setOpen] = useState(false);

    for (let doc of doctors) {
        if (doc.surname === fix_fio[0] && doc.name === fix_fio[1] && doc.patronymics === fix_fio[2]) {
            currentDoctor = doc;
            metodi = JSON.parse(currentDoctor.metodi);
            documents = JSON.parse(currentDoctor.documents);
            specs = JSON.parse(currentDoctor.fullspeciality);
        }
    }

    let education_timeline = [];
    let work_timeline = [];
    if (currentDoctor) {
        let edulist = JSON.parse(currentDoctor.education);
        let worklist = JSON.parse(currentDoctor.working);

        while (edulist.length > 0) {
            let min_edu = edulist[0];
            let j = 0;
            if (edulist.length > 1) {
                for (let i = 0; i < edulist.length; i++) {
                    if (parseInt(edulist[i].year) < min_edu.year) {
                        min_edu = edulist[i];
                        j = i;
                    }
                }
                education_timeline.push(min_edu);
                edulist.splice(j, 1);
            } else {
                education_timeline.push(min_edu);
                edulist.splice(0, 1);
            }
        }

        while (worklist.length > 0) {
            let min_working = worklist[0];
            let j = 0;
            if (worklist.length > 1) {
                for (let i = 0; i < worklist.length; i++) {
                    if (parseInt(worklist[i].year) < min_working.year) {
                        min_working = worklist[i];
                        j = i;
                    }
                }
                work_timeline.push(min_working);
                worklist.splice(j, 1);
            } else {
                work_timeline.push(min_working);
                worklist.splice(0, 1);
            }
        }
    }



    let keywords = "Возрождение жизни, медицинский центр, психология, клиника, " + fix_fio.toString().replaceAll(",", " ") + ", " + specs.toString().replaceAll(",", "*").replaceAll("*", ", ");

    return (
        <div className={"doctor-main"}>
            <Helmet>
                <meta charSet="utf-8"/>
                <meta name="keywords" content={keywords}/>
                <meta name="description"
                      content={fix_fio.toString().replaceAll(",", " ") + ", " + specs.toString().replaceAll(",", "*").replaceAll("*", ", ")}/>
                <title>Возрождение жизни - Специалисты
                    - {currentDoctor.surname + " " + currentDoctor.name + " " + currentDoctor.patronymics} - {currentDoctor.specialty}</title>
            </Helmet>
            <Sechead/>
            <div className={"doctor-page"}>
                <div className={"bread"}>
                    <ul>
                        <li><Link to="/">Главная</Link></li>
                        <li><Link to={"/specs"}>Специалисты</Link></li>
                        <li>{currentDoctor.surname} {currentDoctor.name} {currentDoctor.patronymics}</li>
                    </ul>
                </div>
                <div className={"left-column"}>
                    <div className={"doc-img"}>
                        <img src={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/" + currentDoctor.photo} alt={currentDoctor.specialty}/>
                        <div className={"button"}>
                            <Link to={"#"}>Записаться на консультацию</Link>
                        </div>
                    </div>
                    <div className={"documents-header"}>Документы об образовании</div>
                    <div className={openDocuments === true ? "documents open" : "documents"}>
                        {documents.map((document, index) => {
                            return (
                                <div className={"documents-item"} key={index}>
                                    <Zoom>
                                        <picture>
                                            <source media="(max-width: 800px)"
                                                    srcSet={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/" + document}/>
                                            <img
                                                alt="документ"
                                                src={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/" + document}
                                                width="100"
                                                height="150"
                                            />
                                        </picture>
                                    </Zoom>
                                </div>
                            )
                        })}
                    </div>
                    {documents.length > 3 ?
                        <div className={"more-documents"} onClick={() => {
                            setOpen(!openDocuments);
                        }}>
                            {openDocuments === true ? "Скрыть" : "Еще документы"}
                        </div> :
                        ""}

                </div>
                <div className={"right-column"}>
                    <div className={"titul"}>
                        <h2>{currentDoctor.surname} {currentDoctor.name} {currentDoctor.patronymics}</h2>
                        <div className={"stag"}>Опыт работы - {currentDoctor.stag}</div>
                        <div className={"fullspeciality"}>
                            {specs.map((spec, index) => {
                                return (
                                    <div className={"spec-item"} key={index}>
                                        {spec}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className={"about-yourself"}>
                        <h3>О себе</h3>
                        {parse(currentDoctor.about)}
                    </div>
                    <div className={"how-i-work"}>
                        <h3>Как я работаю?</h3>
                        {parse(currentDoctor.howiwork)}
                    </div>
                    <div className={"timelines"}>
                        <TimeLine line={JSON.stringify(education_timeline)} header={"Образование"}/>
                        <TimeLine line={JSON.stringify(work_timeline)} header={"Опыт Работы"}/>
                    </div>
                    <div className={"metodi"}>
                        <h3>Владение методиками</h3>
                        <div className={"metodi-block"}>
                            {metodi.map((item, index) => {
                                return (
                                    <div className={"metod"} key={index}>
                                        {item}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className={"link-to-home"}>
                    <div className={"arrow"}>
                        <svg width="24" height="8" viewBox="0 0 24 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M23 3.5C23.2761 3.5 23.5 3.72386 23.5 4C23.5 4.27614 23.2761 4.5 23 4.5V3.5ZM0.646446 4.35355C0.451185 4.15829 0.451185 3.84171 0.646446 3.64645L3.82843 0.464466C4.02369 0.269204 4.34027 0.269204 4.53553 0.464466C4.7308 0.659728 4.7308 0.976311 4.53553 1.17157L1.70711 4L4.53553 6.82843C4.7308 7.02369 4.7308 7.34027 4.53553 7.53553C4.34027 7.7308 4.02369 7.7308 3.82843 7.53553L0.646446 4.35355ZM23 4.5L1 4.5V3.5L23 3.5V4.5Z"
                                fill="#D84313"/>
                        </svg>
                    </div>
                    <Link to="/">На главную</Link>
                </div>
            </div>
            <MainContacts/>
            <Footer/>
        </div>
    );
}