import {useDispatch, useSelector} from "react-redux";
import {deleteReview, reviewsList} from "../../../../reducers/dataReducer";
import {useEffect, useState} from "react";
import {RiDeleteBin2Line} from "react-icons/ri";
import "./FeedbackModerate.sass"
import {EditReview} from "./EditReview";

export function FeedbackModerate() {
    const dispatch = useDispatch();

    let reviews = useSelector(reviewsList);

    const [deletedReview, setDel] = useState(0);

    //токен
    const [token, setToken] = useState("");
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            setToken(token);
        }
    }, []);
    //__/токен

    const DeleteReview = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: "DELETE",
            headers: {
                "accept": "application/json",
                Authorization: "Bearer " + token,
            },

        };
        const response = await fetch(`https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/reviews/delete?news_id=${deletedReview}`, requestOptions);
        const data = await response.json();
        if (!response.ok) {
            console.log("Хуйня");
        } else {
            dispatch(deleteReview(deletedReview));
            alert(data);
        }
    };


    return (
        <div className={"reviews-all"}>
            <h2>Отзывы</h2>
            <div className={"review-items"}>
                <table className={"review-items-table"} border={1}>
                    <caption>Таблица отзывов</caption>
                    <thead>
                    <tr>
                        <th>Пользователь</th>
                        <th>Текст</th>
                        <th>Рейтинг</th>
                        <th>Дата</th>
                        <th>Опубликован</th>
                        <th>Ответ</th>
                        <th>Удалить/<br/>Изменить</th>
                    </tr>
                    </thead>
                    <tbody>
                    {reviews.map((review) => {
                        return (
                            <tr key={review.id}>
                                <td>
                                    {review.username}
                                </td>
                                <td>
                                    {review.feedback_text}
                                </td>
                                <td>
                                    {review.rating}
                                </td>
                                <td>
                                    {review.date}
                                </td>
                                <td>
                                    {review.is_published ? "да" : "нет"}
                                </td>
                                <td>
                                    {review.answer}
                                </td>
                                <td>
                                    <button onClick={DeleteReview}><RiDeleteBin2Line/></button>
                                    <EditReview review={review}/>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}