import Modal from "react-modal";
import validator from "validator";
import React, {useEffect, useState} from "react";
import {addArticle} from "../../../../reducers/dataReducer";
import {useDispatch, useSelector} from "react-redux";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build';
import {categoriesList} from "../../../../reducers/dataReducer";
import {AddCategory} from "./AddCategory";
import moment from "moment";
import "./AddArticleForm.sass"


export function AddArticleForm() {
    let categories = useSelector(categoriesList);
    let date = moment().format();


    const dispatch = useDispatch();

    const [showModal, setModal] = useState(false);
    const [articleHeader, setHeader] = useState("");
    const [articleDate, setDate] = useState(date);
    const [articleDesc, setDesc] = useState("");
    const [articleImage, setImage] = useState("");
    const [articleText, setText] = useState("");
    const [articleAuthor, setAuthor] = useState("");
    const [parentCategory, setCategory] = useState("");

    const [articleFile, setFile] = useState();


    useEffect(() => {
        setDate(date);
    }, [parentCategory, date]);

    //токен
    const [token, setToken] = useState("");
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            setToken(token);
        }
    }, []);
    //__/токен
    let AddArticle = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: "POST",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                "article_id": 0,
                "header": articleHeader,
                "date": articleDate,
                "description": articleDesc,
                "image": articleImage,
                "image_big": articleImage,
                "text": articleText,
                "author": articleAuthor,
                "owner_id": parentCategory
            }),
        };
        const response = await fetch("https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/articles/add", requestOptions);
        const data = await response.json();
        if (!response.ok) {
            console.log("Хуйня");
        } else {
            dispatch(addArticle(data));
            setHeader("");
            setDesc("");
            setImage("");
            setText("");
            setAuthor("");
            setCategory("");
        }
        setModal(false);
    };

    let UploadImage = async (e) => {
        e.preventDefault()
        const image = new FormData();
        image.append('uploaded_file', articleFile);

        const requestOptions = {
            method: "POST",
            headers: {
                "accept": "application/json",
                Authorization: "Bearer " + token,
            },
            "Content-Type": "multipart/form-data",
            body: image,
        };
        const response = await fetch("https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload-file/", requestOptions);


        const data = await response.json();
        if (!response.ok) {
            console.log(response);
        } else {
            setImage(articleFile.name);
            setValid({
                ...inputsValid,
                image: validator.isLength(articleFile.name, {
                    min: 5,
                    max: 40,
                }),
            });
            console.log(data);
        }
    }

    const [inputsValid, setValid] = useState({
        header: validator.isLength(articleHeader, {min: 5, max: 140}),
        date: validator.isLength(articleDate, {min: 5, max: 40}),
        description: validator.isLength(articleDesc, {min: 5, max: 400}),
        image: validator.isLength(articleImage, {min: 5, max: 40}),
        text: validator.isLength(articleText, {min: 5, max: 20000}),
        author: validator.isLength(articleAuthor, {min: 5, max: 40}),
        owner_id: validator.isInt(parentCategory,),
    });

    const formValid = (values) => {
        return !(values.header === true &&
            values.date === true &&
            values.description === true &&
            values.image === true &&
            values.text === true &&
            values.author === true &&
            values.owner_id === true);
    };



    return (
        <div className={"add-article-form"}>
            <div className={"add-article"}>
                <button onClick={() => setModal(true)} className={"add-article-button"}>Добавить</button>
            </div>
            <Modal isOpen={showModal} contentLabel="Форма: Добавить статью">
                <h2>Добавить статью</h2>
                <form>
                    <label>
                        <span>Заголовок</span>
                        <input
                            name="article-header"
                            type="text"
                            value={articleHeader}
                            onChange={(event) => setHeader(event.target.value)}
                            onInput={(event) =>
                                setValid({
                                    ...inputsValid,
                                    header: validator.isLength(event.target.value, {
                                        min: 5,
                                        max: 140,
                                    }),
                                })
                            }
                        />
                        <span className="warning">
                              {inputsValid.header === true
                                  ? ""
                                  : "Введите заголовок!"}
                            </span>
                    </label>
                    <br/>
                    <label>
                        <span>Краткое содержание</span>
                        <input
                            name="article-description"
                            type="text"
                            value={articleDesc}
                            onChange={(event) => setDesc(event.target.value)}
                            onInput={(event) =>
                                setValid({
                                    ...inputsValid,
                                    description: validator.isLength(event.target.value, {
                                        min: 5,
                                        max: 400,
                                    }),
                                })
                            }
                        />
                        <span className="warning">
                              {inputsValid.description === true
                                  ? ""
                                  : "Введите краткий текст!"}
                            </span>
                    </label>
                    <br/>
                    <label>
                        <span>Главное фото</span>
                        <input
                            name="article-image"
                            type="text"
                            value={articleImage}
                            onChange={(event) => setImage(event.target.value)}
                            onInput={(event) =>
                                setValid({
                                    ...inputsValid,
                                    image: validator.isLength(event.target.value, {
                                        min: 5,
                                        max: 40,
                                    }),
                                })
                            }
                        />
                        <span className="warning">
                              {inputsValid.image === true
                                  ? ""
                                  : "фото!"}
                            </span>
                    </label>
                    <label>
                        <input
                            name="article-image-file"
                            type="file"
                            onChange={(event) => setFile(event.target.files[0])}
                        />
                        <button onClick={UploadImage}> загрузить</button>
                    </label>
                    <br/>
                    <label>
                        <span>Текст Статьи</span>
                        <CKEditor
                            editor={Editor}
                            data={articleText}
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setText(data);
                                setValid({
                                    ...inputsValid,
                                    text: validator.isLength(articleText, {
                                        min: 5,
                                        max: 20000,
                                    }),
                                });
                            }}

                        />

                        <span className="warning">
                              {inputsValid.text === true
                                  ? ""
                                  : "Введите Текст!"}
                            </span>
                    </label>
                    <br/>
                    <label>
                        <span>Автор</span>
                        <input
                            name="article-author"
                            type="text"
                            value={articleAuthor}
                            onChange={(event) => setAuthor(event.target.value)}
                            onInput={(event) =>
                                setValid({
                                    ...inputsValid,
                                    author: validator.isLength(event.target.value, {
                                        min: 5,
                                        max: 40,
                                    }),
                                })
                            }
                        />
                        <span className="warning">
                              {inputsValid.author === true
                                  ? ""
                                  : "Введите автора!"}
                            </span>
                    </label>
                    <br/>
                    <label>
                        <span>Категория</span>
                        <select onChange={(event) => setCategory(event.target.value)}
                                onInput={(event) =>
                                    setValid({
                                        ...inputsValid,
                                        owner_id: validator.isInt(event.target.value,),
                                        date: validator.isLength(articleDate, {
                                            min: 5,
                                            max: 40,
                                        }),
                                    })
                                }>
                            <option value={""}>Выберите Категорию</option>
                            {categories.map((category) => {
                                return (
                                    <option key={category.id} value={category.id}>{category.name}</option>
                                )
                            })}
                        </select>
                    </label>
                </form>
                <AddCategory/>
                <button
                    onClick={AddArticle}
                    className="AddButton"
                    disabled={formValid(inputsValid)}
                >
                    Добавить
                </button>
                <button onClick={() => setModal(false)} className="closeButton">
                    Закрыть
                </button>
            </Modal>
        </div>
    );

}