import "./Sechead.sass"
import React, {useState} from "react";
import Logo from "../../images/logo3.png"
import {Link, useNavigate} from "react-router-dom";
import arrow from "../../images/sec-menu-arrow.png";
import {AudioTrack} from "../AudioPlayer/AudioTrack";
import {HashLink} from "react-router-hash-link";
import {Link as Link1} from 'react-scroll';
import {ServisesMenu} from "./ServisesMenu";
import {HeaderSearch} from "./HeaderSearch";
import {IoCloseOutline, IoMenu} from "react-icons/io5";


export function Sechead(props) {
    const [menuStatus, setMenustatus] = useState(false);
    const availableScreenWidth = window.screen.availWidth;

    let navigate = useNavigate();
    function nav(path){
        navigate(path);
    }

    let player = props.player;

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -150;
        window.scrollTo({top: yCoordinate + yOffset, behavior: 'smooth'});
    }

    return (
        <div className={"second-header"} id={"secondheader"}>
            <div className={"container"}>
                <div className={"second-header-logo"}>
                    <HashLink to="/#secondheader" smooth><img src={Logo} alt={"Лого"}/></HashLink>
                </div>
                <HeaderSearch/>
                <div className={"second-header-phone"}>
                    <Link to={"tel:+73912303030"}>+7 (391) 230 30 30</Link>
                </div>
                <div className={"second-header-lk"}>
                    <div className={"lk-label"}>личный кабинет</div>
                </div>
                <div className={"online-record"}>
                    <div className={"online-record-label"}>он-лайн запись</div>
                </div>
                <div className={"second-header-eye"}>
                    <svg width="23" height="9" viewBox="0 0 23 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M0.958333 4.5C0.42906 4.5 0 4.09706 0 3.6C0 3.10294 0.42906 2.7 0.958333 2.7H1.35704C2.09645 1.11069 3.78511 0 5.75 0C7.49839 0 9.02806 0.879407 9.86487 2.19292C10.3352 1.94223 10.9025 1.8 11.5 1.8C12.0975 1.8 12.6648 1.94223 13.1351 2.19292C13.9719 0.879407 15.5016 0 17.25 0C19.2149 0 20.9035 1.11069 21.643 2.7H22.0417C22.5709 2.7 23 3.10294 23 3.6C23 4.09706 22.5709 4.5 22.0417 4.5C22.0417 6.98528 19.8964 9 17.25 9C14.6036 9 12.4583 6.98528 12.4583 4.5C12.4583 4.34812 12.4663 4.19801 12.482 4.05H12.4583C12.4583 3.86692 12.0794 3.6 11.5 3.6C10.9206 3.6 10.5417 3.86692 10.5417 4.05H10.518C10.5337 4.19801 10.5417 4.34812 10.5417 4.5C10.5417 6.98528 8.39636 9 5.75 9C3.10364 9 0.958333 6.98528 0.958333 4.5ZM5.75 7.2C7.33782 7.2 8.625 5.99117 8.625 4.5C8.625 3.00883 7.33782 1.8 5.75 1.8C4.16218 1.8 2.875 3.00883 2.875 4.5C2.875 5.99117 4.16218 7.2 5.75 7.2ZM17.25 7.2C18.8378 7.2 20.125 5.99117 20.125 4.5C20.125 3.00883 18.8378 1.8 17.25 1.8C15.6622 1.8 14.375 3.00883 14.375 4.5C14.375 5.99117 15.6622 7.2 17.25 7.2Z"
                              fill="white"/>
                    </svg>
                </div>
                <div className={"second-header-questions"}>
                    <HashLink to="/#main-page-appointment" smooth scroll={scrollWithOffset}>
                    <svg height="31.925" viewBox="0 0 31.925 31.925" width="31.925" fill="white" xmlns="http://www.w3.org/2000/svg">
                        <g transform="scale(0.8) translate(-668.321 -508.099) ">
                            <path
                                d="M689.284,516.1a13.962,13.962,0,1,1-13.963,13.962A13.977,13.977,0,0,1,689.284,516.1m0-2a15.962,15.962,0,1,0,15.962,15.962A15.962,15.962,0,0,0,689.284,514.1Z"/>
                            <path
                                d="M687.65,531.612a2.374,2.374,0,0,1,.49-1.433,9.248,9.248,0,0,1,1.443-1.483,10.084,10.084,0,0,0,1.321-1.371,1.993,1.993,0,0,0,.367-1.2,1.953,1.953,0,0,0-1.982-2,2.073,2.073,0,0,0-1.419.543,3.56,3.56,0,0,0-.954,1.582l-2.152-.939a5.027,5.027,0,0,1,1.724-2.657,4.632,4.632,0,0,1,2.9-.926,4.959,4.959,0,0,1,2.287.531,4.154,4.154,0,0,1,1.651,1.5,3.979,3.979,0,0,1,.611,2.175,3.681,3.681,0,0,1-.538,1.965,8.76,8.76,0,0,1-1.638,1.865,13.792,13.792,0,0,0-1.359,1.322,1.536,1.536,0,0,0-.379,1,2.868,2.868,0,0,0,.1.667h-2.2A2.74,2.74,0,0,1,687.65,531.612Zm1.468,6.969a1.855,1.855,0,0,1-1.357-.543,1.831,1.831,0,0,1-.551-1.359,1.875,1.875,0,0,1,.551-1.372,1.835,1.835,0,0,1,1.357-.556,1.868,1.868,0,0,1,1.908,1.928,1.833,1.833,0,0,1-.549,1.359A1.863,1.863,0,0,1,689.118,538.581Z"/>
                        </g>
                    </svg>
                    </HashLink>
                </div>
                <AudioTrack player={player}/>
            </div>
            <div className={"menu-second"}>
                <div className={"menu-close-button"} onClick={() => setMenustatus(!menuStatus)}>
                    {menuStatus ? <IoCloseOutline />: <IoMenu />}
                </div>
                <div className={availableScreenWidth < 1024 ? (!menuStatus ? "menu-container-mobile collapsed" : "menu-container-mobile expanded") : "menu-container"}>
                    <div className={"menu-second-element-blog"}>
                        <HashLink to="/#about-centre" smooth scroll={scrollWithOffset}>О центре<img src={arrow}
                                                                                                    alt={"Стрелка"}/></HashLink>
                        <div className={"sub-menu"}>
                            <ul>
                                <li><Link to={"/article/О_центре"}>О центре</Link></li>
                                <li><Link to={"/article/Преимущества"}>Преимущества</Link></li>
                                <li><Link to={"/feedback"}>Отзывы</Link></li>
                                <li><Link to={"/gallery"}>Галерея</Link></li>
                                <li><Link to={"/licenses"}>Лицензии</Link></li>
                                <li><Link to={"/category/Направления_работы"}>Направления работы</Link></li>
                            </ul>
                        </div>
                    </div>
                    <Link to="/specs" >
                    <div className={"menu-second-element"}>
                            Специалисты
                        </div>
                    </Link>
                    <div className={"menu-second-element-services"}>
                        Услуги<img src={arrow} alt={"Стрелка"}/>
                        <ServisesMenu/>
                    </div>
                    <HashLink to="/#price" smooth scroll={scrollWithOffset}>
                        <div className={"menu-second-element"}>
                        Цены
                        </div>
                    </HashLink>
                    <div className={"menu-second-element-blog"}>
                        <HashLink to="/#blog" smooth scroll={scrollWithOffset}>Блог<img src={arrow} alt={"Стрелка"}/></HashLink>
                        <div className={"sub-menu"}>
                            <ul>
                                <li><Link to={"/video"}>Видео</Link></li>
                                <li><Link to={"/articles"}>Статьи</Link></li>
                            </ul>
                        </div>
                    </div>
                    <Link1 to="vr-tech" offset={-150} smooth={true}>
                        <div className={"menu-second-element"}>
                            VR технологии
                        </div>
                    </Link1>
                    <Link to="/news" >
                        <div className={"menu-second-element"}>
                            Новости
                        </div>
                    </Link>
                    <Link1 to="contacts" offset={-150} smooth={true}>
                        <div className={"menu-second-element"}>
                            Контакты
                        </div>
                    </Link1>

                        <div className={"menu-second-element-blog"}>
                            <HashLink>Еще<img src={arrow} alt={"Стрелка"}/></HashLink>
                            <div className={"sub-menu"}>
                                <ul>
                                    <li><Link to={"/feedback"}>Отзывы</Link></li>
                                    <li><Link to={"/documents"}>Документы</Link></li>
                                    <li><Link to={"/regulatory"}>Контролирующие органы</Link></li>
                                    <li>Вакансии</li>
                                </ul>
                            </div>
                        </div>

                </div>
            </div>
        </div>
    );
}