import {Link, useParams} from 'react-router-dom';
import {articlesList, categoriesList} from "../../reducers/dataReducer";
import {useSelector} from "react-redux";
import {Sechead} from "../../components/Sechead/Sechead";
import React, {useState} from "react";
import "./Article.sass"
import parse from "html-react-parser";
import {MainContacts} from "../../components/MainContacts/MainContacts";
import {Footer} from "../../components/Footer/Footer";
import {ReadMore} from "./ReadMore";
import {Helmet} from "react-helmet";


export function Article() {
    let categories = useSelector(categoriesList);
    let articles = useSelector(articlesList);
    const {article_name} = useParams();
    console.log(article_name);
    let fix_name = article_name.replaceAll("_", ' ').replaceAll("q", "?");
    console.log(fix_name);
    let currentItem;
    let currentCategory;

    for (let category of categories) {
        for (let article of category.articles) {
            if (article.header === fix_name) {
                currentItem = article;
                currentCategory = category;
            }
        }
    }
    console.log(currentItem, currentCategory)

    const [activeCategory, setCategory] = useState(currentCategory);

    let keywords = "Возрождение жизни, медицинский центр, психология, клиника, статьи по психологии";

    keywords = keywords + ", " + currentItem.header + ", " + currentCategory.name


    return (
        <div className={"article-main"}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Возрождение жизни - {activeCategory.name} - {currentItem.header}</title>
                <meta name="keywords" content={keywords}/>
                <meta name="description" content={currentItem.description}/>
            </Helmet>
            <Sechead/>
            <div className={"article-page"}>
                <div className={"bread"}>
                    <ul>
                        <li><Link to="/">Главная</Link></li>
                        {currentCategory.id !== 6 ?
                            <li><Link
                                to={"/category/" + activeCategory.name.replaceAll(" ", "_")}>{activeCategory.name}</Link>
                            </li>
                            :
                            null}
                        <li>{currentItem.header}</li>
                    </ul>
                </div>
                <div className={"articles-categories"}>
                    {categories.map((category) => {
                        if (category.id !== 6) {
                            return (
                                <div className={category.id === activeCategory.id ? "category active" : "category"}
                                     key={category.id}>
                                    <p><Link
                                        to={"/category/" + category.name.replaceAll(" ", "_")}>{category.name}</Link>
                                    </p>
                                </div>
                            )
                        } else {
                            return null
                        }

                    })}
                </div>
                <div className={"article-page-main"}>
                    <h1>{currentItem.header}</h1>
                    <div className={"article-page-top"}>
                        <div className={"article-page-top-image"}>
                            <img src={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/" + currentItem.image_big}/>
                        </div>
                        <div className={"article-main-text"}>
                            {parse(currentItem.text)}
                        </div>
                        {currentItem.article_id === 9 ?
                            <div className={"buttons"}>
                                <div className={"button"}><Link to={"/gallery"}>
                                    Галерея
                                </Link></div>
                                <div className={"button"}><Link to={"/articles"}>
                                    Статьи
                                </Link></div>
                            </div>
                            : ""}
                    </div>
                    <div className={"reading-more"}>
                        <h3>Читайте также:</h3>
                    </div>
                    <ReadMore category={currentCategory} article={currentItem}/>
                </div>
            </div>
            <MainContacts/>
            <Footer/>
        </div>
    );
}