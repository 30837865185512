import validator from "validator";
import React, {useEffect, useState} from "react";
import {addCategory} from "../../../../reducers/dataReducer";
import {useDispatch} from "react-redux";
import "./AddCategory.sass"

export function AddCategory() {
    const dispatch = useDispatch();
    const [token, setToken] = useState("");
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            setToken(token);
        }
    }, []);

    const [formVis, setVis] = useState(false)

    const [categoryName, setName] = useState("");
    const [categoryDesc, setDesc] = useState("");
    const [inputsValid, setValid] = useState({
        name: validator.isLength(categoryName, {min: 5, max: 40}),
        description: validator.isLength(categoryDesc, {min: 20, max: 140}),
    });

    const formValid = (values) => {
        return !(values.name === true &&
            values.description === true);
    };

    let AddNewCategory = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: "POST",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                "id": 0,
                "name": categoryName,
                "description": categoryDesc,
                "articles": [],
            }),
        };
        const response = await fetch("https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/categories/add", requestOptions);
        const data = await response.json();
        if (!response.ok) {
            console.log("Хуйня");
        } else {
            dispatch(addCategory(data));
        }
        setVis(false);
        setName("");
        setDesc("");

    };
    return (
        <div className={"add-category"}>
            <button onClick={() => setVis(true)}>+ Новая категория</button>
            <div className={!formVis ? "add-category-form collapsed" : "add-category-form expanded"}>
                <form>
                    <label>
                        <span>Название Категории</span>
                        <input
                            name="category-name"
                            type="text"
                            value={categoryName}
                            onChange={(event) => setName(event.target.value)}
                            onInput={(event) =>
                                setValid({
                                    ...inputsValid,
                                    name: validator.isLength(event.target.value, {
                                        min: 5,
                                        max: 40,
                                    }),
                                })
                            }
                        />
                        <span className="warning">
                              {inputsValid.name === true
                                  ? ""
                                  : "Введите Название!"}
                            </span>
                    </label>
                    <br/>
                    <label>
                        <span>Описание</span>
                        <input
                            name="category-desc"
                            type="text"
                            value={categoryDesc}
                            onChange={(event) => setDesc(event.target.value)}
                            onInput={(event) =>
                                setValid({
                                    ...inputsValid,
                                    description: validator.isLength(event.target.value, {
                                        min: 20,
                                        max: 140,
                                    }),
                                })
                            }
                        />
                        <span className="warning">
                              {inputsValid.description === true
                                  ? ""
                                  : "Введите Текст!"}
                            </span>
                    </label>
                </form>
                <button
                    onClick={AddNewCategory}
                    className="AddButton"
                    disabled={formValid(inputsValid)}
                >
                    Добавить
                </button>
                <button onClick={() => setVis(false)} className="closeButton">
                    Отмена
                </button>
            </div>
        </div>
    );
}