import {useDispatch, useSelector} from "react-redux";
import {addPriceItem, priceList} from "../../../../reducers/dataReducer";
import validator from "validator";
import Modal from "react-modal";
import React, {useEffect, useState} from "react";

export function AddPriceItem(){
    let sections = useSelector(priceList);
    const dispatch = useDispatch();

    const [showModal, setModal] = useState(false);

    const [itemName, setItemName] = useState("");
    const [itemPrice, setItemPrice] = useState("");
    const [itemSection, setSection] = useState("");

    //токен
    const [token, setToken] = useState("");
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            setToken(token);
        }
    }, []);
    //__/токен

    let AddItem = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: "POST",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                "id": 0,
                "name": itemName,
                "price": itemPrice,
                "section": itemSection,
            }),
        };
        const response = await fetch("https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/price/add_item", requestOptions);
        const data = await response.json();
        if (!response.ok) {
            console.log("Хуйня");
        } else {
            dispatch(addPriceItem(data));
        }
        setModal(false);
    };
    const [inputsValid, setValid] = useState({
        name: validator.isLength(itemName, {min: 5, max: 120}),
        price: validator.isLength(itemPrice, {min: 5, max: 20}),
        section: validator.isInt(itemSection),
    });

    const formValid = (values) => {
        return !(values.name === true &&
            values.price === true &&
            values.section === true);
    };

    return(
        <div className={"add-price-item-form"}>
            <div className={"add-price-item"}>
                <button onClick={() => setModal(true)} className={"add-price-item-button"}>Добавить</button>
            </div>
            <Modal isOpen={showModal} contentLabel="Форма: Добавить позицию в прайс">
                <h2>Добавить позицию в прайс</h2>
                <form>
                    <label>
                        <span>Название</span>
                        <input
                            name="price-item-name"
                            type="text"
                            value={itemName}
                            onChange={(event) => setItemName(event.target.value)}
                            onInput={(event) =>
                                setValid({
                                    ...inputsValid,
                                    name: validator.isLength(event.target.value, {
                                        min: 5,
                                        max: 120,
                                    }),
                                })
                            }
                        />
                        <span className="warning">
                              {inputsValid.name === true
                                  ? ""
                                  : "Введите название!"}
                            </span>
                    </label>
                    <br/>
                    <label>
                        <span>Цена</span>
                        <input
                            name="price-item-price"
                            type="text"
                            value={itemPrice}
                            onChange={(event) => setItemPrice(event.target.value)}
                            onInput={(event) =>
                                setValid({
                                    ...inputsValid,
                                    price: validator.isLength(event.target.value, {
                                        min: 5,
                                        max: 20,
                                    }),
                                })
                            }
                        />
                        <span className="warning">
                              {inputsValid.price === true
                                  ? ""
                                  : "Введите цену!"}
                            </span>
                    </label>
                    <br/>
                    <label>
                        <span>Секция прайслиста</span>
                        <select onChange={(event) => setSection(event.target.value)}
                                onInput={(event) =>
                                setValid({
                                    ...inputsValid,
                                    section: validator.isInt(event.target.value),
                                })
                            }>
                            <option>-----</option>
                            {sections.map((section) => {
                                return (
                                    <option key={section.id} value={section.id}>{section.name}</option>
                                )
                            })}
                        </select>
                    </label>
                </form>
                <button
                    onClick={AddItem}
                    className="AddButton"
                    disabled={formValid(inputsValid)}
                >
                    Добавить
                </button>
                <button onClick={() => setModal(false)} className="closeButton">
                    Закрыть
                </button>
            </Modal>
        </div>
    );
}