import "./Articles.sass";
import {categoriesList} from "../../reducers/dataReducer";
import {Sechead} from "../../components/Sechead/Sechead";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {MainContacts} from "../../components/MainContacts/MainContacts";
import {Footer} from "../../components/Footer/Footer";
import {Helmet} from "react-helmet";

export function Articles() {
    let categories = useSelector(categoriesList);
    let temp = categories;

    const main_article_id = 48;
    let currentItem;
    let currentCategory;

    for (let category of categories) {
        for (let article of category.articles) {
            if (article.article_id === main_article_id) {
                currentItem = article;
                currentCategory = category;
            }
        }
    }
    const [activeCategory, setCategory] = useState(currentCategory);
    const [categoriesListVis, setListVis] = useState(false);
    const [tempCat, setTemp] = useState(categories);

    useEffect(() => {
        window.scrollTo(0, 0);
        setTemp(categories);
    }, [activeCategory, temp]);

    return (
        <div className={"articles-all-main"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Возрождение жизни - Статьи</title>
            </Helmet>
            <Sechead/>
            <div className={"articles-all-page"}>
                <div className={"bread"}>
                    <ul>
                        <li><Link to="/">Главная</Link></li>
                        <li>Статьи</li>
                    </ul>
                </div>
                <div className={"articles-all-page-categories"}>
                    {categories.map((category) => {
                        if (category.id !== 6) {
                            return (
                                <div className={category.id === activeCategory.id ? "category active" : "category"}
                                     key={category.id} onClick={() => {
                                    setCategory(category);
                                }}>
                                    <p><Link to={"/category/" + category.name.replaceAll(" ", '_')}>{category.name}</Link></p>
                                </div>
                            )
                        } else
                            return null
                    })}
                </div>
                <div className={"main-article"}>
                    <h1>{currentItem.header}</h1>
                    <div className={"main-article-img"}>
                        <img src={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/" + currentItem.image_big}/>
                    </div>
                    <div className={"main-article-desc"}>
                        <p>{currentItem.description}</p>
                        <div className={"main-article-link"}>
                            <Link to={"/article/" + currentItem.header.replaceAll(" ", '_').replaceAll("?", "q")}>читать</Link>
                        </div>
                    </div>
                </div>
                <div className={"main-article-category-link"}>
                    <Link to={"/category/" + currentCategory.name}>Перейти в раздел "{currentCategory.name}"
                        <div className={"arrow"}>
                            <svg width="24" height="8" viewBox="0 0 24 8" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5V3.5ZM23.3536 4.35355C23.5488 4.15829 23.5488 3.84171 23.3536 3.64645L20.1716 0.464466C19.9763 0.269204 19.6597 0.269204 19.4645 0.464466C19.2692 0.659728 19.2692 0.976311 19.4645 1.17157L22.2929 4L19.4645 6.82843C19.2692 7.02369 19.2692 7.34027 19.4645 7.53553C19.6597 7.7308 19.9763 7.7308 20.1716 7.53553L23.3536 4.35355ZM1 4.5L23 4.5V3.5L1 3.5V4.5Z"
                                    fill="#D84313"/>
                            </svg>
                        </div>
                    </Link>
                </div>
                <div className={"articles-all-page-categories-items"}>
                    {tempCat.map((category) => {
                        if (category !== currentCategory && category.id !== 6) {
                            return (
                                <div className={"articles-all-page-categories-item"} key={category.id} onClick={() => {
                                    setListVis(!categoriesListVis);
                                    setTemp(tempCat);
                                }}>
                                    <Link to={"/category/" + category.name}>
                                        <h2>{category.name}</h2>
                                        <div className={"articles-all-page-categories-item-img"}>
                                            <div className={"frame"}>
                                                <img src={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload" + category.image}/>
                                            </div>
                                        </div>
                                        <div className={"articles-all-page-categories-item-text"}>
                                            {category.description}
                                        </div>
                                        <div className={"articles-all-page-categories-item-link"}>
                                            Перейти в раздел "{category.name}"
                                        </div>
                                    </Link>
                                </div>
                            )
                        } else {
                            return null
                        }
                    })}
                </div>
            </div>
            <MainContacts/>
            <Footer/>
        </div>
    );
}

