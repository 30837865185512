import {Link} from "react-router-dom";
import React from "react";
import "./ReadMore.sass"

export function ReadMore(props){

    let current_cat = props.category;
    let current_article = props.article;


    let readMore = [];

    let len = current_cat.articles.length;

    if (len > 4){
        for (let i = len-1; i > len-5; i--){
            if (current_cat.articles[i] !== current_article){
                readMore.push(current_cat.articles[i])
            }
        }
    }else {
        for (let item of current_cat.articles){
            if (item !== current_article){
                readMore.push(item);
            }
        }
    }
    console.log(readMore);
    return (
        <div className={"read-more-block"}>
            {readMore.map((article) => {
                console.log(article.header.replaceAll(" ", '_'));
                return (
                    <Link to={"/article/" + article.header.replaceAll(" ", '_')} key={article.article_id}>
                        <div className={"category-view-articles-item"}>
                            <h4>{article.header}</h4>
                            <div className={"category-view-articles-item-image"}>
                                <div className={"frame"}><img
                                    src={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/" + article.image_big}
                                    alt={article.header}/></div>
                            </div>
                            <div className={"category-view-articles-item-text"}>
                                {article.description}
                            </div>
                        </div>
                        <div className={"category-view-articles-item-link"}>
                            читать
                        </div>
                    </Link>
                )
            })}
        </div>
    );
}