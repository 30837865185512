import {Sechead} from "../../components/Sechead/Sechead";
import {Link} from "react-router-dom";
import React from "react";
import "./Regulatory.sass"
import {MainContacts} from "../../components/MainContacts/MainContacts";
import {Footer} from "../../components/Footer/Footer";

export function Regulatory() {
    return (
        <div className={"regulatory-all"}>
            <Sechead/>
            <div className={"regulatory-page"}>
                <div className={"bread"}>
                    <ul>
                        <li><Link to="/">Главная</Link></li>
                        <li>Контролирующие органы</li>
                    </ul>
                </div>
                <h1>Адреса и телефоны контролирующих органов г.Красноярска и Красноярского края</h1>
                <div className={"intro-text"}>
                    <p>Адреса и телефоны органов исполнительной власти Красноярского края в сфере охраны здоровья
                        граждан, территориальных органов Федеральной службы по надзору в сфере здравоохранения и в
                        сфере защиты прав потребителя.</p>
                </div>
                <div className={"regulatory-blocks"}>
                    <div className={"regulatory-block"}>
                        <h2>Министерство здравоохранения Красноярского края</h2>
                        <div className={"regulatory-address"}>
                            660017, г. Красноярск, ул. Красной Армии, 3
                        </div>
                        <div className={"regulatory-tel"}>
                            <strong>Тел.:</strong> 8 (391) 211-51-51
                        </div>
                        <div className={"regulatory-fax"}>
                            <strong>Факс:</strong> 8 (391) 211-01-36
                        </div>
                        <div className={"regulatory-email"}>
                            <strong>E-mail:</strong> <Link
                            to={"mailto:office@kraszdrav.ru"}>office@kraszdrav.ru</Link>
                        </div>
                        <div className={"regulatory-site"}>
                            <strong>Официальный сайт:</strong> <Link
                            to={"https://kraszdrav.ru/"}>https://kraszdrav.ru/</Link>
                        </div>
                        <div className={"regulatory-chief"}>
                            <strong>Министр здравоохранения Красноярского края</strong> - Немик Борис Маркович
                        </div>
                    </div>
                    <div className={"regulatory-block"}>
                        <h2>Территориальный орган Федеральной службы по надзору в сфере здравоохранения по
                            Красноярскому Краю</h2>
                        <div className={"regulatory-address"}>
                            660021, г.Красноярск, проспект Мира, 132.
                        </div>
                        <div className={"regulatory-tel"}>
                            <strong>Тел.:</strong> 8 (391) 211-11-31
                        </div>
                        <div className={"regulatory-fax"}>
                            <strong>Факс:</strong> 8 (391) 2-213-123
                        </div>
                        <div className={"regulatory-email"}>
                            <strong>E-mail:</strong> <Link
                            to={"mailto:info@reg24.roszdavndzor.ru"}>info@reg24.roszdavndzor.ru</Link>
                        </div>
                        <div className={"regulatory-site"}>
                            <strong>Официальный сайт:</strong> <Link
                            to={"https://24reg.roszdravnadzor.ru"}>https://24reg.roszdravnadzor.ru</Link>
                        </div>
                        <div className={"regulatory-chief"}>
                            <strong>Руководитель</strong> - Деген Елена Валентиновна
                        </div>
                    </div>
                    <div className={"regulatory-block"}>
                        <h2>Управление Федеральной службы по надзору в сфере защиты прав потребителей и благополучия
                            человека Красноярского края</h2>
                        <div className={"regulatory-address"}>
                            660097, г.Красноярск, ул. Каратанова, 21.
                        </div>
                        <div className={"regulatory-tel"}>
                            <strong>Тел.:</strong> 8 (391) 226-89-50
                        </div>
                        <div className={"regulatory-fax"}>
                            <strong>Факс:</strong> 8 (391) 226-90-49
                        </div>
                        <div className={"regulatory-email"}>
                            <strong>E-mail:</strong> <Link
                            to={"mailto:info@reg24.roszdavndzor.ru"}>info@reg24.roszdavndzor.ru</Link>
                        </div>
                        <div className={"regulatory-site"}>
                            <strong>Официальный сайт:</strong> <Link
                            to={"https://24reg.roszdravnadzor.ru"}>https://24reg.roszdravnadzor.ru</Link>
                        </div>
                        <div className={"regulatory-chief"}>
                            <strong>Руководитель</strong> - Деген Елена Валентиновна
                        </div>
                    </div>
                    <div className={"regulatory-block"}>
                        <h2>Красноярское краевое общество защиты прав потребителей</h2>
                        <div className={"regulatory-address"}>
                            660049, г.Красноярск, ул. 9 января, 26а, оф.1.
                        </div>
                        <div className={"regulatory-tel"}>
                            <strong>Тел.:</strong> 8 (391) 227-68-69
                        </div>
                        <div className={"regulatory-email"}>
                            <strong>E-mail:</strong> <Link
                            to={"mailto:mail@kopkr.ru"}>mail@kopkr.ru</Link>
                        </div>
                    </div>
                    <div className={"regulatory-block"}>
                        <h2>Федеральное бюджетное учреждение здравоохранения "Центр гигиены и эпидемиологии в
                            Красноярском крае"</h2>
                        <div className={"regulatory-address"}>
                            660100, г.Красноярск, ул. Сопочная, 38.
                        </div>
                        <div className={"regulatory-tel"}>
                            <strong>Тел.:</strong> 8 (391) 202-58-01
                        </div>
                        <div className={"regulatory-fax"}>
                            <strong>Факс:</strong> 8 (391) 243-18-47
                        </div>
                        <div className={"regulatory-email"}>
                            <strong>E-mail:</strong> <Link
                            to={"mailto:fguz@24.rospotrebnadzor.ru"}>fguz@24.rospotrebnadzor.ru</Link>
                        </div>
                        <div className={"regulatory-site"}>
                            <strong>Официальный сайт:</strong> <Link
                            to={"https://24.rospotrebnadzor.ru/center/"}>https://24.rospotrebnadzor.ru/center/</Link>
                        </div>
                    </div>
                    <div className={"regulatory-block"}>
                        <h2>Государственное учреждение - Красноярское региональное отделение Фонда социального
                            страхования РФ</h2>
                        <div className={"regulatory-address"}>
                            660010, г.Красноярск, проспект имени газеты "Красноярский рабочий", 117.
                        </div>
                        <div className={"regulatory-tel"}>
                            <strong>Тел.:</strong> 8 (391) 268-72-02
                        </div>
                        <div className={"regulatory-fax"}>
                            <strong>Факс:</strong> 8 (391) 268-72-03
                        </div>
                        <div className={"regulatory-email"}>
                            <strong>E-mail:</strong> <Link
                            to={"mailto:info@r24.fss.ru"}>info@r24.fss.ru</Link>
                        </div>
                        <div className={"regulatory-site"}>
                            <strong>Официальный сайт:</strong> <Link
                            to={"https://r24.fss.ru"}>https://r24.fss.ru</Link>
                        </div>
                    </div>
                    <div className={"regulatory-block"}>
                        <h2>Филиал №1 (левобережный) ГУ Красноярское региональное отделение Фонда социального
                            страхования РФ</h2>
                        <div className={"regulatory-address"}>
                            660017, г.Красноярск, ул, Ленина, 108.
                        </div>
                        <div className={"regulatory-tel"}>
                            <strong>Тел.:</strong> 8 (391) 211-28-65
                        </div>
                        <div className={"regulatory-fax"}>
                            <strong>Факс:</strong> 8 (391) 211-30-56
                        </div>
                        <div className={"regulatory-email"}>
                            <strong>E-mail:</strong> <Link
                            to={"mailto:info_fil_1@ro24.fss.ru"}>info_fil_1@ro24.fss.ru</Link>
                        </div>
                    </div>
                </div>
            </div>
            <MainContacts/>
            <Footer/>
        </div>
    );
}