import './Services.sass';
import {Link, useParams} from "react-router-dom";
import {servicesList} from "../../reducers/dataReducer";
import {useSelector} from "react-redux";
import {Sechead} from "../../components/Sechead/Sechead";
import React from "react";
import parse from "html-react-parser";
import {Helmet} from "react-helmet";
import {MainContacts} from "../../components/MainContacts/MainContacts";
import {Footer} from "../../components/Footer/Footer";

export function Services() {
    let services = useSelector(servicesList);
    const {service_name} = useParams();

    let fix_name = service_name.replaceAll("_", ' ');

    let currentService;

    for (let item of services){
        if(item.name === fix_name){
            currentService = item;
        }
    }
    console.log(currentService);

    return (
        <div className="services">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Возрождение жизни - {currentService.name}</title>
            </Helmet>
            <Sechead/>
            <div className="service-page">
                <div className={"bread"}>
                    <ul>
                        <li><Link to="/">Главная</Link></li>
                        <li>Услуги</li>
                        <li>{currentService.name}</li>
                    </ul>
                </div>
                <div className={"service-page-text"}>
                    <h1>{currentService.name}</h1>
                    <div className={"service-page-text-image"}>
                        <img src={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/" + currentService.image}/>
                    </div>
                    {parse(currentService.text)}
                </div>
            </div>
            <MainContacts/>
            <Footer/>
        </div>
    );
}