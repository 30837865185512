import familyImg from "../../images/directions_images/family-guy.png";
import kidsImg from "../../images/directions_images/little-and-big-kids.png";
import vrImg from "../../images/directions_images/vr.png";
import deprImg from "../../images/directions_images/depression.png";
import profImg from "../../images/directions_images/profession.png";
import groupImg from "../../images/directions_images/big-group.png";
import warManImg from "../../images/directions_images/warman.png"
import bissImg from "../../images/directions_images/business.png"
import "./Directions.sass";
import React from "react";
import "animate.css/animate.compat.css"
import ScrollAnimation from 'sgk-react-animation-scroll';
import {Link} from "react-router-dom";

export function Directions() {
    return (
        <div className={"main-directions"}>
            <div className={"directions-blocks"}>

                <div className={"directions-block"}>
                    <Link to={"/article/Семейная_психотерапия"}>
                        <div className={"directions-block-header"}>
                            <h4>Семье и молодоженам</h4>
                            <svg width="24" height="8" viewBox="0 0 24 8" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5L1 3.5ZM23.3536 4.35355C23.5488 4.15829 23.5488 3.84171 23.3536 3.64644L20.1716 0.464464C19.9763 0.269202 19.6597 0.269202 19.4645 0.464464C19.2692 0.659727 19.2692 0.976309 19.4645 1.17157L22.2929 4L19.4645 6.82843C19.2692 7.02369 19.2692 7.34027 19.4645 7.53553C19.6597 7.73079 19.9763 7.73079 20.1716 7.53553L23.3536 4.35355ZM1 4.5L23 4.5L23 3.5L1 3.5L1 4.5Z"
                                    fill="white"/>
                            </svg>
                        </div>
                        <div className={"directions-block-image"}>
                            <img src={familyImg} alt={"фото семьи"}/>
                        </div>
                    </Link>
                </div>
                <div className={"directions-block"}>
                    <Link to={"/article/Детям_и_подросткам"}>
                        <div className={"directions-block-header"}>
                            <h4>Детям и подросткам</h4>
                            <svg width="24" height="8" viewBox="0 0 24 8" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5L1 3.5ZM23.3536 4.35355C23.5488 4.15829 23.5488 3.84171 23.3536 3.64644L20.1716 0.464464C19.9763 0.269202 19.6597 0.269202 19.4645 0.464464C19.2692 0.659727 19.2692 0.976309 19.4645 1.17157L22.2929 4L19.4645 6.82843C19.2692 7.02369 19.2692 7.34027 19.4645 7.53553C19.6597 7.73079 19.9763 7.73079 20.1716 7.53553L23.3536 4.35355ZM1 4.5L23 4.5L23 3.5L1 3.5L1 4.5Z"
                                    fill="white"/>
                            </svg>
                        </div>
                        <div className={"directions-block-image"}>
                            <img src={kidsImg} alt={"фото детям"}/>
                        </div>
                    </Link>
                </div>
                <div className={"directions-block"}>
                    <div className={"directions-block-header"}>
                        <h4>VR-технологии. Релаксация</h4>
                        <svg width="24" height="8" viewBox="0 0 24 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5L1 3.5ZM23.3536 4.35355C23.5488 4.15829 23.5488 3.84171 23.3536 3.64644L20.1716 0.464464C19.9763 0.269202 19.6597 0.269202 19.4645 0.464464C19.2692 0.659727 19.2692 0.976309 19.4645 1.17157L22.2929 4L19.4645 6.82843C19.2692 7.02369 19.2692 7.34027 19.4645 7.53553C19.6597 7.73079 19.9763 7.73079 20.1716 7.53553L23.3536 4.35355ZM1 4.5L23 4.5L23 3.5L1 3.5L1 4.5Z"
                                fill="white"/>
                        </svg>
                    </div>
                    <div className={"directions-block-image"}>
                        <img src={vrImg} alt={"фото VR"}/>
                    </div>
                </div>
                <div className={"directions-block"}>
                    <Link to={"/article/Лечение_депрессии"}>
                        <div className={"directions-block-header"}>
                            <h4>Депрессия</h4>
                            <svg width="24" height="8" viewBox="0 0 24 8" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5L1 3.5ZM23.3536 4.35355C23.5488 4.15829 23.5488 3.84171 23.3536 3.64644L20.1716 0.464464C19.9763 0.269202 19.6597 0.269202 19.4645 0.464464C19.2692 0.659727 19.2692 0.976309 19.4645 1.17157L22.2929 4L19.4645 6.82843C19.2692 7.02369 19.2692 7.34027 19.4645 7.53553C19.6597 7.73079 19.9763 7.73079 20.1716 7.53553L23.3536 4.35355ZM1 4.5L23 4.5L23 3.5L1 3.5L1 4.5Z"
                                    fill="white"/>
                            </svg>
                        </div>
                        <div className={"directions-block-image"}>
                            <img src={deprImg} alt={"фото дупрессия"}/>
                        </div>
                    </Link>
                </div>

                <div className={"directions-block"}>
                    <Link to={"/article/Профориентация"}>
                        <div className={"directions-block-header"}>
                            <h4>Профориентация</h4>
                            <svg width="24" height="8" viewBox="0 0 24 8" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5L1 3.5ZM23.3536 4.35355C23.5488 4.15829 23.5488 3.84171 23.3536 3.64644L20.1716 0.464464C19.9763 0.269202 19.6597 0.269202 19.4645 0.464464C19.2692 0.659727 19.2692 0.976309 19.4645 1.17157L22.2929 4L19.4645 6.82843C19.2692 7.02369 19.2692 7.34027 19.4645 7.53553C19.6597 7.73079 19.9763 7.73079 20.1716 7.53553L23.3536 4.35355ZM1 4.5L23 4.5L23 3.5L1 3.5L1 4.5Z"
                                    fill="white"/>
                            </svg>
                        </div>
                        <div className={"directions-block-image"}>
                            <img src={profImg} alt={"фото проф"}/>
                        </div>
                    </Link>
                </div>
                <div className={"directions-block"}>
                    <Link to={"/article/Групповая_психотерапия"}>
                        <div className={"directions-block-header"}>
                            <h4>Групповые занятия</h4>
                            <svg width="24" height="8" viewBox="0 0 24 8" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5L1 3.5ZM23.3536 4.35355C23.5488 4.15829 23.5488 3.84171 23.3536 3.64644L20.1716 0.464464C19.9763 0.269202 19.6597 0.269202 19.4645 0.464464C19.2692 0.659727 19.2692 0.976309 19.4645 1.17157L22.2929 4L19.4645 6.82843C19.2692 7.02369 19.2692 7.34027 19.4645 7.53553C19.6597 7.73079 19.9763 7.73079 20.1716 7.53553L23.3536 4.35355ZM1 4.5L23 4.5L23 3.5L1 3.5L1 4.5Z"
                                    fill="white"/>
                            </svg>
                        </div>
                        <div className={"directions-block-image"}>
                            <img src={groupImg} alt={"фото группы"}/>
                        </div>
                    </Link>
                </div>
                <div className={"directions-block"}>
                    <Link to={"/article/Коррекция_посттравматических_состояний"}>
                        <div className={"directions-block-header"}>
                            <h4>Военнослужащие и их семьи</h4>
                            <svg width="24" height="8" viewBox="0 0 24 8" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5L1 3.5ZM23.3536 4.35355C23.5488 4.15829 23.5488 3.84171 23.3536 3.64644L20.1716 0.464464C19.9763 0.269202 19.6597 0.269202 19.4645 0.464464C19.2692 0.659727 19.2692 0.976309 19.4645 1.17157L22.2929 4L19.4645 6.82843C19.2692 7.02369 19.2692 7.34027 19.4645 7.53553C19.6597 7.73079 19.9763 7.73079 20.1716 7.53553L23.3536 4.35355ZM1 4.5L23 4.5L23 3.5L1 3.5L1 4.5Z"
                                    fill="white"/>
                            </svg>
                        </div>
                        <div className={"directions-block-image"}>
                            <img src={warManImg} alt={"фото военный"}/>
                        </div>
                    </Link>
                </div>
                <div className={"directions-block"}>
                    <Link to={"/article/Эниоматрица_личности"}>
                        <div className={"directions-block-header"}>
                            <h4>Бизнесу</h4>
                            <svg width="24" height="8" viewBox="0 0 24 8" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5L1 3.5ZM23.3536 4.35355C23.5488 4.15829 23.5488 3.84171 23.3536 3.64644L20.1716 0.464464C19.9763 0.269202 19.6597 0.269202 19.4645 0.464464C19.2692 0.659727 19.2692 0.976309 19.4645 1.17157L22.2929 4L19.4645 6.82843C19.2692 7.02369 19.2692 7.34027 19.4645 7.53553C19.6597 7.73079 19.9763 7.73079 20.1716 7.53553L23.3536 4.35355ZM1 4.5L23 4.5L23 3.5L1 3.5L1 4.5Z"
                                    fill="white"/>
                            </svg>
                        </div>
                        <div className={"directions-block-image"}>
                            <img src={bissImg} alt={"фото бизнес"}/>
                        </div>
                    </Link>
                </div>
            </div>

            <div className={"about-centre"} id={"about-centre"}>
                <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true} offset={100}>
                    <div className={"about-centre-block"}>
                        <h2 className={"about-header"}>О центре</h2>
                        <div className={"about-text"}>
                            <p>Центр “Возрождение жизни” оказывает высокоэффективную, безопасную, конфиденциальную
                                психотерапевтическую помощь нуждающимся в ней пациентам (взрослым, пожилым и детям в том
                                числе),
                                проживающим в нашем городе и крае.</p>
                            <p>Стаж работы большинства врачей клиники превышает 15-20 лет.
                                Лечение осуществляется в амбулаторных условиях с учетом установленных стандартов. Наряду
                                с
                                психотерапевтическими методами по показаниям, врачи клиники подбирают
                                психофармакологическое
                                лечение. Применяемые методы диагностики позволяют подобрать адекватную схему терапии, на
                                которой
                                достигается ремиссия
                                и повышается качество жизни.</p>
                        </div>
                        <div className={"about-link"}>
                            <Link to={"/article/О_центре"}>Подробнее
                                <svg width="24" height="8" viewBox="0 0 24 8" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5V3.5ZM23.3536 4.35355C23.5488 4.15829 23.5488 3.84171 23.3536 3.64645L20.1716 0.464466C19.9763 0.269204 19.6597 0.269204 19.4645 0.464466C19.2692 0.659728 19.2692 0.976311 19.4645 1.17157L22.2929 4L19.4645 6.82843C19.2692 7.02369 19.2692 7.34027 19.4645 7.53553C19.6597 7.7308 19.9763 7.7308 20.1716 7.53553L23.3536 4.35355ZM1 4.5L23 4.5V3.5L1 3.5V4.5Z"
                                        fill="#D84313"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </ScrollAnimation>
            </div>
        </div>
    );
}