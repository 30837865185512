import Logo from "../../images/logo4.png";
import React, {useState} from "react";
import "./FirstHeader.sass";
import {Link as Link1} from 'react-scroll';
import {HashLink} from "react-router-hash-link";

export function FirstHeader() {

    const [menuStatus, setMenustatus] = useState(false);

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
        const yOffset = -850;
        window.scrollTo({top: yCoordinate + yOffset, behavior: 'smooth'});
    }


    return (
        <div className={"first-menu"}>
            <div className={"logo-main"}>
                <img src={Logo} alt={"Лого"}/>
            </div>
            <div className={"header-lk"}>
                <span>личный кабинет</span>
            </div>
            <div className={"header-rec"}>
                <span>онлайн запись</span>
            </div>
            <div className="menu" onClick={() => setMenustatus(!menuStatus)}>
                <div className={"menu-label"}>меню</div>
                <div className={!menuStatus ? "collapsed" : "expanded"}>
                    <nav>
                        <HashLink to="/#about-centre" smooth scroll={scrollWithOffset}>О центре</HashLink>
                        <Link1 to="speci" offset={-1050} smooth={true} onClick={() => setMenustatus(!menuStatus)}>Специалисты</Link1>
                        <Link1 to="price" offset={-1050} smooth={true} onClick={() => setMenustatus(!menuStatus)}>Услуги и цены</Link1>
                        <Link1 to="blog" offset={-1050} smooth={true} onClick={() => setMenustatus(!menuStatus)}>Блог</Link1>
                        <Link1 to="vr-tech" offset={-1050} smooth={true} onClick={() => setMenustatus(!menuStatus)}>VR технологии</Link1>
                        <Link1 to="news" offset={-1050} smooth={true} onClick={() => setMenustatus(!menuStatus)}>Новости</Link1>
                    </nav>
                </div>
                <div className={!menuStatus ? "focus-back-inviz" : "focus-back"}></div>
            </div>
            <div className={"header-eye"}>
                <svg width="23" height="9" viewBox="0 0 23 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M0.958333 4.5C0.42906 4.5 0 4.09706 0 3.6C0 3.10294 0.42906 2.7 0.958333 2.7H1.35704C2.09645 1.11069 3.78511 0 5.75 0C7.49839 0 9.02806 0.879407 9.86487 2.19292C10.3352 1.94223 10.9025 1.8 11.5 1.8C12.0975 1.8 12.6648 1.94223 13.1351 2.19292C13.9719 0.879407 15.5016 0 17.25 0C19.2149 0 20.9035 1.11069 21.643 2.7H22.0417C22.5709 2.7 23 3.10294 23 3.6C23 4.09706 22.5709 4.5 22.0417 4.5C22.0417 6.98528 19.8964 9 17.25 9C14.6036 9 12.4583 6.98528 12.4583 4.5C12.4583 4.34812 12.4663 4.19801 12.482 4.05H12.4583C12.4583 3.86692 12.0794 3.6 11.5 3.6C10.9206 3.6 10.5417 3.86692 10.5417 4.05H10.518C10.5337 4.19801 10.5417 4.34812 10.5417 4.5C10.5417 6.98528 8.39636 9 5.75 9C3.10364 9 0.958333 6.98528 0.958333 4.5ZM5.75 7.2C7.33782 7.2 8.625 5.99117 8.625 4.5C8.625 3.00883 7.33782 1.8 5.75 1.8C4.16218 1.8 2.875 3.00883 2.875 4.5C2.875 5.99117 4.16218 7.2 5.75 7.2ZM17.25 7.2C18.8378 7.2 20.125 5.99117 20.125 4.5C20.125 3.00883 18.8378 1.8 17.25 1.8C15.6622 1.8 14.375 3.00883 14.375 4.5C14.375 5.99117 15.6622 7.2 17.25 7.2Z"
                          fill="white"/>
                </svg>
            </div>
        </div>
    );
}