import Modal from "react-modal";
import validator from "validator";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addDisease, doctorsList, servicesList} from "../../../../reducers/dataReducer";
import {IoTrashOutline} from "react-icons/io5";

import {CKEditor} from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build';

export function AddDisease() {
    const dispatch = useDispatch();
    const [showModal, setModal] = useState(false);

    let services = useSelector(servicesList);
    let doctors = useSelector(doctorsList);

    const [chosenDoctors, setChosenDoctors] = useState([]);

    const [selectedDoctor, setDoctor] = useState(0);

    const [diseaseName, setDiseaseName] = useState("");
    const [diseaseImage, setImage] = useState("");
    const [diseaseText, setText] = useState("");
    const [diseaseOwner, setOwner] = useState("");
    const [diseaseDoctors, setDoctors] = useState([]);



    const [deletedDoctor, setDel] = useState(null);

    const [diseaseFile, setFile] = useState();

    //токен
    const [token, setToken] = useState("");
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            setToken(token);
        }
    }, []);
    //__/токен


    let AddSpecArticle = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: "POST",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                "id": 0,
                "name": diseaseName,
                "image": diseaseImage,
                "text": diseaseText,
                "owner_id": diseaseOwner,
                "doctors": JSON.stringify(diseaseDoctors)
            }),
        };
        const response = await fetch("https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/diseases/add", requestOptions);
        const data = await response.json();
        if (!response.ok) {
            console.log("Хуйня");
        } else {
            dispatch(addDisease(data));
            setDiseaseName("");
            setImage("");
            setText("");
            setOwner("");
            setDoctors([]);
        }
        setModal(false);
    };

    const [inputsValid, setValid] = useState({
        name: validator.isLength(diseaseName, {min: 5, max: 40}),
        image: validator.isLength(diseaseImage, {min: 5, max: 40}),
        text: validator.isLength(diseaseText, {min: 5, max: 20000}),
        owner_id: validator.isInt(diseaseOwner,),
        doctors: validator.isJSON(JSON.stringify(diseaseDoctors))
    });

    const formValid = (values) => {
        return !(values.image === true &&
            values.name === true &&
            values.text === true &&
            values.owner_id === true);
    };

    let UploadImage = async (e) => {
        e.preventDefault()
        const image = new FormData();
        image.append('uploaded_file', diseaseFile);

        const requestOptions = {
            method: "POST",
            headers: {
                "accept": "application/json",
                Authorization: "Bearer " + token,
            },
            "Content-Type": "multipart/form-data",
            body: image,
        };
        const response = await fetch("https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload-file/", requestOptions);


        const data = await response.json();
        if (!response.ok) {
            console.log(response);
        } else {
            setImage(diseaseFile.name);
            setValid({
                ...inputsValid,
                image: validator.isLength(diseaseFile.name, {
                    min: 5,
                    max: 70,
                }),
            });
            console.log(data);
        }
    }

    const AddChoseDoctor = (e) => {
        e.preventDefault();
        let add = {};
        for (let doctor of doctors) {
            if (doctor.id === selectedDoctor) {
                add = doctor;
            }
        }
        if (!chosenDoctors.includes(add)) {
            setChosenDoctors(arr => [...arr, add])
            setDoctors(arr => [...arr, add.id])
        }

    }

    useEffect(() => {
        if (deletedDoctor) {
            let chose = chosenDoctors;
            let doctors = diseaseDoctors;

            for (let i = 0; i < chose.length; i++) {
                if (chose[i] === deletedDoctor) {
                    chose.splice(i, 1);
                }
            }
            setChosenDoctors(chose);
            for (let i = 0; i < doctors.length; i++) {
                if (doctors[i] === deletedDoctor.id) {
                    doctors.splice(i, 1);
                }
            }
            setDoctors(doctors);
            setDel(null);
        }
    }, [deletedDoctor]);

    return (
        <div className={"add-disease-form"}>
            <div className={"add-disease-button"}>
                <button onClick={() => setModal(true)} className={"add-article-button"}>Добавить</button>
            </div>
            <Modal isOpen={showModal} contentLabel="Форма: Добавить спец статью">
                <h2>Добавить Заболевание</h2>
                <form>
                    <label>
                        <span>Название</span>
                        <input
                            name="disease-name"
                            type="text"
                            value={diseaseName}
                            onChange={(event) => setDiseaseName(event.target.value)}
                            onInput={(event) =>
                                setValid({
                                    ...inputsValid,
                                    name: validator.isLength(event.target.value, {
                                        min: 5,
                                        max: 80,
                                    }),
                                })
                            }
                        />
                        <span className="warning">
                          {inputsValid.name === true
                              ? ""
                              : "Введите название!"}
                        </span>
                    </label>
                    <br/>
                    <label>
                        <span>Фото</span>
                        <input
                            name="disease-image"
                            type="text"
                            value={diseaseImage}
                            onChange={(event) => setImage(event.target.value)}
                            onInput={(event) =>
                                setValid({
                                    ...inputsValid,
                                    image: validator.isLength(event.target.value, {
                                        min: 5,
                                        max: 70,
                                    }),
                                })
                            }
                        />
                        <span className="warning">
                          {inputsValid.image === true
                              ? ""
                              : "Введите название!"}
                        </span>
                    </label>
                    <label>
                        <input
                            name="article-image-file"
                            type="file"
                            onChange={(event) => setFile(event.target.files[0])}
                        />
                        <button onClick={UploadImage}> загрузить</button>
                    </label>
                    <br/>
                    <label>
                        <span>Текст Статьи</span>
                        <CKEditor
                            editor={Editor}
                            data={diseaseText}
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setText(data);
                                setValid({
                                    ...inputsValid,
                                    text: validator.isLength(data, {
                                        min: 5,
                                        max: 20000,
                                    }),
                                });
                            }}

                        />
                        <span className="warning">
                              {inputsValid.text === true
                                  ? ""
                                  : "Введите Текст!"}
                            </span>
                    </label>
                    <br/>
                    <label>
                        <span>Раздел</span>
                        <select onChange={(event) => setOwner(event.target.value)}
                                onInput={(event) =>
                                    setValid({
                                        ...inputsValid,
                                        owner_id: validator.isInt(event.target.value,),
                                    })
                                }>
                            <option value="">Выберете Раздел</option>
                            {services.map((service) => {
                                return (
                                    <option key={service.id} value={service.id}>{service.name}</option>
                                )
                            })}
                        </select>
                    </label>
                    <br/>
                    <label>
                        <select onChange={(event) => setDoctor(parseInt(event.target.value))}>
                            <option value="">Выберете Доктора</option>
                            {doctors.map((doctor) => {
                                return (
                                    <option key={doctor.id}
                                            value={doctor.id}>{doctor.surname + " " + doctor.name + " " + doctor.patronymics}</option>
                                )
                            })}
                        </select>
                        <button onClick={AddChoseDoctor}>добавить доктора
                        </button>
                        <div className={"doc-list"}>
                            {chosenDoctors.map((doctor) => {
                                return (
                                    <div className={"doc"} key={doctor.id}>
                                        {doctor.surname + " " + doctor.name + " " + doctor.patronymics}
                                        <span onClick={() => setDel(doctor)}><IoTrashOutline/></span>
                                    </div>
                                )
                            })}
                        </div>
                    </label>
                    <br/>
                </form>
                <button
                    onClick={AddSpecArticle}
                    className="AddButton"
                    disabled={formValid(inputsValid)}
                >
                    Добавить
                </button>
                <button onClick={() => setModal(false)} className="closeButton">
                    Закрыть
                </button>
            </Modal>
        </div>
    );
}