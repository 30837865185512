import React, {useState} from "react";
import ReactPlayer from "react-player/youtube";
import {Sechead} from "../../components/Sechead/Sechead";
import "./VideoPage.sass";
import {mainVideo, videoCategoriesList} from "../../reducers/dataReducer";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import aboutImg from "../../images/categories_image/about.png";
import advices from "../../images/categories_image/advices.png";
import usefull from "../../images/categories_image/useful.png"
import {MainContacts} from "../../components/MainContacts/MainContacts";
import {Footer} from "../../components/Footer/Footer";

export function VideoPage() {
    const categoryList = useSelector(videoCategoriesList);
    console.log(categoryList);
    const main_video = useSelector(mainVideo);


    const [activeCategory, setCategory] = useState(categoryList.about);




    return (
        <div className={"video-main"}>
            <Sechead/>
            <div className={"video-main-page"}>
                <div className={"bread"}>
                    <ul>
                        <li><Link to="/">Главная</Link></li>
                        <li><Link to={"/video"}>Видео</Link></li>
                    </ul>
                </div>
                <div className={"video-categories"}>
                    {categoryList.map((category, index) => {
                        return (
                            <div className={category.category === activeCategory ? "video-category-active" : "video-category"}
                                 key={index} onClick={() => setCategory(category)}>
                                <div className={"video-category-name"}>
                                    <Link to={"/videos/" + category.category.replaceAll(" ", "_")}>{category.category}</Link>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className={"main-video"}>
                    <ReactPlayer url={'https://www.youtube.com/watch?v=' + main_video.url} controlst="0" width={641}
                                 height={362} light={true} className={"player"}/>
                    <div className={"video-text-block"}>
                        <div className={"main-video-header"}>
                            {main_video.header}
                        </div>
                        <div className={"main-video-text"}>
                            {main_video.text}
                        </div>
                    </div>
                </div>
                <div className={"video-categories-block"}>
                    <div className={"video-category-link"}>
                        <Link to={"/videos/О_клинике"}>Перейти в раздел “О Клинике”
                            <div className={"arrow"}>
                                <svg width="24" height="8" viewBox="0 0 24 8" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5V3.5ZM23.3536 4.35355C23.5488 4.15829 23.5488 3.84171 23.3536 3.64645L20.1716 0.464466C19.9763 0.269204 19.6597 0.269204 19.4645 0.464466C19.2692 0.659728 19.2692 0.976311 19.4645 1.17157L22.2929 4L19.4645 6.82843C19.2692 7.02369 19.2692 7.34027 19.4645 7.53553C19.6597 7.7308 19.9763 7.7308 20.1716 7.53553L23.3536 4.35355ZM1 4.5L23 4.5V3.5L1 3.5V4.5Z"
                                        fill="#D84313"/>
                                </svg>
                            </div>
                        </Link>
                    </div>
                    <div className={"video-playlists"}>
                        <Link to={"/videos/Интервью"}>
                            <div className={"video-playlists-item"}>
                                <div className={"video-playlists-item-header"}>
                                    <h3>Интервью</h3>
                                </div>
                                <div className={"video-playlists-item-img"}>
                                    <img src={aboutImg} alt={"О Клинике"}/>
                                </div>
                                <div className={"video-playlists-item-text"}>
                                    Основная миссия нашего центра - оказание помощи нуждающимся в ней пациентам
                                    (взрослым,
                                    пожилым и детям в том числе), проживающим в нашем городе и крае.
                                </div>
                                <div className={"video-playlists-item-link"}>
                                    Перейти в раздел “Интервью”
                                </div>
                            </div>
                        </Link>
                        <Link to={"/videos/Советы_экспертов"}>
                            <div className={"video-playlists-item"}>
                                <div className={"video-playlists-item-header"}>
                                    <h3>Советы экспертов</h3>
                                </div>
                                <div className={"video-playlists-item-img"}>
                                    <img src={advices} alt={"Советы экспертов"}/>
                                </div>
                                <div className={"video-playlists-item-text"}>
                                    Основная миссия нашего центра - оказание помощи нуждающимся в ней пациентам
                                    (взрослым,
                                    пожилым и детям в том числе), проживающим в нашем городе и крае.
                                </div>
                                <div className={"video-playlists-item-link"}>
                                    Перейти в раздел “Советы экспертов”
                                </div>
                            </div>
                        </Link>
                        <Link to={"/videos/Полезные_материалы"}>
                            <div className={"video-playlists-item"}>
                                <div className={"video-playlists-item-header"}>
                                    <h3>Полезные материалы</h3>
                                </div>
                                <div className={"video-playlists-item-img"}>
                                    <img src={usefull} alt={"Полезные материалы"}/>
                                </div>
                                <div className={"video-playlists-item-text"}>
                                    Основная миссия нашего центра - оказание помощи нуждающимся в ней пациентам
                                    (взрослым,
                                    пожилым и детям в том числе), проживающим в нашем городе и крае.
                                </div>
                                <div className={"video-playlists-item-link"}>
                                    Перейти в раздел “Полезные материалы”
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <MainContacts/>
            <Footer/>
        </div>
    );
}