import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import validator from "validator";
import {addImage} from "../../../../reducers/dataReducer";
import {useDispatch} from "react-redux";

export function AddNewImage(){
    const dispatch = useDispatch();

    const [showModal, setModal] = useState(false);

    const [addingImage, setImage] = useState("");
    const [imageFile, setFile] = useState();

    let UploadImage = async (e) => {
        e.preventDefault()
        const image = new FormData();
        image.append('uploaded_file', imageFile);

        const requestOptions = {
            method: "POST",
            headers: {
                "accept": "application/json",
                Authorization: "Bearer " + token,
            },
            "Content-Type": "multipart/form-data",
            body: image,
        };
        const response = await fetch("https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload-file/", requestOptions);


        const data = await response.json();
        if (!response.ok) {
            console.log(response);
        } else {
            setImage(imageFile.name);
            setValid({
                ...inputsValid,
                url: validator.isURL("https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/" + imageFile.name),
                image: validator.isLength(imageFile.name, {
                                        min: 5,
                                        max: 40,
                                    }),
            });
            console.log(data);
        }
    }

    let NewImage = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: "POST",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                "id": 0,
                "url": "https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/"+addingImage
            }),
        };
        const response = await fetch("https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/images-gallery/add", requestOptions);
        const data = await response.json();
        if (!response.ok) {
            console.log("Хуйня");
        } else {
            dispatch(addImage(data));
        }
        setModal(false);
    };

    //токен
    const [token, setToken] = useState("");
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            setToken(token);
        }
    }, []);
    //__/токен

    const [inputsValid, setValid] = useState({
        image: validator.isLength(addingImage, {min: 5, max: 40}),
        url: validator.isURL("https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/" + addingImage),
    });
    const formValid = (values) => {
        return !(values.image === true &&
            values.url === true );
    };

    return(
        <div className={"add-image-form"}>
            <div className={"add-image"}>
                <button onClick={() => setModal(true)} className={"add-news-button"}>Добавить</button>
            </div>
            <Modal isOpen={showModal} contentLabel="Форма: Добавить статью">
                <h2>Добавить фото</h2>
                <form>
                    <label>
                        <span>Главное фото</span>
                        <input
                            name="image"
                            type="text"
                            value={addingImage}
                            onChange={(event) => setImage(event.target.value)}
                            onInput={(event) =>
                                setValid({
                                    ...inputsValid,
                                    image: validator.isLength(event.target.value, {
                                        min: 5,
                                        max: 40,
                                    }),
                                })
                            }
                        />
                        <span className="warning">
                              {inputsValid.image === true
                                  ? ""
                                  : "фото!"}
                            </span>
                    </label>
                    <label>
                        <input
                            name="article-image-file"
                            type="file"
                            onChange={(event) => setFile(event.target.files[0])}
                        />
                        <button onClick={UploadImage}> загрузить</button>
                    </label>
                </form>
                <button
                    onClick={NewImage}
                    className="AddButton"
                    disabled={formValid(inputsValid)}
                >
                    Добавить
                </button>
                    <button onClick={() => setModal(false)} className="closeButton">
                        Закрыть
                    </button>
            </Modal>
        </div>
);
}