import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {categoriesList, editArticle} from "../../../../reducers/dataReducer";

import {AddCategory} from "./AddCategory";
import Modal from "react-modal";
import "./EditArticle.sass"

import {CKEditor} from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build';

export function EditArticle(props) {
    let edited_article = props.article;


    let categories = useSelector(categoriesList);

    const dispatch = useDispatch();
    const [showModal, setModal] = useState(false);


    const [articleId, setId] = useState(edited_article.article_id);
    const [articleHeader, setHeader] = useState(edited_article.header);
    const [articleDate, setDate] = useState(edited_article.date);
    const [articleDesc, setDesc] = useState(edited_article.description);
    const [articleImage, setImage] = useState(edited_article.image);
    const [articleText, setText] = useState(edited_article.text);
    const [parentCategory, setCategory] = useState(edited_article.owner_id);
    const [articleFile, setFile] = useState();




    //токен
    const [token, setToken] = useState("");
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            setToken(token);
        }
    }, []);
    //__/токен

    let EditArt = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: "PATCH",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                "header": articleHeader,
                "datetime": articleDate,
                "description": articleDesc,
                "image": articleImage,
                "image_big": articleImage,
                "text": articleText,
                "owner_id": parentCategory
            }),
        };
        const response = await fetch(`https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/articles/edit?article_id=${articleId}`, requestOptions);
        const data = await response.json();
        if (!response.ok) {
            console.log("Хуйня");
        } else {
            dispatch(editArticle(data));
        }
        setModal(false);
    };
    let UploadImage = async (e) => {
        e.preventDefault()
        const image = new FormData();
        image.append('uploaded_file', articleFile);

        const requestOptions = {
            method: "POST",
            headers: {
                "accept": "application/json",
                Authorization: "Bearer " + token,
            },
            "Content-Type": "multipart/form-data",
            body: image,
        };
        const response = await fetch("https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload-file/", requestOptions);


        const data = await response.json();
        if (!response.ok) {
            console.log(response);
        } else {
            setImage(articleFile.name);
            console.log(data);
        }
    }

    return(
        <div className={"edit-article-button"}>
            <button onClick={() => setModal(true)}>Изменить</button>
            <Modal isOpen={showModal} contentLabel="Форма: Добавить статью">
                <h2>Добавить статью</h2>
                <form>
                    <label>
                        <span>Заголовок</span>
                        <input
                            name="article-header"
                            type="text"
                            value={articleHeader}
                            onChange={(event) => setHeader(event.target.value)}
                        />
                    </label>
                    <br/>
                    <label>
                        <span>Краткое содержание</span>
                        <input
                            name="article-description"
                            type="text"
                            value={articleDesc}
                            onChange={(event) => setDesc(event.target.value)}
                        />
                    </label>
                    <br/>
                    <label>
                        <span>Главное фото</span>
                        <input
                            name="article-image"
                            type="text"
                            value={articleImage}
                            onChange={(event) => setImage(event.target.value)}
                        />
                    </label>
                    <label>
                        <input
                            name="article-image-file"
                            type="file"
                            onChange={(event) => setFile(event.target.files[0])}
                        />
                        <button onClick={UploadImage}> загрузить</button>
                    </label>
                    <br/>
                    <label>
                        <span>Текст Статьи</span>
                        <CKEditor
                            editor={Editor}
                            data={articleText}
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setText(data)
                            }}

                        />
                    </label>
                    <br/>
                    <label>
                        <span>Категория</span>
                        <select onChange={(event) => setCategory(event.target.value)} value={parentCategory}>
                            {categories.map((category) => {
                                return (
                                    <option key={category.id} value={category.id} >{category.name}</option>
                                )
                            })}
                        </select>
                    </label>
                </form>
                <AddCategory/>
                <button
                    onClick={EditArt}
                    className="AddButton"
                >
                    Изменить
                </button>
                <button onClick={() => setModal(false)} className="closeButton">
                    Закрыть
                </button>
            </Modal>
        </div>
    );
}