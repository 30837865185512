import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import {useDispatch} from "react-redux";
import {editService} from "../../../../reducers/dataReducer";
import {IoBuildOutline} from "react-icons/io5";

import {CKEditor} from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build';

export function EditService(props) {
    let edited_service = props.service;

    const dispatch = useDispatch();
    const [showModal, setModal] = useState(false);

    const [serviceName, setName] = useState(edited_service.name);
    const [serviceImage, setImage] = useState(edited_service.image);
    const [serviceText, setText] = useState(edited_service.text);
    const [serviceFile,setFile] = useState();


    //токен
    const [token, setToken] = useState("");
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            setToken(token);
        }
    }, []);
    //__/токен

    let UploadImage = async (e) => {
        e.preventDefault()
        const image = new FormData();
        image.append('uploaded_file', serviceFile);

        const requestOptions = {
            method: "POST",
            headers: {
                "accept": "application/json",
                Authorization: "Bearer " + token,
            },
            "Content-Type": "multipart/form-data",
            body: image,
        };
        const response = await fetch("https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload-file/", requestOptions);


        const data = await response.json();
        if (!response.ok) {
            console.log(response);
        } else {
            setImage(serviceFile.name);
            console.log(data);
        }
    }

    let EditService = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: "PATCH",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                "name": serviceName,
                "image": serviceImage,
                "text": serviceText,
            }),
        };
        const response = await fetch(`https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/services/edit?service_id=${edited_service.id}`, requestOptions);
        const data = await response.json();
        if (!response.ok) {
            console.log("Хуйня");
        } else {
            dispatch(editService(data));
        }
        setModal(false);
    };


    return (
        <div className={"edit-service-form"}>
            <button onClick={() => setModal(true)}><IoBuildOutline/></button>
            <Modal isOpen={showModal} contentLabel="Форма: Добавить статью">
                <form>
                    <label>
                        <span>Название</span>
                        <input
                            name="service-name"
                            type="text"
                            value={serviceName}
                            onChange={(event) => setName(event.target.value)}
                        />
                    </label>
                    <br/>
                    <label>
                        <span>Фото</span>
                        <input
                            name="service-image"
                            type="text"
                            value={serviceImage}
                            onChange={(event) => setImage(event.target.value)}
                        />
                    </label>
                    <label>
                        <input
                            name="article-image-file"
                            type="file"
                            onChange={(event) => setFile(event.target.files[0])}
                        />
                        <button onClick={UploadImage}> загрузить</button>
                    </label>
                    <br/>
                    <label>
                        <span>Текст Статьи</span>
                        <CKEditor
                            editor={Editor}
                            data={serviceText}
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setText(data)
                            }}

                        />
                    </label>
                </form>
                <button
                    onClick={EditService}
                    className="AddButton"
                >
                    Изменить
                </button>
                <button onClick={() => setModal(false)} className="closeButton">
                    Закрыть
                </button>
            </Modal>
        </div>
    );
}