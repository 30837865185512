import "./Diseases.sass";
import {diseasesList, servicesList} from "../../reducers/dataReducer";
import {Link, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {Sechead} from "../../components/Sechead/Sechead";
import React from "react";
import parse from "html-react-parser";
import {Helmet} from "react-helmet";
import {MainContacts} from "../../components/MainContacts/MainContacts";
import {Footer} from "../../components/Footer/Footer";

export function Diseases(){
    let diseases = useSelector(diseasesList);
    let services = useSelector(servicesList);
    const {dis_name} = useParams();
    console.log(dis_name)

    let fix_name = dis_name.replaceAll("_", ' ').replaceAll("q", "?");
    console.log(fix_name)

    let currentDisease;
    let currentService;

    for(let item of diseases){
        if(item.name === fix_name){
            currentDisease = item;
            for (let serv of services){
                if (serv.id === currentDisease.owner_id){
                    currentService = serv;
                }
            }
        }
    }


    return(
        <div className={"diseases"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Возрождение жизни - {currentService.name} - {currentDisease.name}</title>
            </Helmet>
            <Sechead/>
            <div className={"diseases-page"}>
                <div className={"bread"}>
                    <ul>
                        <li><Link to="/">Главная</Link></li>
                        <li>Услуги</li>
                        <li>{currentService.name}</li>
                        <li>{currentDisease.name}</li>
                    </ul>
                </div>
                <div className={"diseases-page-text"}>
                    <h1>{currentDisease.name}</h1>
                    <div className={"diseases-page-text-image"}>
                        <img src={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/" + currentDisease.image}/>
                    </div>
                    {parse(currentDisease.text)}
                </div>
            </div>
            <MainContacts/>
            <Footer/>
        </div>
    );
}