import AudioPlayer from "react-h5-audio-player";
import Audio1 from "../../audio/12-16-49. Dewtone.mp3";
import React, {useRef, useState} from "react";
import sound from "../../images/sound1.png";
import nosound from "../../images/sound2.png"
import Audio2 from "../../audio/sound2.mp3"

import "./AudioTrack.sass"

export function AudioTrack(props) {
    const [soundStatus, setSound] = useState(false);
    const [soundSource, setSource] = useState(Audio1);
    const ref = useRef(null);

    const [hoverStatus, setHover] = useState(false);

    const view = props.player;

    return (
        <>
            <div className={!view && !hoverStatus? "header-sound" : "header-sound-fix"} onMouseLeave={() => setHover(false)}>
                <div className={"header-sound-icon"} onClick={() => {
                    if (soundStatus) {
                        ref.current.audio.current.pause();
                        setSound(false);
                    } else {
                        ref.current.audio.current.play();
                        setSound(true);
                    }
                }}>
                    {soundStatus ?
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"
                             className={"sound-on"}>
                            <path
                                d="M9.6348 17C9.51923 16.9997 9.40651 16.9586 9.31174 16.8823L3.6441 12.3039C3.56902 12.2438 3.50765 12.1636 3.46522 12.0702C3.42278 11.9769 3.40054 11.873 3.40039 11.7676V5.22705C3.40054 5.12161 3.42278 5.01777 3.46522 4.92438C3.50765 4.83099 3.56902 4.75083 3.6441 4.69073L9.31174 0.112363C9.39437 0.0478447 9.49028 0.00963574 9.5898 0.00159676C9.68931 -0.00644223 9.7889 0.0159735 9.87851 0.066579C9.97409 0.119104 10.055 0.201475 10.1121 0.304357C10.1692 0.40724 10.2002 0.526522 10.2016 0.648686V16.3459C10.2018 16.466 10.1733 16.5839 10.1193 16.6866C10.0654 16.7893 9.98792 16.8728 9.89551 16.928C9.81484 16.9757 9.72544 17.0004 9.6348 17ZM4.53392 11.4275L9.06803 15.0902V1.90447L4.53392 5.56716V11.4275Z"
                                fill="#D84313"/>
                            <path
                                d="M3.96735 12.4216H1.70029C1.24935 12.4216 0.816871 12.2149 0.498004 11.8469C0.179138 11.4789 0 10.9799 0 10.4595V6.53516C0 6.01476 0.179138 5.51568 0.498004 5.1477C0.816871 4.77972 1.24935 4.573 1.70029 4.573H3.96735C4.11767 4.573 4.26183 4.64191 4.36811 4.76457C4.4744 4.88722 4.53412 5.05358 4.53412 5.22705V11.7676C4.53412 11.941 4.4744 12.1074 4.36811 12.2301C4.26183 12.3527 4.11767 12.4216 3.96735 12.4216ZM1.70029 5.8811C1.54998 5.8811 1.40582 5.95001 1.29953 6.07267C1.19324 6.19533 1.13353 6.36169 1.13353 6.53516V10.4595C1.13353 10.6329 1.19324 10.7993 1.29953 10.922C1.40582 11.0446 1.54998 11.1135 1.70029 11.1135H3.40059V5.8811H1.70029Z"
                                fill="#D84313"/>
                            <path
                                d="M14.4411 14.6978C14.3666 14.6983 14.2926 14.6818 14.2236 14.6493C14.1545 14.6167 14.0917 14.5688 14.0387 14.5081C13.9856 14.4473 13.9435 14.375 13.9147 14.2953C13.8859 14.2156 13.8711 14.1301 13.8711 14.0438C13.8711 13.9574 13.8859 13.8719 13.9147 13.7922C13.9435 13.7125 13.9856 13.6402 14.0387 13.5794C15.2051 12.2306 15.8601 10.4029 15.8601 8.4974C15.8601 6.5919 15.2051 4.76422 14.0387 3.41542C13.9859 3.35443 13.944 3.28204 13.9154 3.20236C13.8868 3.12268 13.8721 3.03728 13.8721 2.95104C13.8721 2.8648 13.8868 2.7794 13.9154 2.69972C13.944 2.62004 13.9859 2.54765 14.0387 2.48666C14.0916 2.42568 14.1543 2.37731 14.2234 2.3443C14.2924 2.3113 14.3664 2.29431 14.4411 2.29431C14.5159 2.29431 14.5899 2.3113 14.6589 2.3443C14.728 2.37731 14.7907 2.42568 14.8435 2.48666C16.2245 4.0811 17.0002 6.24312 17.0002 8.4974C17.0002 10.7517 16.2245 12.9137 14.8435 14.5081C14.7906 14.5688 14.7278 14.6167 14.6587 14.6493C14.5897 14.6818 14.5157 14.6983 14.4411 14.6978Z"
                                fill="#D84313"/>
                            <path
                                d="M12.839 12.8468C12.6903 12.8461 12.5478 12.778 12.4422 12.6571C12.3488 12.5321 12.2998 12.3709 12.3051 12.2059C12.3103 12.041 12.3695 11.8844 12.4706 11.7676C12.839 11.3425 13.1313 10.8377 13.3307 10.2822C13.5301 9.72673 13.6327 9.13133 13.6327 8.53006C13.6327 7.92878 13.5301 7.33339 13.3307 6.77788C13.1313 6.22238 12.839 5.71764 12.4706 5.2925C12.3777 5.16738 12.3292 5.00643 12.3347 4.84182C12.3402 4.67721 12.3994 4.52106 12.5003 4.40458C12.6012 4.2881 12.7366 4.21986 12.8792 4.2135C13.0218 4.20714 13.1613 4.26313 13.2697 4.37028C14.225 5.474 14.7615 6.97014 14.7615 8.53006C14.7615 10.09 14.225 11.5861 13.2697 12.6898C13.2111 12.7487 13.1426 12.793 13.0685 12.82C12.9944 12.847 12.9163 12.8561 12.839 12.8468Z"
                                fill="#D84313"/>
                        </svg>
                        :
                        <svg width="17" height="24" viewBox="0 0 17 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                             className={"sound-of"}>
                            <path d="M1 0.5L14 23.5" stroke="#D84313" strokeLinecap="round"/>
                            <path
                                d="M9.6348 21C9.51923 20.9997 9.40651 20.9586 9.31174 20.8823L3.6441 16.3039C3.56902 16.2438 3.50765 16.1636 3.46522 16.0702C3.42278 15.9769 3.40054 15.873 3.40039 15.7676V9.22705C3.40054 9.12161 3.42278 9.01777 3.46522 8.92438C3.50765 8.83099 3.56902 8.75083 3.6441 8.69073L9.31174 4.11236C9.39437 4.04784 9.49028 4.00964 9.5898 4.0016C9.68931 3.99356 9.7889 4.01597 9.87851 4.06658C9.97409 4.1191 10.055 4.20147 10.1121 4.30436C10.1692 4.40724 10.2002 4.52652 10.2016 4.64869V20.3459C10.2018 20.466 10.1733 20.5839 10.1193 20.6866C10.0654 20.7893 9.98792 20.8728 9.89551 20.928C9.81484 20.9757 9.72544 21.0004 9.6348 21ZM4.53392 15.4275L9.06803 19.0902V5.90447L4.53392 9.56716V15.4275Z"
                                fill="#D84313"/>
                            <path
                                d="M3.96735 16.4216H1.70029C1.24935 16.4216 0.816871 16.2149 0.498004 15.8469C0.179138 15.4789 0 14.9799 0 14.4595V10.5352C0 10.0148 0.179138 9.51568 0.498004 9.1477C0.816871 8.77972 1.24935 8.573 1.70029 8.573H3.96735C4.11767 8.573 4.26183 8.64191 4.36811 8.76457C4.4744 8.88722 4.53412 9.05358 4.53412 9.22705V15.7676C4.53412 15.941 4.4744 16.1074 4.36811 16.2301C4.26183 16.3527 4.11767 16.4216 3.96735 16.4216ZM1.70029 9.8811C1.54998 9.8811 1.40582 9.95001 1.29953 10.0727C1.19324 10.1953 1.13353 10.3617 1.13353 10.5352V14.4595C1.13353 14.6329 1.19324 14.7993 1.29953 14.922C1.40582 15.0446 1.54998 15.1135 1.70029 15.1135H3.40059V9.8811H1.70029Z"
                                fill="#D84313"/>
                            <path
                                d="M14.4411 18.6978C14.3666 18.6983 14.2926 18.6818 14.2236 18.6493C14.1545 18.6167 14.0917 18.5688 14.0387 18.5081C13.9856 18.4473 13.9435 18.375 13.9147 18.2953C13.8859 18.2156 13.8711 18.1301 13.8711 18.0438C13.8711 17.9574 13.8859 17.8719 13.9147 17.7922C13.9435 17.7125 13.9856 17.6402 14.0387 17.5794C15.2051 16.2306 15.8601 14.4029 15.8601 12.4974C15.8601 10.5919 15.2051 8.76422 14.0387 7.41542C13.9859 7.35443 13.944 7.28204 13.9154 7.20236C13.8868 7.12268 13.8721 7.03728 13.8721 6.95104C13.8721 6.8648 13.8868 6.7794 13.9154 6.69972C13.944 6.62004 13.9859 6.54765 14.0387 6.48666C14.0916 6.42568 14.1543 6.37731 14.2234 6.3443C14.2924 6.3113 14.3664 6.29431 14.4411 6.29431C14.5159 6.29431 14.5899 6.3113 14.6589 6.3443C14.728 6.37731 14.7907 6.42568 14.8435 6.48666C16.2245 8.0811 17.0002 10.2431 17.0002 12.4974C17.0002 14.7517 16.2245 16.9137 14.8435 18.5081C14.7906 18.5688 14.7278 18.6167 14.6587 18.6493C14.5897 18.6818 14.5157 18.6983 14.4411 18.6978Z"
                                fill="#D84313"/>
                            <path
                                d="M12.839 16.8468C12.6903 16.8461 12.5478 16.778 12.4422 16.6571C12.3488 16.5321 12.2998 16.3709 12.3051 16.2059C12.3103 16.041 12.3695 15.8844 12.4706 15.7676C12.839 15.3425 13.1313 14.8377 13.3307 14.2822C13.5301 13.7267 13.6327 13.1313 13.6327 12.5301C13.6327 11.9288 13.5301 11.3334 13.3307 10.7779C13.1313 10.2224 12.839 9.71764 12.4706 9.2925C12.3777 9.16738 12.3292 9.00643 12.3347 8.84182C12.3402 8.67721 12.3994 8.52106 12.5003 8.40458C12.6012 8.2881 12.7366 8.21986 12.8792 8.2135C13.0218 8.20714 13.1613 8.26313 13.2697 8.37028C14.225 9.474 14.7615 10.9701 14.7615 12.5301C14.7615 14.09 14.225 15.5861 13.2697 16.6898C13.2111 16.7487 13.1426 16.793 13.0685 16.82C12.9944 16.847 12.9163 16.8561 12.839 16.8468Z"
                                fill="#D84313"/>
                        </svg>
                    }
                </div>
                <div className={"choice-sound"}>
                    <div className={"choice-block"}>
                        <input
                            type="radio"
                            name="melody"
                            value="мелодия"
                            id="melody"
                            checked={soundSource === Audio1}
                            onChange={() => {
                                setSource(Audio1)
                            }}
                        />
                        <label htmlFor="melody">мелодия</label>
                    </div>
                    <div className={"choice-block"}>
                        <input
                            type="radio"
                            name="about"
                            value="оклинике"
                            id="about"
                            checked={soundSource === Audio2}
                            onChange={() => {
                                setSource(Audio2)
                            }}
                        />
                        <label htmlFor="about">о клинике</label>
                    </div>
                </div>
            </div>
            <div className={"player-3"} onMouseEnter={() => setHover(true)}>
            <AudioPlayer
                autoPlay={false}
                layout={"custom"}
                src={soundSource}
                loop
                showSkipControls={false}
                showJumpControls={false}
                showDownloadProgress={false}
                onPlay={() => {
                    setSound(true)
                }}
                onPause={() => {
                    setSound(false)
                }}
                customVolumeControls={[]}
                customProgressBarSection={[]}
                customAdditionalControls={[]}
                style={{width: '50px'}}
                className={"player-1"}
                customIcons={{
                    play: (
                        <img
                            src={nosound}
                            alt="Play icon"
                        />
                    ),
                    pause: (
                        <img
                            src={sound}
                            alt="Pause icon"
                        />
                    )
                }}
                ref={ref}
                onMouseEnter={() => setHover(true)}
                // other props here
            />
                </div>
        </>
    );
}