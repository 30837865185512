import {useDispatch} from "react-redux";
import {editVideoCategory} from "../../../../reducers/dataReducer";
import React, {useEffect, useState} from "react";

export function VideoCategories(props){
    const video_category = props.category;
    const dispatch = useDispatch();

    const [playUrl, setUrl] = useState(video_category.url);
    //токен
    const [token, setToken] = useState("");
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            setToken(token);
        }
    }, []);
    //__/токен

    let EditVideoPlaylist = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: "PATCH",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                "category": video_category.category,
                "url": playUrl
            }),
        };
        const response = await fetch(`https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/video_categories/edit?video_category_id=${video_category.id}`, requestOptions);
        const data = await response.json();
        if (!response.ok) {
            console.log("Хуйня");
        } else {
            dispatch(editVideoCategory(data));
        }
    };


    return(
        <div>
            <label>
                <span>ID: </span>
                <span>{video_category.id}</span>
            </label>
            <label>
                <span>Категория: </span>
                <span>{video_category.category}</span>
            </label>
            <label>
                <span>ссылка на плейлист youtube</span>
                <input
                    name="videoplaylist-url"
                    type="text"
                    value={playUrl}
                    onChange={(event) => setUrl(event.target.value)}
                />
            </label>
            <button onClick={EditVideoPlaylist}>Обновить</button>
        </div>
    );
}