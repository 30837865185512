import {useDispatch} from "react-redux";
import {addNews} from "../../../../reducers/dataReducer";
import moment from "moment/moment";
import React, {useEffect, useState} from "react";

import validator from "validator";
import Modal from "react-modal";

import {CKEditor} from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build';

export function AddNewsForm() {
    let date = moment().format();

    const dispatch = useDispatch();

    const [showModal, setModal] = useState(false);

    const [newsHeader, setHeader] = useState("");
    const [newsDate, setDate] = useState("");
    const [newsShortDesc, setShortDesc] = useState("");
    const [newsImage, setImage] = useState("");
    const [newsDesc, setDesc] = useState("");

    const [imageFile, setFile] =useState();



    useEffect(() => {
        setDate(date);
    }, [newsImage, date]);

    //токен
    const [token, setToken] = useState("");
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            setToken(token);
        }
    }, []);
    //__/токен

    let AddNews = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: "POST",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                "news_id": 0,
                "header": newsHeader,
                "short_descr": newsShortDesc,
                "description": newsDesc,
                "date": newsDate,
                "news_img": newsImage,
            }),
        };
        const response = await fetch("https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/news/add", requestOptions);
        const data = await response.json();
        if (!response.ok) {
            console.log("Хуйня");
        } else {
            dispatch(addNews(data));
        }
        setModal(false);
    };
    console.log(moment(newsDate).format("YYYY/MM/DD"));
    const [inputsValid, setValid] = useState({
        header: validator.isLength(newsHeader, {min: 5, max: 100}),
        short_descr: validator.isLength(newsShortDesc, {min: 5, max: 250}),
        description: validator.isLength(newsDesc, {min: 5, max: 1000}),
        date: validator.isDate(moment(newsDate).format("YYYY/MM/DD")),
        news_img: validator.isLength(newsImage, {min: 5, max: 50}),
    });
    console.log(inputsValid);
    const formValid = (values) => {
        return !(values.header === true &&
            values.date === true &&
            values.description === true &&
            values.news_img === true &&
            values.short_descr === true);
    };
    let UploadImage = async (e) => {
        e.preventDefault()
        const image = new FormData();
        image.append('uploaded_file', imageFile);

        const requestOptions = {
            method: "POST",
            headers: {
                "accept": "application/json",
                Authorization: "Bearer " + token,
            },
            "Content-Type": "multipart/form-data",
            body: image,
        };
        const response = await fetch("https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload-file/", requestOptions);


        const data = await response.json();
        if (!response.ok) {
            console.log(response);
        } else {
            setImage(imageFile.name);
            setValid({
                ...inputsValid,
                news_img: validator.isLength(imageFile.name, {
                    min: 5,
                    max: 70,
                }),
            });
            console.log(data);
        }
    }
    return (
        <div className={"add-news-form"}>
            <div className={"add-news"}>
                <button onClick={() => setModal(true)} className={"add-news-button"}>Добавить</button>
            </div>
            <Modal isOpen={showModal} contentLabel="Форма: Добавить статью">
                <h2>Добавить новость</h2>
                <form>
                    <label>
                        <span>Заголовок</span>
                        <input
                            name="news-header"
                            type="text"
                            value={newsHeader}
                            onChange={(event) => setHeader(event.target.value)}
                            onInput={(event) =>
                                setValid({
                                    ...inputsValid,
                                    header: validator.isLength(event.target.value, {
                                        min: 5,
                                        max: 100,
                                    }),
                                })
                            }
                        />
                        <span className="warning">
                              {inputsValid.header === true
                                  ? ""
                                  : "Введите заголовок!"}
                            </span>
                    </label>
                    <br/>
                    <label>
                        <span>Краткое содержание</span>
                        <input
                            name="news-description"
                            type="text"
                            value={newsShortDesc}
                            onChange={(event) => setShortDesc(event.target.value)}
                            onInput={(event) =>
                                setValid({
                                    ...inputsValid,
                                    short_descr: validator.isLength(event.target.value, {
                                        min: 20,
                                        max: 250,
                                    }),
                                })
                            }
                        />
                        <span className="warning">
                              {inputsValid.short_descr === true
                                  ? ""
                                  : "Введите краткий текст!"}
                            </span>
                    </label>
                    <br/>
                    <label>
                        <span>Текст Новости</span>
                        <CKEditor
                            editor={Editor}
                            data={newsDesc}
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setDesc(data);
                                setValid({
                                    ...inputsValid,
                                    description: validator.isLength(data, {
                                        min: 5,
                                        max: 1000,
                                    }),
                                });
                            }}

                        />
                        <span className="warning">
                              {inputsValid.description === true
                                  ? ""
                                  : "Введите Текст!"}
                            </span>
                    </label>
                    <br/>
                    <label>
                        <span>Главное фото</span>
                        <input
                            name="news-image"
                            type="text"
                            value={newsImage}
                            onChange={(event) => setImage(event.target.value)}
                            onInput={(event) =>
                                setValid({
                                    ...inputsValid,
                                    news_img: validator.isLength(event.target.value, {
                                        min: 5,
                                        max: 40,
                                    }),
                                    date: validator.isDate(moment(newsDate).format("YYYY/MM/DD")),
                                })
                            }
                        />
                        <span className="warning">
                              {inputsValid.news_img === true
                                  ? ""
                                  : "фото!"}
                            </span>
                        <label>
                            <input
                                name="article-image-file"
                                type="file"
                                onChange={(event) => setFile(event.target.files[0])}
                            />
                            <button onClick={UploadImage}> загрузить</button>
                        </label>
                    </label>
                    <br/>
                    <button
                        onClick={AddNews}
                        className="AddButton"
                        disabled={formValid(inputsValid)}
                    >
                        Добавить
                    </button>
                    <button onClick={() => setModal(false)} className="closeButton">
                        Закрыть
                    </button>
                </form>
            </Modal>
        </div>
    );
}