import "./Appointment.sass";
import React, {useState} from "react";
import validator from "validator";
import Checkbox from "react-custom-checkbox";
import * as Icon from "react-icons/fi";
import "animate.css/animate.compat.css";
import ScrollAnimation from 'sgk-react-animation-scroll';
import { withMask } from 'use-mask-input';
import {Element} from "react-scroll";

export function Appointment() {
    const [clientName, setName] = useState("");
    const [clientPhone, setPhone] = useState("");
    const [clientEmail, setEmail] = useState("");
    const [clientProblem, setProblem] = useState("");
    const [checkBox, setCheck] = useState(false);

    const [inputsValid, setValid] = useState({
        name: validator.isLength(clientName, {min: 2, max: 40}),
        phone: validator.isMobilePhone(clientPhone, 'ru-RU'),
        email: validator.isEmail(clientEmail),
        problem: validator.isLength(clientProblem, {min: 5, max: 140}),
        agree: false,
    });
    const formValid = (values) => {
        return !(values.name === true &&
            values.phone === true &&
            values.email === true &&
            values.problem === true &&
            values.agree === true);
    };

    return (
        <div className={"main-page-appointment"} id={"main-page-appointment"}>
            <Element name="appointment" className="element"></Element>
            <div className={"appointment-block"}>
                <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true} offset={100}>
                <div className={"appointment-header"}>
                    <h2>Задать вопрос:</h2>
                </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true} offset={100}>
                <form className={"appointment-form"}>
                    <label className={"input-label"}>
                        <input
                            name="client-name"
                            type="text"
                            placeholder={"Ваше имя:"}
                            value={clientName}
                            onChange={(event) => setName(event.target.value)}
                            onInput={(event) =>
                                setValid({
                                    ...inputsValid,
                                    name: validator.isLength(event.target.value, {
                                        min: 2,
                                        max: 40,
                                    }),
                                })
                            }
                        />
                        <span className="warning">
                              {inputsValid.name === true
                                  ? ""
                                  : "Введите имя!"}
                            </span>
                    </label>
                    <label className={"input-label"}>
                        <input
                            name="client-email"
                            type="text"
                            placeholder={"Ваш e-mail:"}
                            value={clientEmail}
                            onChange={(event) => setEmail(event.target.value)}
                            onInput={(event) =>
                                setValid({
                                    ...inputsValid,
                                    email: validator.isEmail(event.target.value),
                                })
                            }
                        />
                        <span className="warning">
                              {inputsValid.email === true
                                  ? ""
                                  : "Введите email!"}
                            </span>
                    </label>
                    <label className={"input-label"}>
                        <input
                            name="client-phone"
                            type="text"
                            placeholder={"Ваш телефон:"}
                            value={clientPhone}
                            onChange={(event) => setPhone(event.target.value)}
                            onInput={(event) => {
                                let phone = event.target.value;
                                phone = phone.replace(/[^0-9]/g, '');
                                setValid({
                                    ...inputsValid,
                                    phone: validator.isMobilePhone(phone, 'ru-RU'),
                                })
                            }

                            }
                            ref={withMask('+7(999)999-99-99')}
                        />
                        <span className="warning">
                              {inputsValid.phone === true
                                  ? ""
                                  : "Введите телефон"}
                            </span>
                    </label>

                    <label className={"input-label"}>
                        <input
                            name="client-problem"
                            type="text"
                            placeholder={"Опишите вашу проблему:"}
                            value={clientProblem}
                            onChange={(event) => setProblem(event.target.value)}
                            onInput={(event) =>
                                setValid({
                                    ...inputsValid,
                                    problem: validator.isLength(event.target.value, {
                                        min: 5,
                                        max: 140,
                                    }),
                                })
                            }
                        />
                        <span className="warning">
                              {inputsValid.problem === true
                                  ? ""
                                  : "Введите описание"}
                            </span>
                    </label>
                    <button
                        className="send-problem"
                        disabled={formValid(inputsValid)}
                    >
                        Отправить
                    </button>
                    <Checkbox
                        checked={false}
                        icon={
                            <div
                                style={{
                                    display: "flex",
                                    flex: 1,
                                    backgroundColor: "#60a829",
                                    alignSelf: "stretch",
                                }}
                            >
                                <Icon.FiCheck color="white" size={20} />
                            </div>
                        }
                        borderColor="#C1C1C1"
                        onChange={() => {
                            setCheck((current) => !current);
                            if (checkBox === false){
                                setValid({
                                    ...inputsValid,
                                    agree: true,
                                });
                            }else{
                                 setValid({
                                    ...inputsValid,
                                    agree: false,
                                });
                            }
                        }
                        }
                        borderWidth={1}
                        borderRadius={20}
                        style={{overflow: "hidden"}}
                        size={20}
                        className={"agree-checkbox"}
                        label={<div style={{
                            textAlign: "center",
                        }} className={"agree-label"}
                        >
                            Нажимая «Отправить», вы даете <a href={"#"}>согласие</a> на обработку персональных данных
                        </div>}
                    />
                </form>
                </ScrollAnimation>
            </div>
        </div>
    );
}