import YouTubePlaylist from "@codesweetly/react-youtube-playlist";
import {Link, useParams} from 'react-router-dom';
import "./Video.sass"
import {Sechead} from "../../components/Sechead/Sechead";
import React from "react";
import {useSelector} from "react-redux";
import {videoCategoriesList} from "../../reducers/dataReducer";
import {MainContacts} from "../../components/MainContacts/MainContacts";
import {Footer} from "../../components/Footer/Footer";
export function Video() {
    const categoryList = useSelector(videoCategoriesList);
    const {category_name} = useParams();
    let fix_name = category_name.replaceAll("_", ' ');

    let currentCategory;

    for (let item of categoryList){
        if (fix_name === item.category){
            currentCategory = item;
        }
    }
    console.log(currentCategory);

    // let playlist_id;
    // let header;
    // if(fix_name === "О клинике"){
    //     playlist_id = categoryList[0].url;
    //     header = "О клинике";
    // }else if (fix_name === "Интервью"){
    //     playlist_id = categoryList[1].url;
    //     header = "Интервью";
    // }else if (fix_name === "Полезные материалы"){
    //     playlist_id = categoryList[2].url;
    //     header = "Полезные материалы";
    // }else if (fix_name === "Советы экспертов"){
    //     playlist_id = categoryList[3].url;
    //     header = "Советы экспертов";
    // }


    return(
        <div className={"video-main"}>
            <Sechead/>
            <div className="video-page">
                <div className={"bread"}>
                    <ul>
                        <li><Link to="/">Главная</Link></li>
                        <li><Link to={"/video"}>Видео</Link></li>
                        <li>{currentCategory.category}</li>
                    </ul>
                </div>
                <div className={"video-categories"}>
                    {categoryList.map((category, index) => {
                        return (
                            <div className={category === currentCategory ? "video-category-active" : "video-category"}
                                 key={index} >
                                <div className={"video-category-name"}>
                                    <Link to={"/videos/" + category.category.replaceAll(" ", "_")}>{category.category}</Link>
                                </div>
                            </div>
                        )
                    })}
                </div>

                <h1>{currentCategory.category}</h1>
                <div className="YT-playlist">
                    <YouTubePlaylist
                        apiKey="AIzaSyDnh5VQjILUSZXH0T_f_8D7g3mOdP88aa8"
                        playlistId={currentCategory.url}
                        uniqueName="battle"
                    />
                </div>

            </div>
            <MainContacts/>
            <Footer/>
        </div>
    );
}