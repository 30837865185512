import './Directions.sass';

export function Directions() {
    return (
        <div className="directions">
            <div className="column-6">
                <h2>Семья</h2>
            </div>
            <div className="column-6 logoped-plus">
                <div className="childrens">
                    <h2>Дети</h2>
                </div>
                <div className="logoped">
                    <h2>Логопед</h2>
                </div>
            </div>
            <div className="column-6">
                <h2>Военнослужащим и членам семей</h2>
            </div>
            <div className="column-6">
                <h2>Депрессия</h2>
            </div>
            <div className="column-6">
                <h2>Бизнесу</h2>
            </div>
            <div className="column-6">
                <h2>Ещё...</h2>
                <ul>
                    <li>Документы</li>
                    <li>Отзывы</li>
                    <li>Адреса и телефоны контролирующих организаций</li>
                    <li>Вакансии</li>
                </ul>
            </div>
        </div>
    );
}