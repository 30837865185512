import {Sechead} from "../../components/Sechead/Sechead";
import {useSelector} from "react-redux";
import {reviewsList} from "../../reducers/dataReducer";
import "./Feedback.sass"
import React, {useRef, useState} from "react";
import {Rating} from "./Rating";
import validator from "validator";
import ReCAPTCHA from "react-google-recaptcha";
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
import {MainContacts} from "../../components/MainContacts/MainContacts";
import {Footer} from "../../components/Footer/Footer";

export function Feedback() {
    let reviews = useSelector(reviewsList);
    let score;
    let count = 0;
    let summ = 0;


    for (let item of reviews) {
        if (item.is_published) {
            summ += item.rating;
            count++;
        }
    }
    score = summ / count;

    const [activeReview, setReview] = useState();

    const [clientName, setName] = useState("");
    const [clientFeed, setFeed] = useState("");
    const [checkBox, setCheck] = useState(false);

    const [inputsValid, setValid] = useState({
        name: validator.isLength(clientName, {min: 2, max: 40}),
        feed: validator.isLength(clientFeed, {min: 5, max: 140}),
        agree: false,
    });
    const formValid = (values) => {
        return !(values.name === true &&
            values.feed === true &&
            values.agree === true);
    };

    function onChange(value) {
        console.log("Captcha value:", value);
    }

    const recaptcha = useRef();

    async function submitForm(event) {
        event.preventDefault()
        const captchaValue = recaptcha.current.getValue()
        if (!captchaValue) {
            alert('Please verify the reCAPTCHA!')
        } else {
            // make form submission
            alert('Form submission successful!')
        }
    }

    return (
        <div className={"feedback-main"}>
            <Sechead/>
            <div className={"feedback-page"}>
                <h1>Отзывы</h1>
                <div className={"score"}>Средняя оценка нашей работы: <span>{score}</span></div>
                <div className={"feedback-form"}>
                    <form onSubmit={submitForm}>
                        <label className={"input-label"}>
                            <input
                                name="client-name"
                                type="text"
                                placeholder={"Ваше имя:"}
                                value={clientName}
                                onChange={(event) => setName(event.target.value)}
                                onInput={(event) =>
                                    setValid({
                                        ...inputsValid,
                                        name: validator.isLength(event.target.value, {
                                            min: 2,
                                            max: 40,
                                        }),
                                    })
                                }
                            />
                            <span className="warning">
                              {inputsValid.name === true
                                  ? ""
                                  : "Введите имя!"}
                            </span>
                        </label>
                        <label className={"input-label"}>
                            <textarea
                                name="client-problem"
                                type="text"
                                placeholder={"Ваш отзыв:"}
                                value={clientFeed}
                                onChange={(event) => setFeed(event.target.value)}
                                onInput={(event) =>
                                    setValid({
                                        ...inputsValid,
                                        feed: validator.isLength(event.target.value, {
                                            min: 5,
                                            max: 140,
                                        }),
                                    })
                                }
                            />
                            <span className="warning">
                              {inputsValid.feed === true
                                  ? ""
                                  : "Напишите отзыв"}
                            </span>
                        </label>
                        <label className={"input-captcha"}>
                            <ReCAPTCHA
                                ref={recaptcha}
                                sitekey="6LclOBcqAAAAAB1wDwdi85cBDDnBHe6zYyiXczGY"
                                onChange={onChange}
                            />
                        </label>
                        <label className={"input-checkbox"}>
                            <Checkbox
                                checked={false}
                                icon={
                                    <div
                                        style={{
                                            display: "flex",
                                            flex: 1,
                                            backgroundColor: "#60a829",
                                            alignSelf: "stretch",
                                        }}
                                    >
                                        <Icon.FiCheck color="white" size={20}/>
                                    </div>
                                }
                                borderColor="#C1C1C1"
                                onChange={() => {
                                    setCheck((current) => !current);
                                    if (checkBox === false) {
                                        setValid({
                                            ...inputsValid,
                                            agree: true,
                                        });
                                    } else {
                                        setValid({
                                            ...inputsValid,
                                            agree: false,
                                        });
                                    }
                                }
                                }
                                borderWidth={1}
                                borderRadius={20}
                                style={{overflow: "hidden"}}
                                size={20}
                                className={"agree-checkbox"}
                                label={<div style={{
                                    textAlign: "center",
                                }} className={"agree-label"}
                                >
                                    Нажимая «Отправить», вы даете <a href={"#"}>согласие</a> на обработку персональных
                                    данных
                                </div>}
                            />
                        </label>
                        <label>
                            <input type="submit" value="Отправить" disabled={formValid(inputsValid)} className={"submit-button"}/>
                        </label>

                    </form>
                </div>
                <div className={"feedback-reviews-list"}>
                    {reviews.map((review) => {
                        return (
                            <div className={review === activeReview ? "feedback-review-active" : "feedback-review"}
                                 key={review.id} onClick={() => setReview(review)}>
                                <h2>{review.username}</h2>
                                <Rating review_rating={review.rating}/>
                                <p className={"review-text"}>{review.feedback_text}</p>
                                <p className={"answer"}>
                                    {review.answer}
                                </p>
                            </div>
                        )
                    })}
                </div>
            </div>
            <MainContacts/>
            <Footer/>
        </div>
    );
}