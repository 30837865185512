import {useSelector} from "react-redux";
import {doctorsList} from "../../reducers/dataReducer";
import "./Specialists.sass"
import {Link} from "react-router-dom";
import "animate.css/animate.compat.css";
import ScrollAnimation from 'sgk-react-animation-scroll';
import React from "react";

export function Specialists() {
    let doctors = useSelector(doctorsList);

    return (
        <div className={"spec"} id={"speci"}>
            <div className={"main-spec-block"}>
                <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true} offset={100}>
                <div className={"spec-header"}>
                    <h2>Специалисты</h2>
                </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true} offset={100}>
                    <div className={"spec-list"}>
                        {doctors.map((doctor) => {
                            let photo
                            if (doctor.photo === "no-photo") {
                                photo = "https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/placeholder.png"
                            } else {
                                photo = "https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/" + doctor.photo
                            }
                            return (
                                <div className={"spec-block"} key={doctor.id}>
                                    <Link to={"/doctor/"+ doctor.surname + "_" + doctor.name + "_" + doctor.patronymics}>
                                    <div className={"spec-img"}>
                                        <img src={photo} alt={doctor.specialty}/>
                                    </div>
                                    <div className={"spec-fio"}>
                                        <h4>{doctor.surname}
                                            <br/>{doctor.name} {doctor.patronymics}</h4>
                                    </div>
                                    <div className={"spec-stag"}>
                                        стаж <span>{doctor.stag}</span>
                                    </div>
                                    <div className={"spec-spec"}>
                                        {doctor.specialty}
                                    </div>
                                    </Link>
                                </div>
                            );
                        })}
                    </div>
                    <div className={"more-specs-link"}>
                        <Link to={"/specs"}>Все специалисты
                            <svg width="24" height="8" viewBox="0 0 24 8" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5V3.5ZM23.3536 4.35355C23.5488 4.15829 23.5488 3.84171 23.3536 3.64645L20.1716 0.464466C19.9763 0.269204 19.6597 0.269204 19.4645 0.464466C19.2692 0.659728 19.2692 0.976311 19.4645 1.17157L22.2929 4L19.4645 6.82843C19.2692 7.02369 19.2692 7.34027 19.4645 7.53553C19.6597 7.7308 19.9763 7.7308 20.1716 7.53553L23.3536 4.35355ZM1 4.5L23 4.5V3.5L1 3.5V4.5Z"
                                    fill="#D84313"/>
                            </svg>
                        </Link>
                    </div>
                </ScrollAnimation>
            </div>
        </div>
    );
}