import {IoBuildOutline, IoTrashOutline} from "react-icons/io5";
import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import {useDispatch, useSelector} from "react-redux";
import {doctorsList, editSpecArticle, servicesList} from "../../../../reducers/dataReducer";

import {CKEditor} from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build';

export function EditSpecArticle(props) {
    let edited_spec_article = props.spec_article;

    let doctors = useSelector(doctorsList);
    let services = useSelector(servicesList);

    const dispatch = useDispatch();

    const [showModal, setModal] = useState(false);

    const [specArticleName, setSpecName] = useState(edited_spec_article.name);
    const [specArticleImage, setImage] = useState(edited_spec_article.image);
    const [specArticleText, setText] = useState(edited_spec_article.text);
    const [specArticleOwner, setOwner] = useState(edited_spec_article.owner);
    const [specDoctors, setDoctors] = useState(JSON.parse(edited_spec_article.doctors));


    let chosed = [];
    for (let i = 0; i < specDoctors.length; i++) {
        for (let item of doctors) {
            if (item.id === parseInt(specDoctors[i])) {
                chosed.push(item);
            }
        }
    }

    const [chosenDoctors, setChosenDoctors] = useState(chosed);
    const [selectedDoctor, setDoctor] = useState(0);
    const [deletedDoctor, setDel] = useState(null);


    const [specFile, setFile] = useState();

    let UploadImage = async (e) => {
        e.preventDefault()
        const image = new FormData();
        image.append('uploaded_file', specFile);

        const requestOptions = {
            method: "POST",
            headers: {
                "accept": "application/json",
                Authorization: "Bearer " + token,
            },
            "Content-Type": "multipart/form-data",
            body: image,
        };
        const response = await fetch("https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload-file/", requestOptions);


        const data = await response.json();
        if (!response.ok) {
            console.log(response);
        } else {
            setImage(specFile.name);
            console.log(data);
        }
    }

    let EditSpecArticle = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: "PATCH",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                "name": specArticleName,
                "image": specArticleImage,
                "text": specArticleText,
                "owner_id": specArticleOwner,
                "doctors": JSON.stringify(specDoctors)
            }),
        };
        const response = await fetch(`https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/spec_articles/edit?spec_article_id=${edited_spec_article.id}`, requestOptions);
        const data = await response.json();
        if (!response.ok) {
            console.log("Хуйня");
        } else {
            dispatch(editSpecArticle(data));
        }
        setModal(false);
    };

    //токен
    const [token, setToken] = useState("");
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            setToken(token);
        }
    }, []);
    //__/токен

    const AddChoseDoctor = (e) => {
        e.preventDefault();
        let add = {};
        for (let doctor of doctors) {
            if (doctor.id === selectedDoctor) {
                add = doctor;
            }
        }
        if (!chosenDoctors.includes(add)) {
            setChosenDoctors(arr => [...arr, add])
            setDoctors(arr => [...arr, add.id])
        }
    }
    useEffect(() => {
        if (deletedDoctor) {
            let chose = chosenDoctors;
            let doctors_list = specDoctors;

            for (let i = 0; i < chose.length; i++) {
                if (chose[i] === deletedDoctor) {
                    chose.splice(i, 1);
                }
            }
            setChosenDoctors(chose);
            for (let i = 0; i < doctors.length; i++) {
                if (parseInt(doctors_list[i]) === deletedDoctor.id) {
                    doctors_list.splice(i, 1);
                }
            }
            setDoctors(doctors_list);
            setDel(null);
        }
    }, [deletedDoctor, chosenDoctors, specDoctors, doctors.length]);

    return (
        <div className={"edit-spec-article-form"}>
            <button onClick={() => setModal(true)}><IoBuildOutline/></button>
            <Modal isOpen={showModal} contentLabel="Форма: Добавить статью">
                <form>
                    <label>
                        <span>Название</span>
                        <input
                            name="service-name"
                            type="text"
                            value={specArticleName}
                            onChange={(event) => setSpecName(event.target.value)}
                        />
                    </label>
                    <br/>
                    <label>
                        <span>Фото</span>
                        <input
                            name="service-image"
                            type="text"
                            value={specArticleImage}
                            onChange={(event) => setImage(event.target.value)}
                        />
                    </label>
                    <label>
                        <input
                            name="article-image-file"
                            type="file"
                            onChange={(event) => setFile(event.target.files[0])}
                        />
                        <button onClick={UploadImage}> загрузить</button>
                    </label>
                    <br/>
                    <label>
                        <span>Текст Статьи</span>
                        <CKEditor
                            editor={Editor}
                            data={specArticleText}
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setText(data)
                            }}

                        />
                    </label>
                    <br/>
                    <label>
                        <span>Раздел</span>
                        <select onChange={(event) => setOwner(event.target.value)}>
                            <option value="">Выберете Раздел</option>
                            {services.map((service) => {
                                return (
                                    <option key={service.id} value={service.id}>{service.name}</option>
                                )
                            })}
                        </select>
                    </label>
                    <br/>
                    <label>
                        <select onChange={(event) => setDoctor(parseInt(event.target.value))}>
                            <option value="">Выберете Доктора</option>
                            {doctors.map((doctor) => {
                                return (
                                    <option key={doctor.id}
                                            value={doctor.id}>{doctor.surname + " " + doctor.name + " " + doctor.patronymics}</option>
                                )
                            })}
                        </select>
                        <button onClick={AddChoseDoctor}>добавить доктора
                        </button>
                        <div className={"doc-list"}>
                            {chosenDoctors.map((doctor) => {
                                return (
                                    <div className={"doc"} key={doctor.id}>
                                        {doctor.surname + " " + doctor.name + " " + doctor.patronymics}
                                        <span onClick={() => setDel(doctor)}><IoTrashOutline/></span>
                                    </div>
                                )
                            })}
                        </div>
                    </label>
                    <br/>
                </form>
                <button
                    onClick={EditSpecArticle}
                    className="AddButton"
                >
                    Изменить
                </button>
                <button onClick={() => setModal(false)} className="closeButton">
                    Закрыть
                </button>
            </Modal>
        </div>
    );
}