import {newsList} from "../../reducers/dataReducer";
import {useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import React from "react";
import "./NewsItem.sass";
import parse from "html-react-parser";
import moment from "moment";
import {MainContacts} from "../../components/MainContacts/MainContacts";
import {Footer} from "../../components/Footer/Footer";
import {Sechead} from "../../components/Sechead/Sechead";
import {Helmet} from "react-helmet";

export function NewsItem(){
    const news = useSelector(newsList);
    const {news_head} = useParams();
    let fix_head = news_head.replaceAll("_", ' ');
    let currentItem;

    for (let item of news){
        if (item.header === fix_head){
            currentItem = item;
        }
    }
    let read_more = [];

    for (let i = news.length-1; i>=0; i--){
        if (currentItem !== news[i] && read_more.length<4){
            read_more.push(news[i]);
        }
        if (read_more.length === 4){
            break
        }
    }
    let keywords = "Возрождение жизни, медицинский центр, психология, клиника, статьи по психологии, новости" + ", " + currentItem.header;


    return(
        <div className={"news-item-main"}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Возрождение жизни - Новости - {currentItem.header}</title>
                <meta name="keywords" content={keywords}/>
                <meta name="description" content={currentItem.short_descr}/>
            </Helmet>
            <Sechead/>
            <div className={"news-item-page"}>
                <div className={"bread"}>
                    <ul>
                        <li><Link to="/">Главная</Link></li>
                        <li><Link to={"/news"}>Новости</Link></li>
                        <li>{currentItem.header}</li>
                    </ul>
                </div>
                <div className={"news-item-body"}>
                    <div className={"news-item-image"}>
                        <img src={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/" + currentItem.news_img} alt={currentItem.header}/>
                    </div>
                    <div className={"news-item-header"}>
                        <h1>{currentItem.header}</h1>
                        <div className={"news-item-date"}>{moment(currentItem.date).format("DD.MM.YY")} г.</div>
                    </div>
                    <div className={"news-item-text"}>
                        {parse(currentItem.description)}
                    </div>
                    <h3>Другие новости:</h3>
                    <div className={"read-more-block"}>
                        {read_more.map((item) => {
                            return (
                                <Link to={"/news/" + item.header.replaceAll(" ", "_")} key={item.news_id}>
                                    <div className={"read-more-item"}>
                                        <h4>{item.header}</h4>
                                        <div className={"read-more-item-image"}>
                                            <div className={"frame"}>
                                                <img src={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/" + item.news_img}
                                                     alt={item.header}/>
                                            </div>
                                        </div>
                                        <div className={"read-more-item-text"}>
                                            {item.short_descr}
                                        </div>
                                        <div className={"read-more-item-link"}>
                                            читать
                                        </div>
                                    </div>
                                </Link>
                            )
                        })}
                    </div>
                </div>
            </div>
            <MainContacts/>
            <Footer/>
        </div>
    );
}