import React, {useState} from "react";
import "./TimeLine.sass"


export function TimeLine(props) {
    let timeline = JSON.parse(props.line);
    let header = props.header;

    const [activeYear, setYear] = useState(timeline[0]);

    let years_count = parseInt(timeline[timeline.length - 1].year) - parseInt(timeline[0].year);
    function Width(index){
        let x = index;
        let w = 0;
        if (timeline[index+1]){
            w = parseInt(timeline[x+1].year) - parseInt(timeline[x].year);
        }else{
            w = 1;
        }
        let step = 100 / (years_count+1);
        let result = w * step;
        return String(result)+"%";

    }

    return (
        <div className={"timeline"}>
            <h3>{header}</h3>
            <span className="timeline-eventline"></span>
            <ol className={"events-bar"}>
                {timeline.map((item, index) => {
                    return(
                        <li key={item.year} style={{ width: Width(index)}} onClick={() => setYear(item)} className={activeYear.year === item.year ? "active": ""}>
                            <span className={"dot"}></span>
                            <span className={"year"}>{item.year}</span>
                        </li>
                    )
                })}
            </ol>
            <div className={"year-detail"}>
                <div className={"year-text"}>
                    <p>{activeYear.text}</p>
                </div>
                <div className={"year-img"}>
                    <img src={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/"+activeYear.img}/>
                </div>
            </div>
        </div>
    );
}