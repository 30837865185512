import {useDispatch, useSelector} from "react-redux";
import {editMainVideo, mainVideo, videoCategoriesList} from "../../../../reducers/dataReducer";
import "./MainVideo.sass";
import React, {useEffect, useState} from "react";
import {VideoCategories} from "./VideoCategories";

export function MainVideo() {
    const dispatch = useDispatch();
    const main_video = useSelector(mainVideo);
    const video_categories = useSelector(videoCategoriesList);


    const [videoUrl, setUrl] = useState(main_video.url);
    const [videoText, setText] = useState(main_video.text);
    const [videoHead, setHead] = useState(main_video.header);


    //токен
    const [token, setToken] = useState("");
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            setToken(token);
        }
    }, []);
    //__/токен

    let EditVideo = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: "PATCH",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                "url": videoUrl,
                "text": videoText,
                "header": videoHead
            }),
        };
        const response = await fetch(`https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/main_video/edit?video_id=${1}`, requestOptions);
        const data = await response.json();
        if (!response.ok) {
            console.log("Хуйня");
        } else {
            dispatch(editMainVideo(data));
        }
    };

    return (
        <div className={"admin-video-page"}>
            <div className={"main-video"}>
                <h2>Главное Видео</h2>
                <form>
                    <label>
                        <span>Ссылка</span>
                        <input
                            name="video-url"
                            type="text"
                            value={videoUrl}
                            onChange={(event) => setUrl(event.target.value)}
                        />
                    </label>
                    <label>
                        <span>Заголовок</span>
                        <input
                            name="video-header"
                            type="text"
                            value={videoHead}
                            onChange={(event) => setHead(event.target.value)}
                        />
                    </label>
                    <label>
                        <span>Текст</span>
                        <textarea
                            name="video-text"
                            type="textarea"
                            value={videoText}
                            onChange={(event) => setText(event.target.value)}
                        />
                    </label>
                    <button onClick={EditVideo}>Обновить</button>
                </form>
            </div>
            <div className={"video-categories"}>
                <h2>Видео категории</h2>
                <form>
                    {video_categories.map((video_cat) => {
                        return (
                            <VideoCategories category={video_cat} key={video_cat.id}/>
                        )
                    })}
                </form>
            </div>
        </div>
    );
}