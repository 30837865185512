import "./Doctor.sass"
import {AddDoctor} from "./AddDoctor";
import {useDispatch, useSelector} from "react-redux";
import {deleteDoctor, doctorsList} from "../../../../reducers/dataReducer";
import {useEffect, useState} from "react";
import {EditDoctor} from "./EditDoctor";


export function Doctors() {
    let doctors = useSelector(doctorsList);
    const dispatch = useDispatch();

    const[deletedDoctor, setDel] = useState(0);

    //токен
    const [token, setToken] = useState("");
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            setToken(token);
        }
    }, []);
    //__/токен

    let DeleteDoctor = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: "DELETE",
            headers: {
                "accept": "application/json",
                Authorization: "Bearer " + token,
            },

        };
        const response = await fetch(`https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/doctors/delete?doctor_id=${deletedDoctor}`, requestOptions);
        const data = await response.json();
        if (!response.ok) {
            console.log("Хуйня");
        } else {
            dispatch(deleteDoctor(deletedDoctor));
            alert(data);
        }
    };


    return (
        <div className="doctors">
            <div className={"doc-block-header"}>
                <div className={"doc-id"}>
                    id
                </div>
                <div className={"doc-name"}>
                    Имя
                </div>
                <div className={"doc-specialty"}>
                    Специальность
                </div>
                <div className={"doc-fullspecialty"}>
                    Полная специальность
                </div>
                <div className={"doc-about"}>
                    О докторе
                </div>
                <div className={"doc-howiwork"}>
                    Как работает
                </div>
                <div className={"doc-img"}>
                    Фото
                </div>
                <div className={"doc-act"}>
                    Действия
                </div>
            </div>
            {doctors.map((doctor) => {
                let photo
                if (doctor.photo === "no-photo") {
                    photo = "https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/placeholder.png"
                } else {
                    photo = "https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/" + doctor.photo
                }
                return (
                    <div className={"doc-block"} key={doctor.id}>
                        <div className={"doc-id"}>
                            {doctor.id}
                        </div>
                        <div className={"doc-name"}>
                            {doctor.name} {doctor.patronymics} {doctor.surname}
                        </div>
                        <div className={"doc-specialty"}>
                            {doctor.specialty}
                        </div>
                        <div className={"doc-fullspecialty"}>
                            {doctor.fullspeciality}
                        </div>
                        <div className={"doc-about"}>
                            {doctor.about} стаж {doctor.stag}
                        </div>
                        <div className={"doc-howiwork"}>
                            {doctor.howiwork}
                        </div>
                        <div className={"doc-img"}>
                            <img src={photo} height={66} alt={doctor.specialty}/>
                        </div>
                        <div className={"doc-act"} onMouseEnter={() => setDel(doctor.id)}>
                            <button className={"doc-button"} onClick={DeleteDoctor}>удалить</button>
                            <EditDoctor doc={doctor}/>
                        </div>
                    </div>
                );
            })}

            <AddDoctor/>
        </div>
    );
}