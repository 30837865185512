import './News.sass';
import {useSelector} from "react-redux";
import {newsList} from "../../reducers/dataReducer";
import {Sechead} from "../../components/Sechead/Sechead";
import {Link} from "react-router-dom";
import moment from "moment/moment";
import {MainContacts} from "../../components/MainContacts/MainContacts";
import {Footer} from "../../components/Footer/Footer";
import React from "react";


export function News() {
    const newss = useSelector(newsList);

    return (
        <div className="news-all-main">
            <Sechead/>
            <div className={"news-all-page"}>
                <div className={"bread"}>
                    <ul>
                        <li><Link to="/">Главная</Link></li>
                        <li>Новости</li>
                    </ul>
                </div>
                <h1>Новости</h1>
                <div className={"news-items"}>
                    {newss.map((news) => {
                        let photo = "https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/" + news.news_img
                        return (
                            <div className={"news-item"} key={news.news_id}>
                                <div className={"news-item-img"}>
                                    <img src={photo} alt={news.header}/>
                                </div>
                                <h2>{news.header}</h2>
                                <div className={"news-item-short"}>
                                    <p>{news.short_descr}</p>
                                </div>
                                <div className={"news-date-see-more"}>
                                    <div className={"news-date"}>
                                        {moment(news.date).format("DD.MM.YY")} г.
                                    </div>
                                    <div className={"see-more"}>
                                        <Link to={"/news/" + news.header.replaceAll(" ", "_")}>читать далее</Link>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <MainContacts/>
            <Footer/>
        </div>
    );

}